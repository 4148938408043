import React from 'react';
import styles from './GoalPlaceholder.module.css';

interface Props {
  background?: string;
}

export function GoalPlaceholder(props: Props) {
  const { background = '#ffffff' } = props;

  const goal_styling = {
    '--background': background,
  } as React.CSSProperties;

  return (
    <div
      className={styles.goal}
      style={goal_styling}
    >
      <div className={`${styles.line} ${styles.heading}`}></div>
      <div className={styles.spacer}></div>
      <div className={styles.line}></div>
      <div className={styles.spacer}></div>
      <div className={styles.goal_footer}>
        <div className={styles.goal_status}></div>
        <div className={`${styles.line} ${styles.short}`}></div>
        <div className={`${styles.line} ${styles.short}`}></div>
      </div>
    </div>
  );
}

GoalPlaceholder.defaultProps = {
  background: '#ffffff',
};
