import React from 'react';

import { Header } from '../Header/Header';
import { useAdaptiveLayout } from '../../../utils/hooks';

type Props = {
  children: React.ReactElement;
  /** Used only for styleguide, to not include the entire header, with routing and store provider */
  header?: boolean;
};

// @todo think about including the <Content /> here also
// apparently, we'll always include the <Content /> inside the <Wrapper />
// unless we need them separately, no need
export function Wrapper(props: Props) {
  const { children, header = true } = props;
  const { isDesktop } = useAdaptiveLayout();

  const style = {
    '--header-height': `${getHeaderSize()}px`,
  } as React.CSSProperties;

  return (
    <div data-desktop={isDesktop} style={style}>
      {header === true && <Header />}

      {children}
    </div>
  );

  function getHeaderSize(): number {
    if (!header) {
      return 0;
    }
    if (isDesktop) {
      return 72;
    }
    return 65;
  }
}

Wrapper.defaultProps = {
  header: true,
};
