import React, { useEffect, useMemo, useState } from 'react';
import styles from './EmployeePerformanceReportScreen.module.css';
import { inject, observer } from 'mobx-react';

import {
  Button,
  Card,
  Color,
  Content,
  Heading,
  Icon,
  IconEnum,
  Spacer,
  Table,
  Wrapper,
} from '../../components';
import { IClientStore, IEvaluationPeriodStore, IUserStore } from '../../stores';
import { EmployeePerformance, ReportRequest } from '../../types';
import { Column } from 'react-table';

interface Props
{
  clientStore?: IClientStore;
  evaluationPeriodStore?: IEvaluationPeriodStore;
  userStore?: IUserStore;
}

function EmployeePerformanceReportScreen(props: Props) {
  const clientStore = props.clientStore!;
  const evaluationPeriodStore = props.evaluationPeriodStore!;
  const userStore = props.userStore!;

  const [employees, setEmployees] = useState<EmployeePerformance[]>([]);
  const columns: Column<EmployeePerformance>[] = [{
    Header: 'Name',
    accessor: (row: EmployeePerformance) => {
      return <>{row.name} <sup className={styles.onPIP}>{row.on_pip ? 'PIP' : ''}</sup></>;
    },
    Footer: 'Totals',
  }, {
    Header: 'Goals:',
    accessor: (row: EmployeePerformance) => {
      return <>{row.goals_count}</>;
    },
    Footer: info => {
      const total = useMemo(
        () => info.rows.reduce(
          (sum, row) => row.original.goals_count + sum,
          0
        ),
        [info.rows]
      );

      return <>{total}</>;
    },
  }, {
    Header: 'Completed',
    accessor: (row: EmployeePerformance) => {
      return <>{row.goals_completed}</>;
    },
    Footer: info => {
      const total = useMemo(
        () => info.rows.reduce(
          (sum, row) => row.original.goals_completed + sum,
          0
        ),
        [info.rows]
      );

      return <>{total}</>;
    },
  }, {
    Header: 'In Progress',
    accessor: (row: EmployeePerformance) => {
      return <>{row.goals_in_progress}</>;
    },
    Footer: info => {
      const total = useMemo(
        () => info.rows.reduce(
          (sum, row) => row.original.goals_in_progress + sum,
          0
        ),
        [info.rows]
      );

      return <>{total}</>
    },
  }, {
    Header: 'Not Approved',
    accessor: (row: EmployeePerformance) => {
      return <>{row.goals_not_approved}</>;
    },
    Footer: info => {
      const total = useMemo(
        () => info.rows.reduce(
          (sum, row) => row.original.goals_not_approved + sum,
          0
        ),
        [info.rows]
      );

      return <>{total}</>;
    },
  }, {
    Header: 'Not Completed',
    accessor: (row: EmployeePerformance) => {
      return <>{row.goals_not_completed}</>;
    },
    Footer: info => {
      const total = useMemo(
        () => info.rows.reduce(
          (sum, row) => row.original.goals_not_completed + sum,
          0
        ),
        [info.rows]
      );

      return <>{total}</>;
    },
  }, {
    Header: 'Pending Approval',
    accessor: (row: EmployeePerformance) => {
      return <>{row.goals_pending}</>;
    },
    Footer: info => {
      const total = useMemo(
        () => info.rows.reduce(
          (sum, row) => row.original.goals_pending + sum,
          0
        ),
        [info.rows]
      );

      return <>{total}</>;
    },
  }, {
    Header: 'Obsolete',
    accessor: (row: EmployeePerformance) => {
      return <>{row.goals_obsolete}</>;
    },
    Footer: info => {
      const total = useMemo(
        () => info.rows.reduce(
          (sum, row) => row.original.goals_obsolete + sum,
          0
        ),
        [info.rows]
      );

      return <>{total}</>;
    },
  }, {
    Header: 'Applauses',
    accessor: (row: EmployeePerformance) => {
      return <>{row.high_fives_count}</>;
    },
    Footer: info => {
      const total = useMemo(
        () => info.rows.reduce(
          (sum, row) => row.original.high_fives_count + sum,
          0
        ),
        [info.rows]
      );

      return <>{total}</>;
    },
  }, {
    Header: 'Reviews',
    accessor: (row: EmployeePerformance) => {
      return <>{row.reviews_count}/{
        evaluationPeriodStore.checkInCycles
          .filter((cycle) => cycle.evaluation_period_id === clientStore.evaluationPeriod.id)
          .length
      }</>;
    },
  }];

  useEffect(() => {
    const request: ReportRequest = { check_in_cycle: clientStore.checkInCycle.id };
    userStore.getEmployeePerformance(request)
      .then(response => setEmployees(response));
  }, [
    clientStore.checkInCycle.id,
    userStore,
  ]);

  return (
    <Wrapper>
      <Content
        title="Employee Performance Report"
        header={
          <>
            <a href="/reports">
              <Icon
                icon={IconEnum.CHEVRON_LEFT}
                size={8}
                color={Color.GREY}
              />
              <Spacer horizontal={4} />
              Back
            </a>
            <Spacer vertical={8} />
            <Heading level={1}>Employee Performance Report</Heading>
          </>
        }
        body={renderBody()}
      />
    </Wrapper>
  );

  function renderBody() {
    return (
      <Card className={styles.reports_card}>
        <div className={styles.actions}>
          <h2>{clientStore.evaluationPeriod.label}</h2>
          <Button onClick={() => savePdf()}>Save PDF</Button>
        </div>

        {!employees.length
          ? (<div>No data to show.</div>)
          :
            (<Table
              data={employees}
              columns={columns}
              footer={true}
              className={styles.table}
            />)
        }
      </Card>
    );
  }

  async function savePdf()
  {
    const request: ReportRequest = { check_in_cycle: clientStore.checkInCycle.id };
    await userStore.downloadEmployeePerformance(request);
  }
}

export default inject(
  'clientStore',
  'evaluationPeriodStore',
  'userStore'
)(observer(EmployeePerformanceReportScreen));
