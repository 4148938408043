import React from 'react';
import styles from './Textarea.module.css';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  value: string;
  placeholder?: string;
  minRows?: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export function Textarea(props: Props) {
  const { onChange, onKeyDown, value, placeholder, minRows } = props;

  const styles = cx({
    textarea: true,
  });

  return (
    <TextareaAutosize
      minRows={minRows}
      className={styles}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      onKeyDown={onKeyDown}
    />
  );
}

Textarea.defaultProps = {
  minRows: 3,
};
