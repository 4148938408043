import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Card, Text, Color, ToggleableCard, Spacer } from '../../';
import { scrollInView } from '../../../utils/hooks';

type Props = {
  children: React.ReactNode;
};

export function OverallReviewCard(props: Props) {
  const { children } = props;

  const { search, key } = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const query = new URLSearchParams(search);
  const isOpened = !!query.get('review');

  useEffect(() => {
    if (isOpened) {
      setTimeout(() => {
        scrollInView(ref.current);
      }, 100);
    }
  }, [key, search, isOpened]);

  return (
    <Card>
      <ToggleableCard
        title="Check-in Reviews and Final Reviews for the Period"
        opened={true}
        extra={
          <>
            <Spacer vertical={4} />
            <Text color={Color.GREY}>
              Please provide an overall comment at the end of each review period.
              Focus on goals, discussions around expectations and address any
              questions or additional assignments. Frame comments in terms of
              behaviors: "Start, stop or continue". If it's helpful, you can view
              your employee's progress by viewing their Activity Report which can
              be accessed above.
            </Text>
          </>
        }>
        <div ref={ref}>{children}</div>
      </ToggleableCard>
    </Card>
  );
}
