import React, { useState } from 'react';
import styles from './Review.module.css';
import { useAdaptiveLayout } from '../../../utils/hooks';
import { CheckInCycle, Review as ReviewType } from '../../../types';

import {
  Inline,
  Icon,
  IconEnum,
  Color,
  Text,
  Spacer,
  Textarea,
  Button,
} from '../../';

interface Props {
  children?: React.ReactNode;
  review: ReviewType;
  readOnly?: boolean;
  evaluationPeriod: string;
  onEdit?: (reviewId: number, review: string) => void;
}

export function Review(props: Props) {
  const { review, children, readOnly, evaluationPeriod, onEdit } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [text, setText] = useState(review.review);

  const { isDesktop } = useAdaptiveLayout();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Icon
          icon={
            (review.period as CheckInCycle)?.evaluation_period_id
              ? IconEnum.CHECK_CIRCLE
              : IconEnum.FINAL_REVIEW
          }
          color={Color.SECONDARY}
          size={24}
        />

        <Spacer horizontal={16} />

        <div>
          {(review.period as CheckInCycle).evaluation_period_id ? (
            <Text bold inline>
              {isDesktop ? 'Check in review for' : ''} {evaluationPeriod} |{' '}
              <Text color={Color.PRIMARY} bold inline>
                {review.period.label}
              </Text>
            </Text>
          ) : (
            <Text bold inline>
              Final Evaluation |{' '}
              <Text color={Color.PRIMARY} bold inline>
                {evaluationPeriod}
              </Text>
            </Text>
          )}

          <Spacer horizontal={8} />
          <Text color={Color.GREY} size={10} inline={isDesktop}>
            Added on {formatDateShort(new Date(review.created_at))} by{' '}
            {readOnly ? review.reviewer.name : 'you'}
          </Text>
        </div>
        {!readOnly && (
          <>
            {!isEdit && (
              <button
                onClick={() => setIsEdit(true)}
                className={styles.edit_btn}>
                <Icon icon={IconEnum.EDIT} />
              </button>
            )}
          </>
        )}
      </div>
      <Spacer vertical={isEdit ? 16 : 8} />
      <div className={styles.content}>
        {isEdit ? renderEditReview() : <Text>{text}</Text>}
        {children}
      </div>
    </div>
  );

  function renderEditReview() {
    return (
      <>
        <Textarea
          onChange={(e) => setText(e.target.value)}
          onKeyDown={() => {}}
          value={text}
          placeholder="Add your review here..."
        />
        <Spacer vertical={16} />
        <Inline gap={24}>
          <Button onClick={updateReview} disabled={text === review.review}>
            Save Review
          </Button>
          <Button role="tertiary" onClick={cancelEdit}>
            Cancel
          </Button>
        </Inline>
      </>
    );
  }

  function updateReview() {
    onEdit && onEdit(review.id, text);
    setIsEdit(false);
    setText(text);
  }

  function cancelEdit() {
    setIsEdit(false);
  }

  function formatDateShort(date: Date) {
    return new Intl.DateTimeFormat('default', {
      day: 'numeric',
      month: 'short',
      timeZone: 'UTC',
    }).format(date);
  }
}

Review.defaultProps = {
  readOnly: false,
};
