import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { User } from '../types';
import stores from '../stores';
import styles from './utils.module.css';

export function useAdaptiveLayout() {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  return { isDesktop };
}

export function useCurrentUser(): User {
  // @todo need to test if this would update/change when the userStore changes
  // I guess it won't, and we'll have to use this: https://mobx-react.js.org/observer-hook
  // to test: create a dummy action that changes the user name
  return stores.userStore.user;
}

export function useOnClickOutside(
  ref: React.RefObject<HTMLElement> | null,
  handler: (event: Event) => void,
) {
  useEffect(
    () => {
      const listener = (event: Event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (ref?.current?.contains(event.target as Node)) {
          return;
        }

        handler(event);
      };

      const preventPropagation = (event: Event) => {
        event.stopPropagation();
      };

      ref?.current?.addEventListener('mousedown', preventPropagation);
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        ref?.current?.removeEventListener('mousedown', preventPropagation);
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  );
}

// not a hook, not a pure function, not sure where to put it :|
export function scrollInView(elem: HTMLElement | null) {
  if (elem) {
    elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
    elem.classList.toggle(styles.highlight);
  }
}
