import React, { useEffect, useState } from 'react';
import styles from './ApplicationUsageReportScreen.module.css';
import { inject, observer } from 'mobx-react';

import {
  Button,
  Card,
  Color,
  Content,
  Heading,
  Icon,
  IconEnum,
  Spacer,
  Table,
  Wrapper,
} from '../../components';
import { IClientStore } from '../../stores';
import { ApplicationUsage } from '../../types';
import { Column } from 'react-table';

interface Props
{
  clientStore?: IClientStore;
}

function ApplicationUsageReportScreen(props: Props) {
  const clientStore = props.clientStore!;

  const [clients, setClients] = useState<ApplicationUsage[]>([]);
  const columns: Column<ApplicationUsage>[] = [{
      Header: 'Company',
      accessor: 'name',
    }, {
      Header: '# of Users',
      accessor: 'users_count',
    }, {
      Header: '# of Goals',
      accessor: 'goals_count',
    }, {
      Header: '# of Reviews',
      accessor: 'reviews_count',
    }, {
      Header: '# of Applauses',
      accessor: 'high_fives_count',
  }];

  useEffect(() => {
    clientStore.getApplicationUsage()
      .then(response => setClients(response));
  }, [
    clientStore,
  ]);

  return (
    <Wrapper>
      <Content
        title="Application Usage Report"
        header={
          <>
            <a href="/reports">
              <Icon
                icon={IconEnum.CHEVRON_LEFT}
                size={8}
                color={Color.GREY}
              />
              <Spacer horizontal={4} />
              Back
            </a>
            <Spacer vertical={8} />
            <Heading level={1}>Application Usage Report</Heading>
          </>
        }
        body={renderBody()}
      />
    </Wrapper>
  );

  function renderBody() {
    return (
      <Card className={styles.reports_card}>
        <div className={styles.actions}>
          <Button onClick={() => savePdf()}>Save PDF</Button>
        </div>

        {!clients.length
          ? (<div>Loading…</div>)
          :
            (<Table
              data={clients}
              columns={columns}
              className={styles.table}
            />)
        }
      </Card>
    );
  }

  async function savePdf()
  {
    await clientStore.downloadApplicationUsage();
  }
}

export default inject('clientStore')(observer(ApplicationUsageReportScreen));
