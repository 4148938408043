import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './PerformanceReviewModal.module.css';
import {
  Button,
  Color,
  Heading,
  Icon,
  IconEnum,
  Modal,
  Spacer,
  Stack,
} from '../../';
import { ClientStandardsReview, ClientStandardsScore, Comment, Employee } from '../../../types';
import { IEmployeeStore, IStandardStore } from '../../../stores';
import { autorun } from 'mobx';
import { filterScores } from './PerformanceReviewModal.utils';
import { EmployeeScore } from "./EmployeeScore";

interface Props {
  employee: Employee;
  review: ClientStandardsReview;
  isVisible: boolean;
  employeeStore?: IEmployeeStore;
  standardStore?: IStandardStore;
  onClose: () => void;
}

function _PerformanceReviewModal(props: Props) {
  const {
    employee,
    review,
    isVisible,
    onClose
  } = props;

  const employeeStore = props.employeeStore!;
  const standardStore = props.standardStore!;
  const { reviewComments, scores } = standardStore;

  const [comments, setComments] = useState<Comment[]>([]);
  const [filteredScores, setFilteredScores] = useState<ClientStandardsScore[]>([]);

  useEffect(
    () => {
      standardStore.fetchScores(review.id);
      standardStore.fetchReviewComments(review.id);
    },
    [review.id, standardStore]
  );

  useEffect(
    () => autorun(() => {
      const filtered = filterScores(scores, review.id);
      setFilteredScores(filtered);
    }),
    [scores, review.id]
  );

  useEffect(
    () => autorun(() => { setComments(reviewComments.get(review.id) ?? []) }),
    [reviewComments, review.id]
  );

  return (
    <div className={styles.performanceReviewModal}>
      <Modal isOpen={isVisible} onClose={closeModal}>
        <div className={styles.container}>
          {renderHeader()}

          {renderBody()}

          {renderFooter()}
        </div>
      </Modal>
    </div>
  );

  function renderHeader() {
    return (
      <div className={styles.header}>
        <Heading level={2} color={"inherit"}>
          <small>{employee.name}'s</small>
          Performance Standard
        </Heading>
        <p className={styles.period}>{ review.period.label }</p>
      </div>
    );
  }

  function renderBody() {
    return (
      <div className={styles.body}>
        {renderComments()}

        {renderScores()}
      </div>
    );
  }

  function renderFooter() {
    return (
      <div className={styles.footer}>
        <Button
          role={"tertiary"}
          onClick={requestMeeting}
        >
          <Icon
            icon={IconEnum.CALENDAR}
            color={Color.PRIMARY}
          />
          <Spacer horizontal={8} />
          Request Manager Meeting
        </Button>
      </div>
    );
  }

  function renderComments() {
    return (
      <div className={styles.comments}>
        <Stack gap={16}>
          {comments.map(
            ({ id, comment }) =>
              <p className={styles.comment} key={id}>{comment}</p>
          )}
        </Stack>
      </div>
    );
  }

  function renderScores() {
    return (
      <div className={styles.scores}>
        <Stack gap={16}>
          {filteredScores.map(
            (score) => <EmployeeScore score={score} key={score.id}/>
          )}
        </Stack>
      </div>
    );
  }

  function closeModal() {
    onClose();
  }

  async function requestMeeting() {
    closeModal();
    employeeStore.showRequestMeetingModal();
  }
}

export const PerformanceReviewModal = inject(
  'employeeStore',
  'standardStore'
)(observer(_PerformanceReviewModal));
