import React, { FormEvent, useEffect, useState } from 'react';
import { Goal, GoalProgress, GoalStatus, UserRole } from '../../../types';
import { Input, Radio, Select, Textarea } from '../../form';
import { Divider, Inline, Spacer, Stack } from '../../layout';
import { Checkbox } from '../../form';
import { Button, Text } from '../../base';
import { Color } from '../../styles';
import styles from './GoalForm.module.css';
import {
  formatDeadline,
  formatEvaluationPeriodEndDate,
} from './GoalForm.utils';
import { GoalPayload } from '../../../stores/goalStore';
import { inject, observer } from 'mobx-react';
import { IClientStore, IEvaluationPeriodStore } from '../../../stores';
import { useLocation } from 'react-router-dom';

const measurableDropdownValues = [
  { label: '# (numeric)', value: GoalProgress.NUMERIC },
  { label: '$ (currency)', value: GoalProgress.CURRENCY },
  { label: '% (percentage)', value: GoalProgress.PERCENTAGE },
];

interface Props {
  role: UserRole;
  goal: Goal;
  clientStore?: IClientStore;
  evaluationPeriodStore?: IEvaluationPeriodStore;
  onSubmit: (goal: GoalPayload) => void;
  onCancel: () => void;
}

function GoalFormWithoutStore(props: Props) {
  const clientStore = props.clientStore!;
  const evaluationPeriodStore = props.evaluationPeriodStore!;
  const { goal, onSubmit, onCancel, role } = props;
  const { search, key } = useLocation();

  const [title, setTitle] = useState(goal.title);
  const [description, setDescription] = useState(goal.description);
  const [target, setTarget] = useState(goal.progress_target?.toString());
  const [progressType, setProgressType] = useState(goal.progress_type);
  const [evaluationPeriod, setEvaluationPeriod] = useState(clientStore.evaluationPeriod.id);
  const [evaluationPeriodEndDate, setEvaluationPeriodEndDate] = useState(clientStore.evaluationPeriod.end_date);

  const sameEndDate =
    formatDeadline(goal.deadline) === formatDeadline(evaluationPeriodEndDate);

  const [customDeadline, setDeadline] = useState(
    !sameEndDate ? formatDeadline(goal.deadline) : '',
  );

  const isEditable = goal.id !== 0;
  const isChecked = isEditable;

  const [specific, setSpecific] = useState(isChecked);
  const [measurable, setMeasurable] = useState(isChecked);
  const [achievable, setAchievable] = useState(isChecked);
  const [relevant, setRelevant] = useState(isChecked);
  const [timely, setTimely] = useState(isChecked);
  const [isCustomDeadline, setCustomDeadline] = useState(
    !!goal.deadline && !sameEndDate,
  );
  const [approved, setApproved] = useState(
    goal.status === GoalStatus.NOT_APPROVED
      ? 'no'
      : goal.status === GoalStatus.IN_PROGRESS
        ? 'yes'
        : ''
  );
  const [comment, setComment] = useState('');

  const isDisabled =
    title.trim().length &&
    specific &&
    measurable &&
    achievable &&
    relevant &&
    timely;

  const title_word_count = title.length;
  const title_total_length = 256;

  useEffect(() => {
    const query = new URLSearchParams(search);
    const evaluationPeriodId = query.get('evaluation-period');

    if (!evaluationPeriodId) {
      setEvaluationPeriod(clientStore.evaluationPeriod.id);
      setEvaluationPeriodEndDate(clientStore.evaluationPeriod.end_date);
      return;
    }

    const period = evaluationPeriodStore.evaluationPeriods.find(({ id }) => id === Number(evaluationPeriodId));

    if (period) {
      setEvaluationPeriod(period.id);
      setEvaluationPeriodEndDate(period.end_date);
    }
  }, [key, search, clientStore, evaluationPeriodStore]);

  return (
    <form onSubmit={submitForm}>
      <div className={styles.goal_form}>
        <Input
          placeholder={'Goal title'}
          size={'large'}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <span className={styles.word_count}>
          <ins className={
            title_word_count > title_total_length
              ? styles.word_count_over
              : ''
            }>{title_word_count}</ins> / {title_total_length}
        </span>

        <Spacer vertical={16} />

        <Textarea
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          value={description}
          placeholder={'Describe your goal'}
        />

        <Spacer vertical={32} />

        <Text bold size={16}>
          Is your goal SMART?
        </Text>
        <Text>
          Please check through the boxes below to make sure your goal is
          properly set
        </Text>

        <Spacer vertical={24} />

        <Stack gap={8}>
          {renderSpecificSection()}
          {renderMeasurableSection()}
          {renderAchievableSection()}
          {renderRealisticSection()}
          {renderTimelySection()}
        </Stack>
      </div>

      {role === 'manager' &&
        renderApprovalSection()
      }

      <Divider />

      <div className={styles.goal_form}>
        <Inline gap={8}>
          <Button disabled={!isDisabled} onClick={() => {}}>
            Save goal
          </Button>

          <Spacer horizontal={16} />

          <Button role={'tertiary'} onClick={onCancel}>
            Cancel
          </Button>
        </Inline>
      </div>
    </form>
  );

  function renderApprovalSection() {
    return(
      <div className={styles.goal_form}>
        { goal.status === GoalStatus.PENDING &&
          <Inline gap={16}>
            <label className={styles.grid_label}>
              <Inline gap={16}>
                <Radio
                  checked={approved === 'yes'}
                  onChange={() => {
                    setApproved('yes');
                  }}
                  name={'approved'}
                  value={'yes'}
                />
                <Text
                  bold
                  inline
                  color={Color.DARK_GREY}>
                  Goal Approved
                </Text>
              </Inline>
            </label>

            <label className={styles.grid_label}>
              <Inline gap={16}>
                <Radio
                  checked={approved === 'no'}
                  onChange={() => {
                    setApproved('no');
                  }}
                  name={'approved'}
                  value={'no'}
                />
                <Text bold color={Color.DARK_GREY}>
                  Goal Not Approved
                </Text>
              </Inline>
            </label>
          </Inline>
        }

        <Spacer vertical={16} />

        <Textarea
          onChange={(e) => {
            setComment(e.target.value);
          }}
          value={comment}
          placeholder={approved === 'no'
            ? 'Explain why the goal is not approved'
            : 'Additional comments'
          }
        />
      </div>
    );
  }

  function renderSpecificSection() {
    return (
      <SmartBox
        checked={specific}
        onToggle={setSpecific}
        title="Specific"
        description="Be as clear and specific as possible with what you want
      achieved. Avoid confusion. Is the goal clear and specific? What
      do you want accomplished? What actions should be taken?"
      />
    );
  }

  function renderMeasurableSubSection() {
    return (
      <Stack gap={16}>
        <Text>Set a numeric target for this goal (Optional):</Text>
        <Inline align={'start'} gap={16}>
          <Input
            onChange={(e) => setTarget(e.target.value)}
            placeholder={'No target defined'}
            value={target}
            required
          />

          <Select
            placeholder="Select type"
            values={measurableDropdownValues}
            onChange={(e) => setProgressType(e.target.value as GoalProgress)}
            defaultValue={String(progressType)}
          />
        </Inline>
      </Stack>
    );
  }

  function renderMeasurableSection() {
    return (
      <SmartBox
        checked={measurable}
        onToggle={setMeasurable}
        title="Measurable"
        description="Set out what success will look like – either quantitative or
        qualitative. Can you track progress? How will you know when it
        is accomplished?"
        children={renderMeasurableSubSection()}
      />
    );
  }

  function renderAchievableSection() {
    return (
      <SmartBox
        checked={achievable}
        onToggle={setAchievable}
        title="Achievable"
        description="Set the goal so that it can be reasonably accomplished. Is the
        goal do-able? How realistic is the goal based on skills and
        resources?"
      />
    );
  }

  function renderRealisticSection() {
    return (
      <SmartBox
        checked={relevant}
        onToggle={setRelevant}
        title="Relevant"
        description="Align the goal to overall organizational objectives. Why is the
        goal important? How does the goal align with broader goals?"
      />
    );
  }

  function renderTimelySubSection() {
    return (
      <div className={styles.grid}>
        <label className={styles.grid_label}>
          <Inline gap={16}>
            <Radio
              checked={!isCustomDeadline}
              onChange={() => {
                setCustomDeadline(false);
              }}
              name={'period'}
              value={'end_of_evaluation'}
            />
            <Text
              bold
              inline
              color={isCustomDeadline ? Color.GREY : Color.DARK_GREY}>
              End of evaluation period
            </Text>
          </Inline>
        </label>
        <div className={styles.grid_content}>
          <Text color={isCustomDeadline ? Color.GREY : Color.DARK_GREY}>
            {formatEvaluationPeriodEndDate(evaluationPeriodEndDate)}
          </Text>
        </div>

        <div className={styles.grid_divider}></div>

        <label className={styles.grid_label}>
          <Inline gap={16}>
            <Radio
              checked={isCustomDeadline}
              onChange={() => {
                setCustomDeadline(true);
              }}
              name={'period'}
              value={'end_of_date'}
            />

            <Text bold color={isCustomDeadline ? Color.DARK_GREY : Color.GREY}>
              Custom deadline
            </Text>
          </Inline>
        </label>
        <div className={styles.grid_content}>
          {' '}
          <Input
            onChange={(e) => setDeadline(e.target.value)}
            value={customDeadline}
            disabled={!isCustomDeadline}
            type={'date'}
          />
        </div>
      </div>
    );
  }

  function renderTimelySection() {
    return (
      <SmartBox
        checked={timely}
        onToggle={setTimely}
        title="Time Based"
        description="Set the goal so it is time related. When should the goal be
        achieved? Is there a specific timeframe?"
        children={renderTimelySubSection()}
      />
    );
  }

  function submitForm(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    let deadline;
    let status;

    if (approved && approved === 'yes') {
      status = GoalStatus.IN_PROGRESS
    }

    if (approved && approved === 'no') {
      status = GoalStatus.NOT_APPROVED
    }

    if (role === 'employee' && status === GoalStatus.NOT_APPROVED) {
      status = GoalStatus.PENDING
    }

    if (isCustomDeadline) deadline = customDeadline;

    const goalPayload: GoalPayload = {
      comment,
      deadline,
      description,
      status,
      title,
      id: goal.id,
      progress_target: parseFloat(target),
      progress_type: progressType,
      client_evaluation_period_id: evaluationPeriod,
    };

    onSubmit(goalPayload);
  }
}

export const GoalForm = inject('clientStore', 'evaluationPeriodStore')(
  observer(GoalFormWithoutStore),
);

type SmartBoxProps = {
  title: string;
  description: string;
  checked: boolean;
  onToggle(checked: boolean): void;
  children?: React.ReactNode;
};

function SmartBox(props: SmartBoxProps) {
  const { checked, description, title, onToggle, children } = props;

  return (
    <div className={styles.smart_box}>
      <div className={styles.smart_box_padded}>
        <label>
          <Inline gap={16} align={'start'}>
            <Checkbox checked={checked} onChange={() => onToggle(!checked)} />

            <div>
              <Text bold size={16}>
                <Text inline color={Color.PRIMARY}>
                  {title[0]}
                </Text>
                {title.slice(1)}
              </Text>
              <Spacer vertical={4} />
              <Text>{description}</Text>
            </div>
          </Inline>
        </label>
      </div>

      {checked && children && (
        <>
          <Divider />
          <div className={styles.smart_box_details}>{children}</div>
        </>
      )}
    </div>
  );
}
