import React, { MouseEventHandler } from 'react';
import classNames from 'classnames/bind';

import styles from './Card.module.css';

const cx = classNames.bind(styles);

type Props = {
  children: React.ReactNode;
  background?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export function Card(props: Props) {
  const { className, children, background = '#fff' } = props;
  const css = className || '';

  const empty = () => {};

  const onClick = props.onClick ?? empty;

  const _className = cx({
    card: true,
    [css]: css,
  });

  return (
    <div className={_className} style={{ backgroundColor: background }} onClick={onClick}>
      {children}
    </div>
  );
}
