import { useState, useEffect } from 'react';

const zxcvbn = require('zxcvbn');

export const usePasswordValidation = ({
  password = '',
  confirmPassword = '',
  requiredLength = 8
}) => {
  const [strength, setStrength] = useState<number>(0);
  const [validLength, setValidLength] = useState<boolean|null>(null);
  const [match, setMatch] = useState<boolean|null>(null);
  const [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    const isValidLength = password.length >= requiredLength;
    const isMatch = !!password && password === confirmPassword;

    setStrength(zxcvbn(password).score);
    setValidLength(isValidLength);
    setMatch(isMatch);
    setValid(isValidLength && isMatch);
  }, [password, confirmPassword, requiredLength]);

  return {
    strength,
    validLength,
    match,
    valid
  };
}
