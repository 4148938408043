import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import config from '../config';
import {
  setAccessTokenData,
  getAccessToken,
  clearAuthData,
} from './AuthRepository';
import { AccessTokenData } from '../types';
import { clearClientData } from './ClientRepository';
import { history } from './history';

axios.defaults.baseURL = `${config.API_URL}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.timeout = 10000; //milliseconds

const apiConfig = {
  baseURL: `${config.API_URL}/api/`,
};
export const api = axios.create(apiConfig);

let lockRefreshToken: boolean = false;
let refreshTokenRequest: Promise<void> | null = null;

api.interceptors.request.use(
  async (requestConfig) => {
    const accessToken = localStorage.getItem('access_token');
    requestConfig.headers.Authorization = 'Bearer ' + accessToken;
    return requestConfig;
  },
  async (error: AxiosError) => {
    console.warn(`Request error (${error.config.url})`);
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    const status = error?.response?.status;

    if (status === 401) {
      const initialRequest = error.config;

      if (!lockRefreshToken) {
        lockRefreshToken = true;
        refreshTokenRequest = refreshToken();
      }

      await refreshTokenRequest;

      return await retryInitialRequest(initialRequest);
    }

    throw error;

    // try {
    //   const status = error?.response?.status;

    //   if (status === 401) {
    //     const initialRequest = error.config;

    //     if (!lockRefreshToken) {
    //       lockRefreshToken = true;
    //       refreshTokenRequest = refreshToken();
    //     }

    //     await refreshTokenRequest;

    //     return await retryInitialRequest(initialRequest);
    //   }
    // } catch (e) {
    //   handleRequestError(e);
    // }
  },
);

async function refreshToken() {
  try {
    const refreshAccessTokenResponse = await api.post('auth/refresh');
    const access_token_data = refreshAccessTokenResponse.data as AccessTokenData;

    setAccessTokenData(access_token_data);

    lockRefreshToken = false;
  } catch (error) {
    clearAuthData();
    clearClientData();
    history.push('/');
  }
}
async function retryInitialRequest(initialConfig: AxiosRequestConfig) {
  const { method, baseURL, url, data, headers } = initialConfig;

  const accessToken = getAccessToken();

  console.info(
    `Retrying initial request (${baseURL}${url})... with new accessToken (${accessToken})`,
  );

  try {
    const requestConfig = {
      method,
      url: `${baseURL}${url}`,
      data,
      headers: {
        ...headers,
        Authorization: 'Bearer ' + accessToken,
      },
    };
    return await axios(requestConfig);
  } catch (error) {
    console.info(
      `Re-trying ${url} with accessToken (${accessToken}) failed`,
      error,
    );
  }
}

// function handleRequestError(error: AxiosError) {
//   try {
//     const { status, headers, data, config } = error?.response || {};

//     if (status && status >= 500) {
//       // @todo figure out how to present this to the user - alert or something else
//       console.warn('Server is under maintenance. Please try again later.');
//     }

//     console.warn('REQUEST ERROR', { status, headers, data, config });
//   } catch (e) {
//     throw error;
//   }
// }
