import { Notification, NotificationType } from '../../../types/index';
import { IconEnum } from '../../../components';

export const DUMMY_SUCCESS_NOTIFICATION: Notification = {
  id: 1,
  message: 'Check-in successfully added!',
  type: NotificationType.SUCCESS,
};

export const DUMMY_ERROR_NOTIFICATION: Notification = {
  id: 1,
  message: 'Something went wrong!',
  type: NotificationType.ERROR,
};

export const DUMMY_INFO_NOTIFICATION: Notification = {
  id: 1,
  message: 'Simple information',
  type: NotificationType.INFO,
};

export function getBackgroundColor(type: string) {
  switch (type) {
    case NotificationType.SUCCESS: {
      return '#F0FFF5';
    }
    case NotificationType.ERROR: {
      return '#FFEDEE';
    }
    case NotificationType.INFO: {
      return '#fff';
    }
    default: {
      return '#F0FFF5';
    }
  }
}

export function getIcon(type: string) {
  switch (type) {
    case NotificationType.SUCCESS: {
      return IconEnum.CHECK_CIRCLE;
    }
    case NotificationType.ERROR: {
      return IconEnum.ERROR;
    }
    case NotificationType.INFO: {
      return IconEnum.INFO;
    }
    default: {
      return IconEnum.CHECK_CIRCLE;
    }
  }
}
