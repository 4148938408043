import React from 'react';
import { Employee } from '../../types';
import { Link, useHistory } from 'react-router-dom';
import {
  Card,
  Color,
  Icon,
  IconEnum,
  Inline,
  PipBadge,
  ProfileImage,
  Spacer,
  Text,
} from '../../components';

import styles from './EmployeeCard.module.css';
import { useAdaptiveLayout } from '../../utils/hooks';
import { observer } from 'mobx-react';

type Props = {
  employee: Employee;
};

function _EmployeeCardPure(props: Props) {
  const {
    id,
    name,
    employees_count,
    goals_count,
    high_fives_count,
    on_pip
  } = props.employee;

  const { isDesktop } = useAdaptiveLayout();

  const history = useHistory();


  return (
    <Card className={styles.employee} onClick={goToEmployee}>
      <Link to={`/employee/${id}`}>
        <ProfileImage name={name} size={'large'} />
      </Link>

      {isDesktop ? <Spacer horizontal={16} /> : <Spacer vertical={16} />}

      <div>
        <Inline gap={8}>
          <Link to={`/employee/${id}`}>
            <Text
              bold
              lineHeight={1.2}
              inline={true}
            >
              {name}
            </Text>
            <Spacer horizontal={8} />
            {on_pip && <PipBadge size="small" />}
          </Link>
        </Inline>

        {/* <Text color={Color.GREY}>{'Developer'}</Text> */}

        <Spacer vertical={8} />

        <div className={styles.stats}>
          {renderGoals(goals_count)}

          <Spacer horizontal={16} />

          {renderHighFives(high_fives_count)}

          <Spacer horizontal={16} />

          {renderEmployees(employees_count)}
        </div>
      </div>
    </Card>
  );


  function goToEmployee() {
    if (isDesktop) return;
    history.push(`/employee/${id}`);
  }

  function renderGoals(value: number) {
    return (
      <>
        <Icon
          icon={IconEnum.GOAL}
          size={16}
          color={value ? Color.GREY : Color.PRIMARY}
        />
        <Spacer horizontal={4} />
        <Text color={value ? Color.DARK_GREY : Color.PRIMARY}>{value}</Text>
      </>
    );
  }

  function renderHighFives(value: number) {
    return (
      <>
        <Icon
          icon={IconEnum.HIGH5}
          size={16}
        />
        <Spacer horizontal={4} />
        <Text>{value}</Text>
      </>
    );
  }

  function renderEmployees(value: number) {
    return (
      value
        ?
          <Link to={`/manager/${id}`} >
            <Icon
              icon={IconEnum.TEAM}
              size={16}
            />
            <Spacer horizontal={4} />
            <Text inline={true}>{value}</Text>
          </Link>
        : <>
          <Icon
            icon={IconEnum.TEAM}
            size={16}
          />
          <Spacer horizontal={4} />
          <Text inline={true}>{value}</Text>
        </>
    );
  }
}

export const EmployeeCard = observer(_EmployeeCardPure);
