import React from 'react';

import { Spacer, Space } from '../';

const Align = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  stretch: 'stretch',
};

export type Align = keyof typeof Align;

type Props = {
  children: React.ReactNode;
  direction: 'row' | 'column';
  align?: Align;
  gap?: Space;
};

function Flex(props: Props) {
  const { children, direction, gap = 0, align = 'start' } = props;

  if (typeof gap !== 'undefined' && Array.isArray(children)) {
    const Gap = () =>
      direction === 'column' ? (
        <Spacer vertical={gap} />
      ) : (
        <Spacer horizontal={gap} />
      );

    return (
      <div
        style={{
          flexDirection: direction,
          display: 'flex',
          alignItems: Align[align],
        }}>
        {children
          // ignore any "null | undefined" children
          .filter((child) => !!child)
          .map((child, index) => {
            return (
              <React.Fragment key={index}>
                {gap > 0 && index > 0 && <Gap />}
                {child}
              </React.Fragment>
            );
          })}
      </div>
    );
  }

  return <>{children}</>;
}

export default Flex;
