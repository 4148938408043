import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './EditCompanyValueModal.module.css';
import {
  Button,
  Heading,
  Input,
  Modal,
  Spacer,
  Text,
} from '../../';
import { IHigh5Store } from '../../../stores';
import { CompanyValue } from '../../../types';

interface Props {
  high5Store?: IHigh5Store;
  value: CompanyValue;
  isVisible: boolean;
  onClose: () => void;
}

function _EditCompanyValueModal(props: Props) {
  const { isVisible, value, onClose } = props;
  const high5Store = props.high5Store!;
  const [name, setName] = useState(value.name);

  return (
    <Modal isOpen={isVisible} onClose={closeModal}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Heading level={2}>Edit Company Value</Heading>
        </div>

        <div className={styles.body}>
          <label>
            <Text bold>Company Value</Text>

            <Spacer vertical={8} />

            <Input
              placeholder={''}
              size={'large'}
              value={name}
              onChange={({ target }) => setName(target.value)}
            />
          </label>
        </div>

        <div className={styles.footer}>
          <Button
            role='primary'
            onClick={submit}
            disabled={!name}
          >Submit</Button>
        </div>
      </div>
    </Modal>
  );

  function closeModal() {
    onClose();
  }

  function reset() {
    setName('');
  }

  async function submit() {
    await high5Store.updateCompanyValue(value.id as number, { name });

    reset();

    closeModal();
  }
}

export const EditCompanyValueModal = inject(
  'high5Store',
)(observer(_EditCompanyValueModal));
