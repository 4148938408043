import { ObservableMap } from "mobx";
import { ClientStandardsScore, PerformanceReviewStatus } from "../../../types";

type PerformanceReviewStatusStyling = {
  label: string;
  color: string;
};

export const PERFORMANCE_REVIEW_STATUS_STYLING = new Map<PerformanceReviewStatus, PerformanceReviewStatusStyling>([
  [PerformanceReviewStatus.COMPLETED, { label: 'Completed', color: '#5fbf81' }],
  [PerformanceReviewStatus.IN_PROGRESS, { label: 'In Progress', color: '#C3EAD8' }],
  [PerformanceReviewStatus.NOT_COMPLETED, { label: 'Not completed', color: '#e07930' }],
]);

export function filterScores(scores: ObservableMap<number, ClientStandardsScore>, reviewId: number): ClientStandardsScore[] {
  return Array.from(scores.values())
    .filter((score) => score.review_id === reviewId);
}
