import React from "react";
import InputRange, { Range } from "react-input-range";
import classNames from "classnames/bind";
import styles from "./Progress.module.css";
import "react-input-range/lib/css/index.css";

const cx = classNames.bind(styles);

interface Props {
  max: number;
  min?: number;
  value: number;
  disabled?: boolean;
  children: React.ReactNode;
  labelPosition?: 'before'|'after';
  onChange?(value: number|Range): void;
}

export function Progress(props: Props) {
  const {
    max = 1,
    min = 0,
    value,
    disabled,
    children,
    labelPosition = 'after',
    onChange = () => {},
  } = props;

  const progressStyles = cx({
    'progress': true,
    'labelBefore': labelPosition === 'before',
  });

  return (
    <div className={progressStyles}>
      <InputRange
        value={value}
        maxValue={max || 1}
        minValue={min}
        onChange={(val) => onChange(val)}
        disabled={disabled}
      />

      <label className={styles.progress__label}>
        {children}
      </label>
    </div>
  );
}
