import { AccessTokenData, User, UserRole } from '../types';

enum AuthData {
  ACCESS_TOKEN = 'access_token',
  ACCESS_TOKEN_EXPIRY_DATE = 'access_token_expiry_date',
  TOKEN_TYPE = 'token_type',
  CURRENT_USER = 'current_user',
  ROLE = 'role',
}

export function setAccessTokenData(accessTokenData: AccessTokenData) {
  const { access_token, expires_in, token_type } = accessTokenData;
  localStorage.setItem(AuthData.ACCESS_TOKEN, access_token);
  localStorage.setItem(AuthData.ACCESS_TOKEN_EXPIRY_DATE, expires_in);
  localStorage.setItem(AuthData.TOKEN_TYPE, token_type);
}

export function getAccessToken() {
  return localStorage.getItem(
    AuthData.ACCESS_TOKEN,
  ) as AccessTokenData['access_token'];
}

export function setUser(user: User) {
  localStorage.setItem(AuthData.CURRENT_USER, JSON.stringify(user));
}

export function getUser(): User {
  return JSON.parse(localStorage.getItem(AuthData.CURRENT_USER) || '{}');
}

export function setRole(role: UserRole) {
  localStorage.setItem(AuthData.ROLE, role);
}

export function getRole(): UserRole | null {
  const role = localStorage.getItem(AuthData.ROLE);

  return (role as UserRole) || null;
}

export function clearAuthData() {
  Object.values(AuthData).forEach((item) => localStorage.removeItem(item));
}
