import React, { useEffect } from 'react';
import { inject, observer } from "mobx-react"
import { IClientStore, IStandardStore } from '../../../stores';

type Props = {
  clientStore?: IClientStore
  standardStore?: IStandardStore
}

function _Standards(props: Props) {
  const clientStore = props.clientStore!;
  const standardStore = props.standardStore!;
  const { standards } = standardStore;

  useEffect(() => {
    standardStore.fetchStandards(clientStore.client.id);
  }, [clientStore.client.id, standardStore]);

  return (
    <>
      <h1>Standards</h1>

      <div>
        {standards.map((standard) => {
          const { id, name, description } = standard;

          return (
            <div key={id}>
              <h2>{ name }</h2>
              <p>{ description }</p>
            </div>
          );
        })}
      </div>
    </>
  );
}

export const Standards = inject(
  'clientStore',
  'standardStore',
)(observer(_Standards));
