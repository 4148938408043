import dayjs from "dayjs";
import mime from 'mime-types';

interface Headers {
  "content-type": string;
  "content-disposition": string;
}

export function capitalize(s: string) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getCssVariableName(name: string, prefix?: string) {
  const lowerCase = name.toLowerCase();
  const kebabCase = lowerCase.replace(/_/g, '-');
  const prefixed = prefix ? `${prefix.toLowerCase()}-${kebabCase}` : kebabCase;
  return `--${prefixed}`;
}

export function getFileName(headers: Headers)
{
  const extension = mime.extension(headers["content-type"]);
  const disposition = headers["content-disposition"];
  let filename: string = dayjs().format('YYYY_MM_DD_HH_mm_ss') + "." + extension;

  if (disposition && disposition.indexOf('attachment') !== -1)
  {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
  }

  return filename;
}

export function formatDate(value: string, format: string = 'MM/DD/YYYY hh:mm a'): string {
  const date = dayjs(value);
  return date.format(format);
}

export function formatPercent(value: number): string
{
  return new Intl.NumberFormat('en-US', { style: 'percent' })
    .format(value)
}

export function compareDates(a: string|number, b: string|number, comparator?: '=='|'<='|'>='|'<'|'>'): boolean {
  const date1 = dayjs(a);
  const date2 = dayjs(b);
  const compare = comparator || '==';

  if (!date1.isValid()) {
    throw new Error(`${a} is not a valid date`);
  }

  if (!date2.isValid()) {
    throw new Error(`${b} is not a valid date`);
  }

  if (compare === '<=') {
    return date1.isSame(date2) || date1.isBefore(date2);
  }

  if (compare === '>=') {
    return date1.isSame(date2) || date1.isAfter(date2);
  }

  if (compare === '<') {
    return date1.isBefore(date2);
  }

  if (compare === '>') {
    return date1.isAfter(date2);
  }

  return date1.isSame(date2);
}
