import React from "react";

export type ReportRequest = {
  check_in_cycle?: number;
  evaluation_period?: number;
}

export type AccessTokenData = {
  access_token: string;
  expires_in: string;
  token_type: string;
};

export type User = {
  id: number;
  manager_id: number;
  client_id: number;
  name: string;
  email: string;
  roles?: UserRole[];
  notification_enabled?: boolean;
};

export type UserRole = 'manager' | 'employee' | 'admin' | 'super';

export type Goal = {
  id: number;
  user: User;
  title: string;
  description: string;
  progress_type: GoalProgress | null;
  progress_target: number;
  progress: number;
  status: GoalStatus;
  deadline: string;
  created_at: string;
  comments_count: number;
  review_window: CheckInCycle | EvaluationPeriod | false;
  reviews?: Review;
};

export type Comment = {
  id: number;
  comment: string;
  created_at: string;
  updated_at: string;
  commenter: User;
};

export type Client = {
  id: number;
  name: string;
  logo: string;
  gsuite_id: string;
  created_at: string;
  updated_at: string;
};

export type ClientStandard = {
  id: number;
  name: string;
  description: string;
  deactivated: boolean;
}

export type ClientStandardsReview = {
  id: number;
  user: User;
  author: User;
  period: EvaluationPeriod | CheckInCycle;
  completed_at?: string;
  created_at: string;
  updated_at: string;
}

export type ClientStandardsScore = {
  id: number;
  review_id: number;
  score: 1|2|3|null;
  created_at: string;
  updated_at: string;
  standard: ClientStandard;
}

export type Employee = User & {
  on_pip: boolean;
  goals_count: number;
  high_fives_count: number;
  employees_count: number;
  review_window: CheckInCycle | EvaluationPeriod | false;
  performance_review_window: CheckInCycle | EvaluationPeriod | false;
};

export type CompanyValue = {
  id: number | null;
  client_id: number;
  name: string;
};

export type HighFive = {
  id: number;
  user_id: number;
  author_id: number;
  client_company_value_id: number | null;
  comment: string;
  from_manager: boolean;
  created_at: string;
  updated_at: string;
  author: User;
};

export type Document = {
  id: number;
  filename: string;
  nicename: string;
  uri: string;
  expires_at: string;
  created_at: string;
  updated_at: string;
}

export enum GoalStatus {
  IN_PROGRESS = 'in_progress',
  NOT_APPROVED = 'not_approved',
  COMPLETED = 'completed',
  OBSOLETE = 'obsolete',
  NOT_COMPLETED = 'not_completed',
  PENDING = 'pending_approval',
}

export enum GoalProgress {
  NUMERIC = 'numeric',
  PERCENTAGE = 'percentage',
  CURRENCY = 'currency',
}

export enum PerformanceReviewStatus {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  NOT_COMPLETED = 'not_completed',
}

export enum EvaluationPeriodTypes {
  ANNUAL = 'Annual',
  BI_ANNUAL = 'Bi-annual',
}

export enum CheckInCycleTypes {
  QUARTERLY = 'Quarterly',
  MONTHLY = 'Monthly',
  BI_MONTHLY = 'Bi-monthly'
}

export type EvaluationPeriod = {
  id: number;
  client_id: number;
  active: boolean;
  label: string;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
};

export type CheckInCycle = EvaluationPeriod & {
  evaluation_period_id: number;
  review_window_start_date: string;
  review_window_end_date: string;
};

export type Review = {
  id: number;
  review: string;
  reviewable: User|Goal;
  reviewer: User;
  period: CheckInCycle|EvaluationPeriod;
  created_at: string;
  updated_at: string;
};

export type PIP = {
  id: string;
  user_id: string;
  author_id: string;
  type: 'added' | 'removed';
  created_at: string;
  updated_at: string;
  author: User;
  documents: Document[];
};

export type Task = {
  id: number;
  manager_id: number;
  employee_id: number;
  client_evaluation_period_id: number | null;
  client_check_in_cycle_id: number | null;
  status: TaskStatus;
  type: TaskType;
  created_at: string;
  updated_at: string;
  employee_name: string;
  goal_id: number;
  check_in_cycle_end_date: string;
  evaluation_period_end_date: string;
};

export enum TaskStatus {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
}

export enum TaskType {
  GOAL_SETTING = 'goal_setting',
  PERIODIC_REVIEW = 'periodic_review',
  GENERAL_EVALUATION = 'general_evaluation',
  MEETING_REQUEST = 'meeting_request',
  EMPLOYEE_DRAFT = 'employee_goal_draft',
  PERFORMANCE_REVIEW = 'performance_review',
  GOAL_REVIEW = 'goal_review',
}

export type Notification = {
  id: number;
  message: string;
  type: NotificationType;
};

export enum NotificationType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export type EmployeePerformance = {
  id: number;
  name: string;
  on_pip: boolean;
  goals_count: number;
  goals_completed: number;
  goals_in_progress: number;
  goals_not_approved: number;
  goals_not_completed: number;
  goals_pending: number;
  goals_obsolete: number;
  high_fives_count: number;
  reviews_count: number;
}

export type ManagerPerformance = {
  id: number;
  name: string;
  employees_count: number;
  cycle_check_ins_count: number;
  goal_evaluations_count: number;
  goals_count: number;
}

export type ApplicationUsage = {
  id: number;
  name: string;
  users_count: number;
  goals_count: number;
  reviews_count: number;
  high_fives_count: number;
}

export type EvaluationReportRating = {
  id: number
  name: string;
  value: string;
}

export type EvaluationReport = {
  id: number;
  name: string;
  completed_at: string;
  ratings: EvaluationReportRating[];
}

export interface FieldErrors {
  [key: string]: string[];
}

export interface FormErrors {
  message: string;
  errors: FieldErrors;
}

export interface Links {
  [key: string]: string;
}

export type Resource<T> = {
  data: T;
  links?: Links;
}

export type Tab = {
  title: string;
  body: React.ReactElement;
  count?: number;
}

export type Activity = {
  user: Employee,
  evaluation_period: EvaluationPeriod,
  goals: Goal[],
  reviews: Review[],
  high_fives: HighFive[],
}
