import ClientStore, { IClientStore as _IClientStore } from './clientStore';
import CommentStore, { ICommentStore as _ICommentStore } from './commentStore';
import DocumentStore, { IDocumentStore as _IDocumentStore } from './documentStore';
import EmployeeStore, { IEmployeeStore as _IEmployeeStore, } from './employeeStore';
import EvaluationPeriodStore, { IEvaluationPeriodStore as _IEvaluationPeriodStore } from './evaluationPeriodStore';
import GoalStore, { IGoalStore as _IGoalStore } from './goalStore';
import High5Store, { IHigh5Store as _IHigh5Store } from './high5Store';
import LoadingStore, { ILoadingStore as _ILoadingStore } from './loadingStore';
import NotificationStore, { INotificationStore as _INotificationStore, } from './notificationStore';
import ReviewStore, { IReviewStore as _IReviewStore } from './reviewStore';
import StandardStore, { IStandardStore as _IStandardStore } from './standardStore';
import TaskStore, { ITaskStore as _ITaskStore } from './taskStore';
import UserStore, { IUserStore as _IUserStore } from './userStore';

export class RootStore {
  clientStore: ClientStore;
  commentStore: CommentStore;
  documentStore: DocumentStore;
  employeeStore: EmployeeStore;
  evaluationPeriodStore: EvaluationPeriodStore;
  goalStore: GoalStore;
  high5Store: High5Store;
  loadingStore: LoadingStore;
  notificationStore: NotificationStore;
  reviewStore: ReviewStore;
  standardStore: StandardStore;
  taskStore: TaskStore;
  userStore: UserStore;

  constructor() {
    this.loadingStore = new LoadingStore();
    this.clientStore = new ClientStore(this);
    this.documentStore = new DocumentStore(this);
    this.goalStore = new GoalStore(this);
    this.commentStore = new CommentStore(this);
    this.employeeStore = new EmployeeStore(this);
    this.high5Store = new High5Store(this);
    this.reviewStore = new ReviewStore(this);
    this.standardStore = new StandardStore(this);
    this.taskStore = new TaskStore(this);
    this.evaluationPeriodStore = new EvaluationPeriodStore(this);
    this.userStore = new UserStore(this);
    this.notificationStore = new NotificationStore();
  }
}

export default new RootStore();

export type IClientStore = _IClientStore;
export type ICommentStore = _ICommentStore;
export type IDocumentStore = _IDocumentStore;
export type IEmployeeStore = _IEmployeeStore;
export type IEvaluationPeriodStore = _IEvaluationPeriodStore;
export type IGoalStore = _IGoalStore;
export type IHigh5Store = _IHigh5Store;
export type ILoadingStore = _ILoadingStore;
export type INotificationStore = _INotificationStore;
export type IReviewStore = _IReviewStore;
export type IStandardStore = _IStandardStore;
export type ITaskStore = _ITaskStore;
export type IUserStore = _IUserStore;

// @todo export a typed list of stores (as strings) to inject
