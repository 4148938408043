import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { IEmployeeStore, IGoalStore, IUserStore } from '../../stores';
import {
  Content,
  Wrapper,
  Heading,
  Spacer,
  Icon,
  IconEnum,
  Color,
} from '../../components';
import { useAdaptiveLayout } from '../../utils/hooks';
import { EmployeeCard } from '../dashboard/EmployeeCard';

import styles from './ManagerScreen.module.css';
import { Employee, User } from '../../types';

type RouteParams = {
  manager_id: string;
};

type RouteProps = RouteComponentProps<RouteParams>;

type Props = RouteProps & {
  employeeStore?: IEmployeeStore;
  goalStore?: IGoalStore;
  userStore?: IUserStore;
};

function ManagerScreen(props: Props) {
  const { manager_id } = props.match.params;
  const employeeStore = props.employeeStore!;
  const goalStore = props.goalStore!;
  const userStore = props.userStore!;
  const history = useHistory();

  const [searchString, setSearchString] = useState('');
  const { isDesktop } = useAdaptiveLayout();

  const [user, setUser] = useState<User>();

  useEffect(() => {
    employeeStore.fetchEmployeesByManagerId(Number(manager_id));
    employeeStore.resetEmployee();
    goalStore.reset();
    userStore.fetchUser(Number(manager_id))
      .then((resp) => { setUser(resp) })
  }, [
    manager_id,
    employeeStore,
    goalStore,
    userStore
  ]);

  const filteredEmployees = getfilteredEmployees(employeeStore.employees);

  return (
    <Wrapper>
      <Content
        title={`${user?.name} Team`}
        header={renderMainHeader()}
        body={renderMainBody()}
      />
    </Wrapper>
  );

  function renderMainHeader() {
    return isDesktop === false ? <Heading>{applyPossessive(user?.name || '')} Team</Heading> : undefined;
  }

  function renderMainBody() {
    return (
      <>
        <div className={styles.team_header}>
          <button
            className={styles.back_button}
            onClick={() => history.goBack()}
          >
            <Icon
              icon={IconEnum.ARROW}
              color={Color.PRIMARY}
            ></Icon>
          </button>
          {isDesktop === true && <Heading>{applyPossessive(user?.name || '')} Team</Heading>}
          {renderSearch()}
        </div>

        <Spacer vertical={32} />

        <ul className={styles.employee_list}>
          {filteredEmployees.map((employee) => {
            return (
              <li key={employee.id}>
                {/* <Link
                  to={`/employee/${employee.id}`}
                  className={styles.employee_link}> */}
                  <EmployeeCard employee={employee} />
                {/* </Link> */}
              </li>
            );
          })}
        </ul>
      </>
    );
  }

  function renderSearch() {
    return (
      <div className={styles.search_wrapper}>
        <input
          value={searchString}
          type="text"
          placeholder="Search employee"
          className={styles.search}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <span className={styles.search_icon}>
          <Icon icon={IconEnum.SEARCH} color={Color.PRIMARY} />
        </span>
      </div>
    );
  }

  function getfilteredEmployees(employees: Employee[]) {
    return employees.filter((employee) =>
      employee.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }

  function applyPossessive(str: string): string {
    if (!str.length)
      return '';

    let newStr = `${str}'`;

    if (str.slice(-1) !== 's')
      newStr += 's';

    return newStr
  }
}

export default inject(
  'employeeStore',
  'goalStore',
  'userStore'
)(observer(ManagerScreen));
