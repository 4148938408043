import React from 'react';

import { Space } from '../../';
import Flex, { Align } from '../Flex';

type Props = {
  children: React.ReactNode;
  gap?: Space;
  align?: Align;
};

export function Stack({ align = 'stretch', gap = 0, ...props }: Props) {
  return <Flex direction="column" align={align} gap={gap} {...props} />;
}
