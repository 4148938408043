import React, { useEffect, useState } from 'react';
import styles from './ReportScreen.module.css';
import { inject, observer } from 'mobx-react';
import { useAdaptiveLayout } from '../../utils/hooks';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getGoalsDetails } from './ReportScreen.utils';
import { GoalReviewsContainer } from './GoalReviewsContainer';
import { GoalCount } from './GoalCount';
import { api } from '../../services/http';
import { EMPTY_EVALUATION_PERIOD } from '../../stores/clientStore';
import { EMPTY_EMPLOYEE } from '../../stores/employeeStore';
import {
  IClientStore,
  IEmployeeStore,
  IHigh5Store,
  ILoadingStore,
} from '../../stores';
import {
  CheckInCycle,
  Employee,
  Goal,
  GoalStatus,
  HighFive,
  Review as ReviewType
} from '../../types';
import {
  Content,
  Wrapper,
  Card,
  Divider,
  Text,
  Color,
  Spacer,
  ProfileImage,
  Heading,
  Icon,
  IconEnum,
  Review,
  High5ListModal,
  GoalCard,
  Button,
  Inline,
} from '../../components';

const FETCH_REPORT = 'FETCH_REPORT';

type RouteParams = {
  employee_id: string;
};

type RouteProps = RouteComponentProps<RouteParams>;

type Props = RouteProps & {
  employeeStore: IEmployeeStore;
  high5Store: IHigh5Store;
  loadingStore: ILoadingStore;
  clientStore: IClientStore;
};

function ReportScreen(props: Props) {
  const {
    employeeStore,
    high5Store,
    loadingStore,
    clientStore,
  } = props;

  const { isDesktop } = useAdaptiveLayout();

  const employeeId = parseInt(props.match.params.employee_id);

  const [isLoadingData, setIsLoadingData] = useState(true);

  const [employee, setEmployee] = useState(EMPTY_EMPLOYEE);

  const [evaluationPeriod, setEvaluationPeriod] = useState(EMPTY_EVALUATION_PERIOD);

  const [goals, setGoals] = useState<Goal[]>([]);

  const [highFives, setHighFives] = useState<HighFive[]>([]);

  const [reviews, setReviews] = useState<ReviewType[]>([]);

  useEffect(() => {
    async function fetchActivity() {
      setIsLoadingData(true);

      await high5Store.fetchCompanyValues();

      const activity = await employeeStore.fetchActivity(employeeId);

      if (!activity) return;

      const {
        evaluation_period,
        goals,
        high_fives,
        reviews,
        user,
      } = activity;

      setEmployee(user);

      setEvaluationPeriod(evaluation_period);

      setGoals(getGoalsNotPending(goals));

      setHighFives(high_fives);

      setReviews(reviews);

      setIsLoadingData(false);
    }
    fetchActivity();
  }, [employeeStore, employeeId, high5Store, clientStore.checkInCycle.id]);

  const employeeCheckInReviews = reviews.filter(({ period }) => !!(period as CheckInCycle).evaluation_period_id);

  const employeeFinalReviews = reviews.filter(({ period }) => !(period as CheckInCycle).evaluation_period_id);

  const companyValues = high5Store.companyValues || [];

  const goalsDetails = getGoalsDetails(goals);

  return (
    <Wrapper>
      <Content
        title="Activity Report"
        //TODO extract app loader into a reusable component
        body={isLoadingData ? <span>Loading data...</span> : renderBody()}
      />
    </Wrapper>
  );

  function renderBody() {
    return (
      <Card>
        <div className={styles.container}>
          <div className={styles.report_period}>
            <Text color={Color.GREY}>
              Activity report for:
              <Spacer horizontal={8} />
              <Text inline bold color={Color.PRIMARY}>
                {evaluationPeriod.label}
              </Text>
            </Text>
          </div>
          <div className={styles.header}>
            <div className={styles.employee_info}>
              <Link to={`/employee/${employeeId}`}>
                <div className={styles.employee}>
                  <ProfileImage name={employee?.name || ''} size={'large'} />

                  <Spacer horizontal={isDesktop ? 16 : 8} />

                  <div>
                    <Heading level={isDesktop ? 2 : 3} color="inherit">
                      {employee?.name}
                    </Heading>
                  </div>
                </div>
              </Link>
              <Button onClick={downloadPDF}>Save PDF</Button>
            </div>
            <Spacer vertical={32} />
            {/* For some reason <Inline /> treats goalsDetails map as one element */}
            <Inline gap={isDesktop ? 24 : 16}>
              <Inline gap={isDesktop ? 24 : 16}>
                {goalsDetails.map((item) => (
                  <GoalCount
                    key={item.status}
                    status={item.status}
                    count={item.count}
                    color={item.color}
                    total={item.total}
                  />
                ))}
              </Inline>
              <div
                style={{
                  opacity: employee?.high_fives_count! > 0 ? 1 : 0.6,
                  display: 'flex',
                  alignItems: 'flex-end',
                }}>
                <Icon icon={IconEnum.HIGH5} size={24} />
                <Spacer horizontal={8} />
                <Text size={16} bold inline>
                  {employee?.high_fives_count}
                </Text>
              </div>
            </Inline>
          </div>
          <Divider />
          {renderPerformanceEvaluation()}
          {renderGoals()}
          <Divider />
          {employee ? renderHighFives(employee) : <></>}
        </div>
      </Card>
    );
  }

  function renderPerformanceEvaluation() {
    return (
      <div className={styles.performance_evaluation_container}>
        <Heading level={2}>Overall Performance Evaluation</Heading>
        <Spacer vertical={4} />
        <Text color={Color.GREY}>Periodical check-in reviews</Text>
        {employeeFinalReviews.length !== 0 && employeeCheckInReviews.length !== 0 && (
          <>
            <Spacer vertical={isDesktop ? 32 : 24} />
            <Divider />
          </>
        )}

        {employeeFinalReviews.map((review) => (
          <div key={review.id}>
            <Spacer vertical={isDesktop ? 32 : 24} />
            <Review
              review={review}
              evaluationPeriod={evaluationPeriod.label}
              readOnly
            />
          </div>
        ))}

        {employeeCheckInReviews.map((review) => (
          <div
            key={review.id}
            className={employeeFinalReviews && styles.indented}>
            <Spacer vertical={isDesktop ? 32 : 24} />
            <Review
              review={review}
              evaluationPeriod={evaluationPeriod.label}
              readOnly
            />
          </div>
        ))}
      </div>
    );
  }

  function renderGoals() {
    return (
      <>
        {goals.map((goal) => {
          return (
            <React.Fragment key={goal.id}>
              <Divider />
              <div className={styles.goal}>
                <GoalCard goal={goal} comments={goal.comments_count} spacing={0} />

                <GoalReviewsContainer goal={goal} />
              </div>
            </React.Fragment>
          );
        })}
      </>
    );
  }

  function renderHighFives(employee: Employee) {
    if (companyValues.length === 0) return '';
    return (
      <div className={styles.high_fives_container}>
        <High5ListModal
          employee={employee}
          companyValues={companyValues}
          highFives={highFives}
          expandable={false}
        />
      </div>
    );
  }

  /**
   * Creates a link to download the pdf report
   *
   * @returns void
   */
  async function downloadPDF() {
    loadingStore.start(FETCH_REPORT);

    try {
      const url = await fetchPDF();
      const link = document.createElement('a');
      link.href = url;
      link.download="activity_report.pdf";
      link.click();
    } catch (error) {
      console.warn(error);
    } finally {
      loadingStore.end(FETCH_REPORT);
    }
  }

  /**
   * Fetches PDF data and returns a blob url
   *
   * @returns string
   */
  async function fetchPDF() {
    const check_in_cycle = clientStore.checkInCycle.id;
    const { data } = await api.get(
      `employees/${employeeId}/reports/activity`,
      {
        responseType: 'blob',
        params: { check_in_cycle }
      }
    );
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    return url
  }
}

function getGoalsNotPending(goals: Goal[]) {
  return goals.filter(({ status }) => status !== GoalStatus.PENDING);
}

export default inject(
  'employeeStore',
  'high5Store',
  'loadingStore',
  'clientStore',
)(observer(ReportScreen));
