import { api } from './http';
import {
  ClientStandard,
  ClientStandardsReview,
  ClientStandardsScore,
  Comment,
  Resource,
} from "../types";

export type ScoresCollectionResponse = {
  data: ClientStandardsScore[];
}

export type StandardsCollectionResponse = {
  data: ClientStandard[];
};

export type ClientStandardPayload = {
  name: string;
  description: string;
}

export type ClientStandardsScorePayload = {
  score: number
}

export type ClientStandardsScoreComment = {
  comment: string;
}

export async function activateStandard(standardId: number): Promise<Resource<ClientStandard>> {
  const { data } = await api.post(`client-standards/${standardId}/activate`);
  return data;
}

export async function createReviewComment(reviewId: number, payload: ClientStandardsScoreComment): Promise<Resource<Comment>> {
  const { data } = await api.post(`standards-reviews/${reviewId}/comments`, payload);
  return data;
}

export async function createScoreComment(scoreId: number, payload: ClientStandardsScoreComment): Promise<Resource<Comment>> {
  const { data } = await api.post(`standards-scores/${scoreId}/comments`, payload);
  return data;
}

export async function createReview(employeeId: number, check_in_cycle: number): Promise<Resource<ClientStandardsReview>> {
  const { data } = await api.post(`employees/${employeeId}/standards-reviews`, {
    check_in_cycle,
  });
  return data;
}

export async function createStandard(clientId: number, payload: ClientStandardPayload): Promise<Resource<ClientStandard>> {
  const { data } = await api.post(`clients/${clientId}/standards`, payload);
  return data;
}

export async function deactivateStandard(standardId: number): Promise<Resource<ClientStandard>> {
  const { data } = await api.post(`client-standards/${standardId}/deactivate`);
  return data;
}

export async function fetchReviewComments(reviewId: number): Promise<Resource<Comment[]>> {
  const { data } = await api.get(`standards-reviews/${reviewId}/comments`);
  return data;
}

export async function fetchReviews(userId: number, check_in_cycle: number): Promise<ClientStandardsReview[]> {
  const { data } = await api.get(`employees/${userId}/standards-reviews`, {
    params: { check_in_cycle },
  });
  return data.data;
}

export async function fetchScoreComments(scoreId: number): Promise<Resource<Comment[]>> {
  const { data } = await api.get(`standards-scores/${scoreId}/comments`);
  return data;
}

export async function fetchScores(reviewId: number): Promise<ScoresCollectionResponse> {
  const { data } = await api.get(`standards-reviews/${reviewId}/scores`);
  return data;
}

export async function fetchStandards(clientId: number): Promise<StandardsCollectionResponse> {
  const { data } = await api.get(`clients/${clientId}/standards`)
  return data;
}

export async function fetchStandard(standardId: number): Promise<Resource<ClientStandard>> {
  const { data } = await api.get(`client-standards/${standardId}`)
  return data;
}

export async function markReviewComplete(reviewId: number): Promise<ClientStandardsReview> {
  const { data } = await api.post(`standards-reviews/${reviewId}/complete`);
  return data.data;
}

export async function updateScore(scoreId: number, payload: ClientStandardsScorePayload): Promise<ClientStandardsScore> {
  const { data } = await api.put(`standards-scores/${scoreId}`, payload);
  return data.data;
}

export async function updateStandard(standardId: number, payload: ClientStandardPayload): Promise<ClientStandard> {
  const { data: response } = await api.put(`client-standards/${standardId}`, payload);
  return response.data;
}
