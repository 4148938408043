import React from 'react';
import styles from './SettingsScreen.module.css';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import {
  Card,
  Content,
  Divider,
  Heading,
  Spacer,
  Text,
  Wrapper,
} from '../../components';

function SettingsScreen() {
  return (
    <Wrapper>
      <Content
        title="Application Settings"
        header={<Heading level={1}>Application Settings</Heading>}
        body={renderBody()}
      />
    </Wrapper>
  );

  function renderBody() {
    return (
      <Card className={styles.profile_settings_card}>
        <Link to="/evaluationPeriods">
          <Text size={18} bold>Evaluation Periods</Text>
          <Spacer vertical={4} />
          <Text size={14}>Manage your organization's evaluation periods and check-in cycles</Text>
        </Link>

        <Divider gap={32}></Divider>

        <Link to="/users">
          <Text size={18} bold>Users</Text>
          <Spacer vertical={4} />
          <Text size={14}>Manage your organization's users</Text>
        </Link>

        <Divider gap={32}></Divider>

        <Link to="/standards">
          <Text size={18} bold>Standards</Text>
          <Spacer vertical={4} />
          <Text size={14}>Manage your organization's standards</Text>
        </Link>

        <Divider gap={32}></Divider>

        <Link to="/company-values">
          <Text size={18} bold>Company Values</Text>
          <Spacer vertical={4} />
          <Text size={14}>Manage your organization's values</Text>
        </Link>
      </Card>
    );
  }
}

export default inject('userStore')(observer(SettingsScreen));
