import React from 'react';
import classNames from 'classnames/bind';

import { Spacer } from '../..';
import { useAdaptiveLayout } from '../../../utils/hooks';

import styles from './TabButton.module.css';

const cx = classNames.bind(styles);

interface Props {
  text: string;
  count?: number;
  active?: boolean;
  onClick: () => void;
}

export function TabButton(props: Props) {
  const { text, count, active, onClick } = props;

  const { isDesktop } = useAdaptiveLayout();

  const tabStyles = cx({
    tab: true,
    tab_active: active,
    tab_desktop: isDesktop,
  });

  return (
    <button className={tabStyles} onClick={onClick}>
      {text}
      {count && (
        <>
          <Spacer horizontal={8} />
          <span className={styles.count}>{count}</span>
        </>
      )}
    </button>
  );
}
