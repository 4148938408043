import React from 'react';
import styles from './PipBadge.module.css';
import classNames from 'classnames/bind';

import { Text, Color } from '../..';

const cx = classNames.bind(styles);

interface Props {
  size?: 'small';
}

export function PipBadge(props: Props) {
  const { size } = props;

  const styles = cx({
    pip: true,
    small: size === 'small',
  });

  return (
    <div className={styles}>
      <Text
        size={size === 'small' ? 10 : undefined}
        bold
        color={Color.WHITE}
        lineHeight={1.2}>
        PIP
      </Text>
    </div>
  );
}
