import React from 'react';
import classNames from 'classnames/bind';

import { Color } from '../../styles';
import styles from './Text.module.css';

const cx = classNames.bind(styles);

interface Props {
  inline?: boolean;
  bold?: boolean;
  color?: Color;
  align?: 'start' | 'center' | 'end';
  size?: 10 | 11 | 14 | 16 | 18 | 20 | 22;
  lineHeight?: number;
  wrap?: boolean;
  children: React.ReactNode;
}

export function Text({
  children,
  inline,
  color,
  bold,
  size,
  align,
  lineHeight,
  wrap,
  ...props
}: Props) {
  const element = inline ? 'span' : 'p';
  const className = cx({
    paragraph: !inline,
    bold,
    noWrap: !wrap,
  });

  const style: React.CSSProperties = {
    color,
    fontSize: size,
    lineHeight,
  };
  bold && (style.fontWeight = 'bold');
  align !== 'start' && (style.textAlign = align);

  return React.createElement(element, { ...props, className, style }, [
    children,
  ]);
}

Text.defaultProps = {
  inline: false,
  bold: false,
  wrap: true,
  align: 'start',
};
