import React, { useState } from 'react';
import styles from './ProfileScreen.module.css';
import { inject, observer } from 'mobx-react';
import { Wrapper, Content } from '../../components';
import { IUserStore } from '../../stores';
import { useAdaptiveLayout } from '../../utils/hooks';

import {
  Button,
  Card,
  Checkbox,
  Divider,
  Heading,
  Input,
  Spacer,
  StrengthMeter,
  Text,
} from '../../components';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';

type Props = {
  userStore: IUserStore;
};

function ProfileScreen(props: Props) {
  const { userStore } = props;
  const { user } = userStore;

  const [current_password, setCurrentPassword] = useState('');
  const [new_password, setNewPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [password_disabled, setPasswordDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [notificationEnabled, toggleNotificationEnabled] = useState(
    !!user.notification_enabled,
  );

  const { isDesktop } = useAdaptiveLayout();

  const {
    strength,
    validLength,
    match,
    valid,
  } = usePasswordValidation({
    password: new_password,
    confirmPassword: confirm_password,
  })

  return (
    <Wrapper>
      <Content
        title="Profile Settings"
        header={<Heading level={1}>Profile Settings</Heading>}
        body={renderBody()}
      />
    </Wrapper>
  );

  function renderBody() {
    return (
      <>
        <Card className={styles.profile_settings_card}>
          <Heading level={2}>Change Password</Heading>

          <Spacer vertical={16} />

          <Input
            onChange={updateCurrentPassword}
            value={current_password}
            placeholder={'Enter current password'}
            type={'password'}
          />

          <Spacer vertical={16} />

          <Input
            onChange={updateNewPassword}
            value={new_password}
            placeholder={'Enter new password'}
            type={'password'}
            error={ new_password.length > 0 && !validLength ? 'Too few characters' : undefined}
          />

          <StrengthMeter
            value={strength}
            max={4}
            showLabel={!!new_password}
          />

          <Spacer vertical={16} />

          <Input
            onChange={updateConfirmPassword}
            value={confirm_password}
            placeholder={'Confirm password'}
            type={'password'}
            error={confirm_password.length > 0 && !match ? 'Passwords must match!' : undefined}
          />

          <Spacer vertical={16} />

          <Button
            onClick={savePassword}
            disabled={!current_password || !valid || password_disabled}>
            Change
          </Button>

          {errorMessage.length !== 0 && (
            <div className={styles.centered}>
              <h4>{errorMessage}</h4>
            </div>
          )}
        </Card>

        <Card className={styles.profile_settings_card}>
          <Heading level={2}>Notifications</Heading>

          <Spacer vertical={16} />

          <label className={styles.label}>
            <Checkbox checked={notificationEnabled} onChange={toggleCheckbox} />
            <Spacer horizontal={16} />
            <Text size={16} bold>
              Enable email notifications
            </Text>
          </label>

          <Divider gap={isDesktop ? 32 : 24} />

          <Button
            onClick={saveSettings}
            disabled={notificationEnabled === user.notification_enabled}>
            Save settings
          </Button>
        </Card>
      </>
    );
  }

  function updateCurrentPassword(event: React.ChangeEvent<HTMLInputElement>) {
    setCurrentPassword(event.target.value);
  }

  function updateNewPassword(event: React.ChangeEvent<HTMLInputElement>) {
    setNewPassword(event.target.value);
  }

  function updateConfirmPassword(event: React.ChangeEvent<HTMLInputElement>) {
    setConfirmPassword(event.target.value);
  }

  function toggleCheckbox() {
    toggleNotificationEnabled(!notificationEnabled);
  }

  function resetPasswordForm() {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  }

  async function savePassword() {
    const payload = {
      current_password,
      new_password,
    }

    try {
      setPasswordDisabled(true);
      await userStore.changePassword(payload);
      resetPasswordForm();
    } catch(error) {
      setErrorMessage(error.message);
    } finally {
      setPasswordDisabled(false);
    }
  }

  async function saveSettings() {
    await userStore.toggleEmailNotifications(user.id, notificationEnabled);
  }
}

export default inject('userStore')(observer(ProfileScreen));
