import React, { useEffect, useMemo, useState } from 'react';
import styles from './ManagerPerformanceReportScreen.module.css';
import { inject, observer } from 'mobx-react';

import {
  Button,
  Card,
  Color,
  Content,
  Heading,
  Icon,
  IconEnum,
  Spacer,
  Table,
  Wrapper,
} from '../../components';
import { IClientStore, IUserStore } from '../../stores';
import { ManagerPerformance, ReportRequest } from '../../types';
import { Column } from 'react-table';
import { formatPercent } from '../../utils/functions';

interface Props
{
  clientStore?: IClientStore;
  userStore?: IUserStore;
}

function ManagerPerformanceReportScreen(props: Props) {
  const clientStore = props.clientStore!;
  const userStore = props.userStore!;

  const [managers, setManagers] = useState<ManagerPerformance[]>([]);
  const columns: Column<ManagerPerformance>[] = [{
      Header: 'Manager Name',
      accessor: 'name',
      Footer: 'Totals',
    }, {
      Header: 'Cycle Check-In Evaluations',
      accessor: (row: ManagerPerformance) => {
        return <>{row.cycle_check_ins_count}/{row.employees_count}</>;
      },
      Footer: info => {
        const checkInsTotal = useMemo(
          () => info.rows.reduce(
            (sum, row) => row.original.cycle_check_ins_count + sum,
            0
          ),
          [info.rows]
        );

        const employeesTotal = useMemo(
          () => info.rows.reduce(
            (sum, row) => row.original.employees_count + sum,
            0
          ),
          [info.rows]
        );

        return <>{checkInsTotal}/{employeesTotal}</>;
      },
    }, {
      Header: 'Cycle Percent Completed',
      accessor: (row: ManagerPerformance) => {
        return !row.employees_count
          ? <>0%</>
          : <>{formatPercent(row.cycle_check_ins_count / row.employees_count)}</>;
      },
      Footer: info => {
        const checkInsTotal = useMemo(
          () => info.rows.reduce(
            (sum, row) => row.original.cycle_check_ins_count + sum,
            0
          ),
          [info.rows]
        );

        const employeesTotal = useMemo(
          () => info.rows.reduce(
            (sum, row) => row.original.employees_count + sum,
            0
          ),
          [info.rows]
        );

        return !employeesTotal
          ? <>0%</>
          : <>{formatPercent(checkInsTotal / employeesTotal)}</>;
      },
    }, {
      Header: 'Goal Evaluations',
      accessor: (row: ManagerPerformance) => {
        return <>{row.goal_evaluations_count}/{row.goals_count}</>;
      },
      Footer: info => {
        const evaluationsTotal = useMemo(
          () => info.rows.reduce(
            (sum, row) => row.original.goal_evaluations_count + sum,
            0
          ),
          [info.rows]
        );

        const goalsTotal = useMemo(
          () => info.rows.reduce(
            (sum, row) => row.original.goals_count + sum,
            0
          ),
          [info.rows]
        );

        return <>{evaluationsTotal}/{goalsTotal}</>
      },
    }, {
      Header: 'Goal Percent Complete',
      accessor: (row: ManagerPerformance) => {
        if (row.goals_count === 0)
          return '0%';

        return !row.goals_count
          ? <>0%</>
          : <>{formatPercent(row.goal_evaluations_count / row.goals_count)}</>;
      },
      Footer: info => {
        const evaluationsTotal = useMemo(
          () => info.rows.reduce(
            (sum, row) => row.original.goal_evaluations_count + sum,
            0
          ),
          [info.rows]
        );

        const goalsTotal = useMemo(
          () => info.rows.reduce(
            (sum, row) => row.original.goals_count + sum,
            0
          ),
          [info.rows]
        );

        return !goalsTotal
          ? <>0%</>
          : <>{formatPercent(evaluationsTotal / goalsTotal)}</>;
      },
  }];

  useEffect(() => {
    const request: ReportRequest = {
      check_in_cycle: clientStore.checkInCycle.id,
      evaluation_period: clientStore.evaluationPeriod.id,
    }

      userStore.getManagerPerformance(request)
        .then(response => setManagers(response));
  }, [
    clientStore.checkInCycle.id,
    clientStore.evaluationPeriod.id,
    userStore,
  ]);

  return (
    <Wrapper>
      <Content
        title="Manager Performance Report"
        header={
          <>
            <a href="/reports">
              <Icon
                icon={IconEnum.CHEVRON_LEFT}
                size={8}
                color={Color.GREY}
              />
              <Spacer horizontal={4} />
              Back
            </a>
            <Spacer vertical={8} />
            <Heading level={1}>Manager Performance Report</Heading>
          </>
        }
        body={renderBody()}
      />
    </Wrapper>
  );

  function renderBody() {
    return (
      <Card className={styles.reports_card}>
        <div className={styles.actions}>
          <Button onClick={() => savePdf()}>Save PDF</Button>
        </div>

        {!managers.length
          ? (<div>Loading…</div>)
          :
            (<Table
              data={managers}
              columns={columns}
              footer={true}
              className={styles.table}
            />)
        }
      </Card>
    );
  }

  async function savePdf()
  {
    const request: ReportRequest = {
      check_in_cycle: clientStore.checkInCycle.id,
      evaluation_period: clientStore.evaluationPeriod.id,
    }

    await userStore.downloadManagerPerformance(request);
  }
}

export default inject('clientStore', 'userStore')(observer(ManagerPerformanceReportScreen));
