import React, { useState } from 'react';
import styles from './RequestMeetingModal.module.css';
import {
  Button,
  Textarea,
  ProfileImage,
  Text,
  Spacer,
  Color,
  Modal,
} from '../../';

interface Props {
  managerName: string;
  isVisible: boolean;
  onClose: () => void;
  onSend: () => void;
}

export function RequestMeetingModal(props: Props) {
  const { managerName, isVisible, onClose, onSend } = props;
  const [message, setMessage] = useState('');
  return (
    <Modal isOpen={isVisible} onClose={onClose}>
      <div className={styles.container}>
        <Text size={14} bold color={Color.GREY}>
          Request a Meeting with your Manager
        </Text>
        <Spacer vertical={24} />
        <div className={styles.manager_profile}>
          <span className={styles.profile_image_wrapper}>
            <ProfileImage name={managerName} size="small" />
          </span>
          <Text bold>{managerName}</Text>
        </div>
        <Spacer vertical={24} />
        <Textarea
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          placeholder={`Hi ${managerName}! I would like to meet with you to review my goals and progress.`}
        />
        <Spacer vertical={32} />
        <Button role="primary" onClick={onSend}>
          Request Meeting
        </Button>
      </div>
    </Modal>
  );
}
