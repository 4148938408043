import { EMPTY_USER } from '../../../stores/userStore';
import { Goal, GoalProgress, GoalStatus } from '../../../types';
import { compareDates } from '../../../utils/functions';

export const DUMMY_GOAL: Goal = {
  id: 1,
  user: EMPTY_USER,
  title: 'Goal title lorem ipsum',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  progress_type: null,
  progress_target: 0,
  progress: 0,
  status: GoalStatus.IN_PROGRESS,
  deadline: '2020-09-14 10:24:26',
  created_at: '2020-09-14 10:24:26',
  comments_count: 0,
  review_window: false,
};

export const DUMMY_GOAL_WITH_PROGRESS: Goal = {
  ...DUMMY_GOAL,
  progress_type: GoalProgress.PERCENTAGE,
  progress_target: 80,
  progress: 30,
};

export const STATUS_STYLING = new Map<GoalStatus, GoalCardStatusStyling>([
  [GoalStatus.COMPLETED, { label: 'Completed', color: '#5fbf81' }],
  [GoalStatus.IN_PROGRESS, { label: 'In Progress', color: '#C3EAD8' }],
  [GoalStatus.NOT_APPROVED, { label: 'Not Approved', color: '#d21b11' }],
  [GoalStatus.NOT_COMPLETED, { label: 'Not completed', color: '#e07930' }],
  [GoalStatus.OBSOLETE, { label: 'Obsolete', color: '#797979' }],
  [GoalStatus.PENDING, { label: 'Pending Approval', color: '#833b37' }],
]);

export const AVAILABLE_STATUS_TYPES = (goal: Goal) => {
  return Object.values(GoalStatus).filter((s) => {
    if (compareDates(goal.deadline, Date.now(), '<')) {
      return ![
        GoalStatus.IN_PROGRESS,
        GoalStatus.NOT_APPROVED,
        GoalStatus.PENDING,
      ].includes(s);
    }

    return s !== GoalStatus.PENDING
  });
}

export function formatDate(date: Date) {
  return new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  }).format(date);
}

export function formatDateShort(date: Date) {
  return new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  }).format(date);
}

type GoalCardStatusStyling = {
  label: string;
  color: string;
};
