import React from 'react';
import styles from './File.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: string;
  accept?: string;
}

export function File(props: Props) {
  const {
    onChange,
    onFocus,
    placeholder,
    error,
    accept,
    disabled = false,
    required = false,
  } = props;

  const inputStyles = cx({
    input: true,
  });

  return (
    <div className={styles.container}>
      <input
        className={inputStyles}
        onChange={onChange}
        onFocus={onFocus}
        type="file"
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        accept={accept}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
}

File.defaultProps = {
  disabled: false,
  required: false,
};
