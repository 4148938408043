import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './Modal.module.css';
import { useAdaptiveLayout } from '../../../utils/hooks';

import { Icon, IconEnum } from '../../';

const cx = classNames.bind(styles);

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
}

export function Modal(props: Props) {
  const { children, isOpen, onClose } = props;
  const { isDesktop } = useAdaptiveLayout();

  useEffect(() => {
    function onEscKeyPress(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        onClose();
      }
    }

    document.addEventListener('keydown', onEscKeyPress);
    return () => {
      document.removeEventListener('keydown', onEscKeyPress);
    };
  }, [onClose]);

  if (!isOpen) {
    document.body.style.removeProperty('overflow');
    return null;
  }

  document.body.style.overflow = 'hidden';

  const modalStyles = cx({
    modal: true,
    modal_fullscreen: !isDesktop,
  });

  return (
    <div onClick={closeModal} className={styles.overlay}>
      <div className={modalStyles}>
        <button onClick={onClose} className={styles.closeBtn}>
          <Icon icon={IconEnum.CLOSE} size={16} color="inherit" />
        </button>
        <div className={styles.children}>{children}</div>
      </div>
    </div>
  );

  function closeModal(e: React.MouseEvent) {
    if (e.target !== e.currentTarget) return;
    onClose();
  }
}

Modal.defaultProps = {
  isOpen: false,
};
