import React, { useMemo } from 'react';
import styles from './EvaluationPeriodsScreen.module.css';
import { inject, observer } from 'mobx-react';
import { Wrapper, Content } from '../../components';
import { IEvaluationPeriodStore } from '../../stores';

import {
  Button,
  Card,
  Heading,
  Text,
} from '../../components';
import { EvaluationPeriod } from '../../types';
import EvaluationPeriodTable from '../../components/custom/EvaluationPeriodTable/EvaluationPeriodTable';

interface Props {
  evaluationPeriodStore: IEvaluationPeriodStore;
}

function SettingsScreen(props: Props) {
  const evaluationPeriodStore = props.evaluationPeriodStore!;

  const data: Array<EvaluationPeriod> = useMemo(() => {
    return evaluationPeriodStore.evaluationPeriods;
  }, [evaluationPeriodStore.evaluationPeriods]);

  return (
    <Wrapper>
      <Content
        title="Evaluation Periods"
        header={<Heading level={1}>Evaluation Periods</Heading>}
        body={renderBody()}
      />
    </Wrapper>
  );

  function renderBody() {
    return (
      <Card className={styles.evaluation_periods}>
        <Text>Create or modify upcoming evaluation periods for your organization</Text>
        <Button onClick={createEvaluationPeriod}>New Evaluation Period</Button>
        <EvaluationPeriodTable
          data={data}
        ></EvaluationPeriodTable>
      </Card>
    );
  }

  function createEvaluationPeriod() {
    evaluationPeriodStore.showModal()
  }
}

export default inject('evaluationPeriodStore')(observer(SettingsScreen));
