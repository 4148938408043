import React, { useEffect, useState } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import './Range.css';
import { GoalProgress as GOAL_PROGRESS } from '../../../types';
import styles from './GoalProgress.module.css';
import { Icon, IconEnum, Text } from '../../base';
import { Spacer } from '../../layout';
import { Color } from '../../styles';

const ProgressLabels: { [key in GOAL_PROGRESS]: string } = {
  [GOAL_PROGRESS.NUMERIC]: '#',
  [GOAL_PROGRESS.CURRENCY]: '$',
  [GOAL_PROGRESS.PERCENTAGE]: '%',
};

type Props = {
  target: number;
  type?: GOAL_PROGRESS;
  value: number;
  /** will be called only with the final value */
  onChange?(value: number): void;
  disabled?: boolean;
  width?: number;
};

export function GoalProgress(props: Props) {
  const {
    target,
    type = GOAL_PROGRESS.NUMERIC,
    value: initialValue = 0,
    onChange,
    disabled = false,
    width,
  } = props;

  // this updates on move, while dragging
  const [_value, setValue] = useState(initialValue);

  const progress_styles: React.CSSProperties = {};
  width ? (progress_styles.width = width) : (progress_styles.flex = 1);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue, setValue]);

  return (
    <div className={styles.container}>
      <Icon icon={IconEnum.PROGRESS} />
      <Spacer horizontal={16} />
      <div style={progress_styles}>
        <InputRange
          maxValue={target}
          value={_value}
          onChange={(v) => onChange && setValue(+v)}
          onChangeComplete={(v) => onChange && onChange(+v)}
          disabled={disabled || !onChange}
        />
      </div>
      <Spacer horizontal={16} />
      <Text bold wrap={false} color={Color.GREY}>
        {_value}/{target} {ProgressLabels[type]}
      </Text>
    </div>
  );
}

GoalProgress.defaultProps = {
  type: GOAL_PROGRESS.NUMERIC,
};

type SummaryProps = Pick<Props, 'type' | 'value'>;

function Summary(props: SummaryProps) {
  const { value, type = GOAL_PROGRESS.NUMERIC } = props;

  return (
    <div className={styles.container}>
      <Icon icon={IconEnum.PROGRESS} />
      <Spacer horizontal={8} />
      <Text bold wrap={false} color={Color.GREY}>
        {value} {ProgressLabels[type]}
      </Text>
    </div>
  );
}

GoalProgress.Summary = Summary;
