import { Goal, GoalStatus } from '../../types/index';

const statuses = new Map<GoalStatus, string>();

statuses.set(GoalStatus.COMPLETED, 'Completed');
statuses.set(GoalStatus.OBSOLETE, 'Obsolete');
statuses.set(GoalStatus.NOT_COMPLETED, 'Not Completed');

function getGoalsCountByStatus(goals: Goal[]): Map<GoalStatus, number> {
  const stats = new Map<GoalStatus, number>();
  stats.set(GoalStatus.IN_PROGRESS, 0);
  stats.set(GoalStatus.NOT_APPROVED, 0);
  stats.set(GoalStatus.COMPLETED, 0);
  stats.set(GoalStatus.OBSOLETE, 0);
  stats.set(GoalStatus.NOT_COMPLETED, 0);

  goals.forEach(({ status }: Goal) => {
    let count = stats.get(status);
    count!++;
    stats.set(status, count!);
  });
  return stats;
}

export function getGoalsDetails(goals: Goal[]): GoalDetail[] {
  const goalsCountByStatus = getGoalsCountByStatus(goals);
  return [
    {
      status: statuses.get(GoalStatus.COMPLETED)!,
      count: goalsCountByStatus.get(GoalStatus.COMPLETED)!,
      total:
        goalsCountByStatus.get(GoalStatus.COMPLETED)! +
        goalsCountByStatus.get(GoalStatus.IN_PROGRESS)!,
      color: '#28C37B',
    },
    {
      status: statuses.get(GoalStatus.OBSOLETE)!,
      count: goalsCountByStatus.get(GoalStatus.OBSOLETE)!,
      color: '#797979',
    },
    {
      status: statuses.get(GoalStatus.NOT_COMPLETED)!,
      count: goalsCountByStatus.get(GoalStatus.NOT_COMPLETED)!,
      color: '#EF7309',
    },
  ];
}

export type GoalDetail = {
  status: string;
  count: number;
  color: string;
  total?: number;
};
