import React, { useState, useEffect, useRef } from 'react';
import styles from './High5Modal.module.css';

import {
  Text,
  Color,
  Icon,
  IconEnum,
  Textarea,
  Spacer,
  ProfileImage,
  Modal,
} from '../../';

import { CompanyValue, User } from '../../../types/index';
import { High5Button } from '../High5Button/High5Button';

interface Props {
  selected?: User;
  users?: User[];
  companyValues: CompanyValue[];
  isVisible: boolean;
  onClose: () => void;
  onSend: (selected: {
    comment: string;
    company_value_id: number | null;
    user_id: number;
  }) => void;
}

const NO_COMPANY_VALUE = 0;
const OTHER_COMPANY_VALUE = null;

export function High5Modal(props: Props) {
  const {
    selected: preselected,
    users: employees,
    companyValues,
    onClose,
    onSend,
    isVisible,
  } = props;

  const [comment, setComment] = useState('');
  const [search, setSearch] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<number | null>(
    NO_COMPANY_VALUE,
  );
  const [selectedEmployee, setSelectedEmployee] = useState<User | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [isVisible]);

  useEffect(() => {
    setSelectedEmployee(preselected || null);
  }, [preselected]);

  return (
    <Modal isOpen={isVisible} onClose={closeModal}>
      <div className={styles.container}>
        <Text bold color={Color.GREY}>
          Give Applause to
        </Text>

        <Spacer vertical={24} />
        {selectedEmployee
          ? renderEmployee(selectedEmployee.name)
          : renderSearchInput()}

        <Spacer vertical={16} />

        {renderDropdown()}

        {selectedValue !== OTHER_COMPANY_VALUE ? (
          <Spacer vertical={32} />
        ) : (
          <>
            <Spacer vertical={24} />
            <Text bold>Please specify the reason</Text>
            <Spacer vertical={16} />
          </>
        )}

        <Textarea
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          placeholder="Please provide a brief description of why you are giving an applause."
        />

        <Spacer vertical={32} />

        <High5Button
          disabled={isSendDisabled()}
          onClick={sendHigh5}
          text="Send Applause"
        />
      </div>
    </Modal>
  );

  function renderEmployee(employeeName: string) {
    return (
      <div className={styles.employee}>
        <span className={styles.profile_image}>
          <ProfileImage name={employeeName} size="small" />
        </span>

        <Text bold>{employeeName}</Text>

        {employees && (
          <button
            onClick={clearSelectedEmployee}
            className={styles.clear_employee_btn}>
            <Icon size={12} icon={IconEnum.CLOSE} color="inherit" />
          </button>
        )}
      </div>
    );
  }

  function renderSearchInput() {
    return (
      <div className={styles.dropdownContainer}>
        <div className={`${styles.dropdownContent} ${styles.search}`}>
          <span className={styles.search_icon}>
            <Icon size={16} icon={IconEnum.SEARCH} color={Color.PRIMARY} />
          </span>

          <input
            ref={inputRef}
            type="text"
            placeholder="Search employee"
            className={styles.search_input}
            onChange={(e) => setSearch(e.target.value)}
          />

          {search && (
            <div className={styles.options}>
              {employees?.map((employee) => {
                if (!employee.name.toLowerCase().includes(search.toLowerCase()))
                  return null;

                return (
                  <button
                    key={employee.id}
                    onClick={() => setSelectedEmployee(employee)}
                    className={styles.option}>
                    {employee.name}
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderDropdown() {
    const companyValueLabel = getCompanyValueLabel();

    return (
      <div className={styles.dropdownContainer}>
        <div className={styles.dropdownContent}>
          <button
            className={styles.select_value_btn}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <Icon size={24} icon={IconEnum.HIGH5} />
            <Text
              color={
                selectedValue === NO_COMPANY_VALUE
                  ? Color.GREY
                  : Color.DARK_GREY
              }>
              {companyValueLabel}
            </Text>
            <Icon icon={IconEnum.CHEVRON_DOWN} size={12} />
          </button>

          {isDropdownOpen && (
            <div className={styles.options}>
              {companyValues.map((value) => (
                <button
                  key={value.id}
                  className={styles.option}
                  onClick={() => {
                    setSelectedValue(value.id);
                    setIsDropdownOpen(false);
                  }}>
                  {value.name}
                </button>
              ))}

              <button
                className={styles.option}
                onClick={() => {
                  setSelectedValue(null);
                  setIsDropdownOpen(false);
                }}>
                Other
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  function sendHigh5() {
    onSend({
      comment,
      company_value_id: selectedValue,
      user_id: selectedEmployee!.id,
    });
    setComment('');
    setSelectedValue(NO_COMPANY_VALUE);
    closeModal();
  }

  function isSendDisabled() {
    if (!selectedEmployee) return true;
    if (selectedValue === NO_COMPANY_VALUE) return true;
    if (selectedValue === OTHER_COMPANY_VALUE && !comment) return true;
    return false;
  }

  function closeModal() {
    onClose();
    setIsDropdownOpen(false);
    setSelectedValue(NO_COMPANY_VALUE);
    if (!preselected) setSelectedEmployee(null);
    setSearch('');
    setComment('');
  }

  function clearSelectedEmployee() {
    setSelectedEmployee(null);
    setSearch('');
    setTimeout(() => inputRef.current?.focus(), 0);
  }

  function getCompanyValueLabel(): string {
    if (selectedValue === NO_COMPANY_VALUE) return 'Select a Company Value';

    const companyValue = companyValues.find((v) => v.id === selectedValue);
    if (companyValue) return companyValue.name;

    return 'Other';
  }
}
