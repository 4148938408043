import React from "react";
import styles from './StandardsRatings.module.css';
import classNames from "classnames/bind";

type Props = {
  score?: number;
  onClick: (rating: number) => void;
  disabled?: boolean;
}

const cx = classNames.bind(styles);

const scoreRatings = ["Developing", "Proficient", "Advanced"];

export default function StandardsRatings(props: Props) {
  const { disabled, score, onClick } = props;

  return (
    <div className={styles.ratings}>
      {scoreRatings.map((r, i) => rating(r, i))}
    </div>
  );

  function rating(rating: string, index: number) {
    const style = cx({
      rating: true,
      active: score !== undefined && index === score - 1,
    });

    return (
      <button
        className={style}
        onClick={() => onClick(index+1)}
        disabled={disabled}
        key={index}
      >
        { rating }
      </button>
    );
  }
}
