import React, { useState } from "react";
import { ClientStandardsReview, Employee } from "../../../types";
import styles from "./EmployeeReviewCard.module.css";
import {
  Button,
  Card,
  Color,
  Heading,
  Icon,
  IconEnum,
  PerformanceReviewModal,
} from "../../index";
import { formatDate } from "../../../utils/functions";
import { inject, observer } from "mobx-react";
import { IStandardStore } from "../../../stores";

interface Props {
  review: ClientStandardsReview;
  employee: Employee;
  standardStore?: IStandardStore;
}

function _ReviewCard(props: Props) {
  const { employee, review } = props;
  const [isVisible, setIsVisible] = useState(false);
  const standardStore = props.standardStore!;

  return (
    <Card className={styles.card}>
      <header className={styles.card__header}>
        <Heading level={3}>
          Performance Standards <span>{review.period.label}</span>
        </Heading>

        <button
          className={styles.more_info}
          onClick={() => standardStore.showPerformanceDefinitionsModal()}
        >?</button>
      </header>
      <div className={styles.card__body}>
        {renderExtra()}
      </div>

      <PerformanceReviewModal
        review={review}
        isVisible={isVisible}
        employee={employee}
        onClose={() => setIsVisible(false)}
      />
    </Card>
  );

  function renderExtra() {
    return (
      <div className={styles.extras}>
        {!!review.completed_at &&
          <time className={styles.review__date}>
            <Icon
              icon={IconEnum.CALENDAR}
              color={Color.PRIMARY}
            />
            Completed on {formatDate(review.completed_at, "MM/DD/YYYY")}
          </time>
        }

        {renderActions()}
      </div>
    );
  }

  function renderActions() {
    return (
      <div className={styles.review__actions}>
        <Button
          role={'primary'}
          onClick={() => setIsVisible(true)}
        >View</Button>
      </div>
    );
  }
}

export const EmployeeReviewCard = inject(
  'standardStore'
)(observer(_ReviewCard));
