import React from 'react';
import classNames from 'classnames/bind';
import {
  Column,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import '../../../types/react-table-config.d'
import styles from './Table.module.css';

const cx = classNames.bind(styles);

type Props<T extends object> = {
  className?: string;
  columns: Array<Column<T>>;
  data: Array<T>;
  pageSize?: 10 | 25 | 50 | 100 | 200;
  footer?: boolean;
};

export function Table(props: Props<any>) {
  const { className, columns, data, footer } = props;
  const css = className || '';

  const _className = cx({
    dataTable: true,
    [css]: css,
  });

  const {
    canNextPage,
    canPreviousPage,
    footerGroups,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    prepareRow,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: props.pageSize || 50 },
    },
    useSortBy,
    usePagination,
  );

  return (
    <>
      <table
        {...getTableProps()}
        className={_className}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>

        {footer &&
          <tfoot>
            {footerGroups.map(group => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  <td {...column.getFooterProps()}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        }
      </table>

      {pageCount > 1 && (
        <nav className="pagination">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </button>

          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>

          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'>'}
          </button>

          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>

          <span>
            Page{' '}
            <strong>{ pageIndex + 1 }</strong>
            of
            <strong>{ pageCount }</strong>
          </span>

          <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(page)
              }}
            />
          </span>
          <span>
            Show:
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 25, 50, 100, 200].map(pageSize => (
                <option
                  key={pageSize}
                  value={pageSize}
                >
                  {pageSize}
                </option>
              ))}
            </select>
          </span>
        </nav>
      )}
    </>
  );
}
