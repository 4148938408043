import React from 'react';
import { Route, Redirect, Switch, RouteProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useAdaptiveLayout } from './utils/hooks';

import ApplicationUsageReportScreen from './screens/reports/ApplicationUsageReport';
import CheckInCyclesScreen from './screens/checkInCycles/CheckInCyclesScreen';
import CreateUserScreen from './screens/users/CreateUserScreen';
import DashboardScreen from './screens/dashboard/DashboardScreen';
import EditGoalScreen from './screens/goal/EditGoalScreen';
import EditUserScreen from './screens/users/EditUserScreen';
import EmployeePerformanceReportScreen from './screens/reports/EmployeePerformanceReport';
import EmployeeScreen from './screens/employee/EmployeeScreen';
import EmployeeStandardsPerformanceReport from './screens/reports/EmployeeStandardsPerformanceReport';
import EvaluationPeriodsScreen from './screens/evaluationPeriods/EvaluationPeriodsScreen';
import LoginScreen from './screens/login/LoginScreen';
import ManagerPerformanceReportScreen from './screens/reports/ManagerPerformanceReport';
import ManagerScreen from './screens/manager/ManagerScreen';
import ManageStandards from './screens/standards/ManageStandards';
import ManageValues from './screens/company-values/ManageValues';
import NewGoalScreen from './screens/goal/NewGoalScreen';
import PasswordRecoveryScreen from './screens/login/PasswordRecoveryScreen';
import PasswordResetScreen from './screens/login/PasswordResetScreen';
import ProfileScreen from './screens/profile/ProfileScreen';
import ReportScreen from './screens/report/ReportScreen';
import ReportsScreen from './screens/reports/ReportsScreen';
import SettingsScreen from './screens/settings/SettingsScreen';
import UsersScreen from './screens/users/UsersScreen';

import {
  EvaluationPeriodModalContainer,
  High5ModalContainer,
  Icon,
  IconEnum,
  NotificationList,
} from './components';
import stores, { IUserStore } from './stores';
import styles from './Routes.module.css';

type Props = {
  userStore?: IUserStore;
};

function App(props: Props) {
  const { isDesktop } = useAdaptiveLayout();

  if (props.userStore?.isInitialized === false) {
    // don't render anything until all needed data is fetched
    return (
      <div className={styles.app_loader}>
        <Icon icon={IconEnum.HIGH5} size={50} />
      </div>
    );
  }

  return (
    <>
      <Switch>
        {/* Manager routes */}
        <PrivateRoute
          path="/employee/:employee_id/goal/new"
          component={NewGoalScreen}
        />

        <PrivateRoute
          path="/employee/:employee_id/report"
          component={ReportScreen}
        />

        <PrivateRoute
          path="/employee/:employee_id"
          component={EmployeeScreen}
        />

        <PrivateRoute
          path="/manager/:manager_id"
          component={ManagerScreen}
        />

        <PrivateRoute
          path="/standards"
          component={ManageStandards}
        />

        <PrivateRoute
          path="/company-values"
          component={ManageValues}
        />

        <PrivateRoute
          path="/goal/:goal_id/edit"
          component={EditGoalScreen}
        />

        <PrivateRoute
          path="/settings"
          component={SettingsScreen}
        />

        <PrivateRoute
          path="/evaluationPeriods/:evaluation_period_id"
          component={CheckInCyclesScreen}
        />

        <PrivateRoute
          path="/evaluationPeriods"
          component={EvaluationPeriodsScreen}
        />

        <PrivateRoute
          path="/reports/applicationUsage"
          component={ApplicationUsageReportScreen}
        />

        <PrivateRoute
          path="/reports/employeePerformance"
          component={EmployeePerformanceReportScreen}
        />

        <PrivateRoute
          path="/reports/employeeStandards"
          component={EmployeeStandardsPerformanceReport}
        />

        <PrivateRoute
          path="/reports/managerPerformance"
          component={ManagerPerformanceReportScreen}
        />

        <PrivateRoute
          path="/reports"
          component={ReportsScreen}
        />

        <PrivateRoute
          path="/users/create"
          component={CreateUserScreen}
        />

        <PrivateRoute
          path="/users/:user_id"
          component={EditUserScreen}
        />

        <PrivateRoute
          path="/users"
          component={UsersScreen}
        />

        {/* Employee routes */}
        {/**
         * we don't use this because we don't have a mecanism to get the logged-in user data
         * for Managers, we get the Employee data by the query param ID
         * for Employees, we should get it from the userStore/localStorage
         */}
        {/* <PrivateRoute path="/goal/new" component={NewGoalScreen} /> */}

        {/* Common routes */}
        <PrivateRoute
          path="/dashboard"
          component={DashboardScreen}
        />

        <PrivateRoute
          path="/profile"
          component={ProfileScreen}
        />

        <Route
          path="/passwordrecovery"
          component={PasswordRecoveryScreen}
        />

        <Route
          path="/passwordreset/:encoded"
          component={PasswordResetScreen}
        />

        <Route
          path="/"
          component={LoginScreen}
        />
      </Switch>

      <EvaluationPeriodModalContainer />

      <High5ModalContainer />

      <div data-desktop={isDesktop}>
        <NotificationList />
      </div>
    </>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute: React.SFC<RouteProps> = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      stores.userStore.isAuthenticated() ? (
        React.createElement(component! as React.SFC<any>, props)
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default inject('userStore')(observer(App));
