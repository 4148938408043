import React, { useState } from "react";
import { Comment } from "../../../types";
import styles from './CommentsList.module.css';
import { CommentCard, Icon, IconEnum, ProfileImage, Spacer, Stack, Textarea } from "../../index";
import { observer } from "mobx-react";
import { useCurrentUser } from "../../../utils/hooks";

type Props = {
  canComment: Boolean;
  comments: Comment[];
  layout?: "basic"|"full";
  onComment?(comment: string): Promise<Boolean>;
  onDelete?(commentId: number): void;
}

function _CommentsList(props: Props) {
  const {
    canComment,
    comments,
    layout = "full",
    onComment,
    onDelete,
  } = props;

  const [newComment, setNewComment] = useState('');
  const currentUser = useCurrentUser();

  return (
    <div className={styles.comments}>
      <Stack gap={16}>
        {comments.map((comment) => {
          const canDelete = comment.commenter.id === currentUser.id;
          return (
            <CommentCard
              key={comment.id}
              comment={comment}
              layout={layout}
              onDelete={onDelete && canDelete ? onDelete : undefined}
            />
          );
        })}
      </Stack>
      {canComment && renderCommentForm()}
    </div>
  );

  function renderCommentForm() {
    if (onComment === undefined) {
      return (<></>);
    }

    return (
      <form
        className={styles.new_comment}
        onSubmit={async (e) => {
          e.preventDefault();
          const success = await onComment(newComment);

          if (success) {
            setNewComment('');
          }
        }}
      >
        {layout === "full" && (<>
          <ProfileImage
            name={currentUser.name}
            size={'regular'}
          />

          <Spacer horizontal={16} />
        </>)}

        <Textarea
          onChange={(({ target }) => setNewComment(target.value))}
          value={newComment}
          placeholder="Write a comment…"
          minRows={1}
        />

        <Spacer horizontal={16} />

        <button
          type="submit"
          className={styles.submit}
          title="Add comment"
          disabled={!newComment.trim().length}
        >
          <Icon
            icon={IconEnum.SUBMIT}
            size={24}
          />
        </button>
      </form>
    )
  }
}

export const CommentsList = observer(_CommentsList);
