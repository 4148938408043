import React, { useEffect, useState } from "react";
import { ClientStandardsReview, ClientStandardsScore, Comment, PerformanceReviewStatus } from "../../../types";
import styles from "./ReviewCard.module.css";
import { PERFORMANCE_REVIEW_STATUS_STYLING, filterScores } from "./ReviewCard.utils";
import {
  Button,
  Card,
  CommentsList,
  Divider,
  Heading,
  Icon,
  IconEnum,
  Progress,
  Space,
  Spacer,
  ToggleableCard
} from "../../index";
import { formatDate, formatPercent } from "../../../utils/functions";
import { StandardsScores } from "../StandardsScores/StandardsScores";
import { IStandardStore } from "../../../stores";
import { inject, observer } from "mobx-react";
import { autorun } from "mobx";

interface Props {
  isOpen?: boolean;
  review: ClientStandardsReview;
  spacing?: Space
  standardStore?: IStandardStore;
  onEdit?(): void;
  onStatusChange?(): void;
}

function _ReviewCard(props: Props) {
  const {
    isOpen,
    review,
    spacing
  } = props;
  const standardStore = props.standardStore!;
  const { reviewComments, scores } = standardStore;
  const [visibleScores, setVisibleScores] = useState<ClientStandardsScore[]>([]);
  const [comments, setComments] = useState<Comment[]>([]);
  const isComplete = !!review.completed_at;
  const total = visibleScores.length;
  const answered = visibleScores.filter(({ score }) => score !== null).length;

  useEffect(
    () => {
      standardStore.fetchScores(review.id);
      standardStore.fetchReviewComments(review.id);
    },
    [review.id, standardStore]
  );

  useEffect(
    () => autorun(() => {
      const visible = filterScores(scores, review.id);
      setVisibleScores(visible);
    }),
    [scores, review.id]
  );

  useEffect(
    () => autorun(() => { setComments(reviewComments.get(review.id) ?? []) }),
    [reviewComments, review.id]
  );

  return (
    <Card>
      <ToggleableCard
        title={<>Performance Standards <span>{review.period.label}</span></>}
        extra={renderExtra()}
        spacing={spacing}
        opened={isOpen}
      >
        <Divider />

        <button
          className={styles.more_info}
          onClick={() => standardStore.showPerformanceDefinitionsModal()}
        >?</button>

        <StandardsScores scores={visibleScores} />

        <Spacer vertical={16} />

        <div className={styles.review__comments}>
          <Heading level={3}>Additional Notes</Heading>

          <Spacer vertical={16} />

          <CommentsList
            canComment={!isComplete}
            comments={comments}
            onComment={addComment}
          />
        </div>
      </ToggleableCard>
    </Card>
  );

  function renderExtra() {
    return (
      <div className={styles.extras}>
        {renderStatus()}

        {renderProgress()}

        {renderDate()}

        {renderActions()}
      </div>
    );
  }

  function renderStatus() {
    const status = review.completed_at
      ? PerformanceReviewStatus.COMPLETED
      : PerformanceReviewStatus.IN_PROGRESS;
    const statusStyle = PERFORMANCE_REVIEW_STATUS_STYLING.get(status);
    const styling = {
      '--color': statusStyle?.color ?? PERFORMANCE_REVIEW_STATUS_STYLING.get(PerformanceReviewStatus.IN_PROGRESS)?.color,
    } as React.CSSProperties;

    return (
      <div
        className={styles.review__status}
        style={styling}
      >
        {statusStyle?.label}
      </div>
    )
  }

  function renderProgress() {
    const percentage = formatPercent(answered / total);

    return (
      <div className={styles.review__progress}>
        <Progress
          max={total}
          value={answered}
        >
          <span>{ percentage.substring(0, percentage.length - 1) }/100%</span>
        </Progress>
      </div>
    );
  }

  function renderDate() {
    return (
      <time className={styles.review__date}>
        <Icon icon={IconEnum.CALENDAR} />
        {formatDate(review.created_at, "MM/DD/YYYY")}
      </time>
    )
  }

  function renderActions() {
    const disabled = (total !== answered);

    return (
      <div className={styles.review__actions}>
        {!isComplete &&
          <Button
            role={'secondary'}
            onClick={() => { standardStore.markReviewComplete(review.id) }}
            disabled={disabled}
          >Complete Report</Button>
        }
      </div>
    );
  }

  async function addComment(comment: string) {
    await standardStore.createReviewComment(review.id, { comment });
    return true;
  }
}

export const ReviewCard = inject(
  'standardStore'
)(observer(_ReviewCard));
