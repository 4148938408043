import React from 'react';
import styles from './AddGoalButton.module.css';
import { Icon, IconEnum } from '../../base';
import { Spacer } from '../../layout';

interface Props {
  onClick: () => void;
}

export function AddGoalButton(props: Props) {
  const { onClick } = props;

  return (
    <button className={styles.addGoalBtn} onClick={onClick}>
      <Icon icon={IconEnum.PLUS} size={10} />
      <Spacer horizontal={8} />
      Add New Goal
    </button>
  );
}
