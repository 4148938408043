import React from "react";
import { High5Button } from "../../custom";
import { Icon, IconEnum, Text } from "../../base";
import { Tab } from "../../../types";
import { useAdaptiveLayout } from "../../../utils/hooks";
import classNames from "classnames/bind";
import stores from "../../../stores";
import styles from './SidebarLayout.module.css';
import Tabs from "../../custom/Tabs/Tabs";
import { Spacer } from "../Spacer/Spacer";

export type TabItem = Tab & {
  location: "main"|"sidebar"
}

type Props = {
  active?: number;
  header?: React.ReactElement;
  tabs: TabItem[];
}

const cx = classNames.bind(styles);

export function SidebarLayout(props: Props) {
  const { active, header, tabs } = props;
  const { isDesktop } = useAdaptiveLayout();

  const style = cx({
    content: true,
    with_sidebar: isDesktop,
  });

  return (
    isDesktop
      ? renderDesktopLayout()
      : renderMobileLayout()
  );

  function renderDesktopLayout() {
    const mainContent = tabs.filter((tab) => tab.location === "main");
    const sideContent = tabs.filter((tab) => tab.location === "sidebar");

    return (
      <div className={style}>
        <main className={styles.main}>
          <div className={styles.main_wrapper}>
            <header className={`${styles.main_header}`}>
              { header }
            </header>

            <Tabs
              active={active}
              tabs={mainContent}
            />
          </div>
        </main>

        {!!sideContent.length &&
          <aside className={styles.sidebar}>
            <div className={styles.sidebar_tabs}>
              <div className={styles.scrollable}>
                <Tabs tabs={sideContent} />
              </div>
            </div>

            <div className={styles.high5s_wrapper}>
              { renderApplauses() }
            </div>
          </aside>
        }
      </div>
    );
  }

  function renderMobileLayout() {
    return (
      <div className={style}>
        <header className={`${styles.main_header}`}>
          { header }
        </header>

        <main className={styles.main}>
          <div className={styles.main_wrapper}>
            <Tabs tabs={tabs} />

            <div className={styles.high5s_wrapper}>
              <High5Button
                onClick={openApplauseModal}
                isRound={true}
              />
            </div>
          </div>
        </main>
      </div>
    );
  }

  function renderApplauses() {
    return (
      <div className={styles.high5s_box}>
        <div className={styles.high5s_icon}>
          <Icon icon={IconEnum.HAND} size={200} color="inherit" />
        </div>

        <div className={styles.high5s_content}>
          <Text bold size={16}>Give someone an applause!</Text>

          <Spacer vertical={16} />

          <Text>
            Have you seen a job well done recently? Then, go ahead and give an
            applause! Acknowledging the hard work and achievements of others is
            a great way to keep them motivated and engaged. So, let them know
            how much you appreciate them by giving them an applause.
          </Text>

          <Spacer vertical={16} />

          <High5Button onClick={openApplauseModal}></High5Button>
        </div>
      </div>
    );
  }

  function openApplauseModal() {
    const employee = stores.employeeStore.employee;
    stores.high5Store.showModal(employee);
  }
}
