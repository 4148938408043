import { Task, User } from '../types';
import { RootStore } from './index';
import { decorate, observable, action, runInAction } from 'mobx';
import { api } from '../services/http';

export const FETCH_TASKS = 'FETCH_TASKS';
export const FETCH_MANAGER = 'FETCH_MANAGER';
export const REQUEST_MEETING = 'REQUEST_MEETING';

const EMPTY_TASKS: Task[] = [];
const EMPTY_MANAGER: User = {
  id: 0,
  manager_id: 0,
  client_id: 0,
  name: '',
  email: '',
  roles: [],
  notification_enabled: false,
};
export interface ITaskStore {
  tasks: Task[];
  manager: User;
  fetchTasks(): Promise<void>;
  fetchManager(managerId: number): Promise<void>;
  completeTask(id: number): Promise<void>;
  requestMeeting(): Promise<void>;
  reset(): void;
}

export default class TaskStore implements ITaskStore {
  stores: RootStore;

  tasks = EMPTY_TASKS;
  manager = EMPTY_MANAGER;

  constructor(stores: RootStore) {
    this.stores = stores;
  }

  async fetchTasks(): Promise<void> {
    this.stores.loadingStore.start(FETCH_TASKS);

    try {
      const tasks = await getUserTasks();
      runInAction(() => (this.tasks = tasks));
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_TASKS);
    }
  }

  async fetchManager(managerId: number): Promise<void> {
    if (!managerId) return;

    this.stores.loadingStore.start(FETCH_MANAGER);

    try {
      if (!this.manager.id) {
        const manager = await getManager(managerId);
        runInAction(() => (this.manager = manager));
      }
    } catch (error) {
      console.warn(error);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
    } finally {
      this.stores.loadingStore.end(FETCH_MANAGER);
    }
  }

  async completeTask(id: number): Promise<void> {
    const index = this.tasks.findIndex((t) => t.id === id);

    try {
      await completeTask(id);
      runInAction(() => this.tasks.splice(index, 1));
    } catch (error) {
      console.error('Error while updating task: ', error);
    }
  }

  async requestMeeting(): Promise<void> {
    this.stores.loadingStore.start(REQUEST_MEETING);
    try {
      await addMeetingTask();
      this.stores.notificationStore.triggerSuccessNotification(
        'Meeting successfully requested!',
      );
    } catch (error) {
      console.error(error);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
    } finally {
      this.stores.loadingStore.start(REQUEST_MEETING);
    }
  }

  reset() {
    this.tasks = EMPTY_TASKS;
    this.manager = EMPTY_MANAGER;
  }
}

decorate(TaskStore, {
  tasks: observable,
  fetchTasks: action,
  fetchManager: action,
  reset: action,
  completeTask: action,
  requestMeeting: action,
});

async function getUserTasks(): Promise<TasksResponse> {
  const { data } = await api.get('tasks');
  return data.data;
}

async function getManager(managerId: number): Promise<User> {
  const response = await api.get(`users/${managerId}`);
  return response.data;
}

async function completeTask(taskId: number): Promise<void> {
  const { data } = await api.patch(`tasks/${taskId}`);
  return data.data;
}

async function addMeetingTask(): Promise<void> {
  const { data } = await api.post('tasks');
  return data.data;
}

type TasksResponse = Array<Task>;
