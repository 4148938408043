import React, { useMemo } from 'react';
import { CheckInCycleTypes, EvaluationPeriod, EvaluationPeriodTypes } from '../../../types';
import { Table, } from '../../';
import { Column } from 'react-table';
import { IEvaluationPeriodStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

interface Props {
  data: Array<EvaluationPeriod>;
  evaluationPeriodStore?: IEvaluationPeriodStore
}

function EvaluationPeriodTable(props: Props) {
  const { data } = props;
  const evaluationPeriodStore = props.evaluationPeriodStore!;

  const columns: Array<Column<EvaluationPeriod>> = useMemo(() => [
    {
      Header: 'Evaluation Label',
      accessor: 'label',
      Cell: ({ cell: { row }, value }) => {
        return (
          <Link to={`/evaluationPeriods/${row.original.id}`}>{value}</Link>
        )
      },
    },
    {
      Header: 'Evaluation Type',
      accessor: (row: EvaluationPeriod) => {
        return getEvaluationPeriodType(
          new Date(row.start_date),
          new Date(row.end_date)
        )
      },
    },
    {
      Header: 'Start',
      accessor: 'start_date',
      Cell: (({ value }) => {
        return formatDate(new Date(value));
      }),
    },
    {
      Header: 'End',
      accessor: 'end_date',
      Cell: (({ value }) => {
        return formatDate(new Date(value));
      }),
    },
    {
      Header: 'Check-In Cycle Type',
      accessor: (row: EvaluationPeriod) => {
        const cycles = evaluationPeriodStore.checkInCycles
          .filter(cycle => cycle.evaluation_period_id === row.id)
        const type = getEvaluationPeriodType(
          new Date(row.start_date),
          new Date(row.end_date)
        )

        if (
          (type === EvaluationPeriodTypes.ANNUAL && cycles.length === 4)
          || (type === EvaluationPeriodTypes.BI_ANNUAL && cycles.length === 2)
        ) {
          return CheckInCycleTypes.QUARTERLY
        }

        if (
          (type === EvaluationPeriodTypes.ANNUAL && cycles.length === 6)
          || (type === EvaluationPeriodTypes.BI_ANNUAL && cycles.length === 3)
        ) {
          return CheckInCycleTypes.BI_MONTHLY
        }

        return CheckInCycleTypes.MONTHLY
      },
    },
  ], [evaluationPeriodStore.checkInCycles]);

  return (
    <Table
      columns={columns}
      data={data}
    ></Table>
  );

  function getEvaluationPeriodType(start_date: Date, end_date: Date)
  {
    const elapsed = (end_date.getTime() - start_date.getTime()) / 31536000000

    return elapsed <= 0.55
      ? EvaluationPeriodTypes.BI_ANNUAL
      : EvaluationPeriodTypes.ANNUAL
  }

  function formatDate(date: Date) {
    return new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      timeZone: 'UTC',
    }).format(date);
  }
}

export default inject('evaluationPeriodStore')(observer(EvaluationPeriodTable));
