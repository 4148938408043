import React, { useMemo } from "react";
import styles from './MultiSelect.module.css';
import {
  Text,
} from '../../'

export type OptionValue = string | number;

export type Option<T extends OptionValue> = {
  value: T,
  label: string;
  selected: boolean;
}

interface Props<T extends OptionValue>
{
  label: string;
  onChange: (values: Option<T>[]) => void;
  values: Option<T>[];
}

export function MultiSelect<T extends OptionValue> (props: Props<T>)
{
  const {
    label,
    onChange,
    values,
  } = props;

  const isChecked = useMemo(
    () => {
      return values.reduce(
        (prev, curr) => (prev + (curr.selected ? 1 : 0)),
        0
      ) === values.length
    },
    [values]
  )

  return (
    <div className={styles.select}>
      <div className={styles.heading}>
        <label>
          <input
            type="checkbox"
            className={styles.checkbox}
            onChange={toggleAll}
            checked={isChecked}
          />
          <Text bold inline>{ label }</Text>
        </label>
      </div>

      <div className={styles.selectList}>
        {renderOptions()}
      </div>
    </div>
  );

  function renderOptions()
  {
    return values.map((option: Option<OptionValue>, key) => (
      <label
        className={styles.option}
        key={option.value}
      >
        <input
          type="checkbox"
          className={styles.checkbox}
          onChange={toggle}
          value={option.value}
          checked={option.selected}
        />
        {option.label}
      </label>
    ))
  }

  function toggleAll(e: React.ChangeEvent<HTMLInputElement>)
  {
    const selected = e.target.checked;
    onChange(
      values.map(option => ({ ...option, selected }))
    );
  }

  function toggle(e: React.ChangeEvent<HTMLInputElement>)
  {
    const selected = e.target.checked;
    onChange(
      values.map(option =>
        (option.value === e.target.value || option.value === Number(e.target.value))
          ? { ...option, selected }
          : option
      )
    );
  }
}
