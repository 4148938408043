import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';

import { Card, GoalCard, GoalPlaceholder, AddGoalButton } from '..';
import { Stack, Spacer, Divider } from '../../layout';
import { Icon, IconEnum } from '../../base';

import { IClientStore, IGoalStore, ILoadingStore } from '../../../stores';
import { FETCH_GOALS } from '../../../stores/goalStore';
import { Employee, GoalStatus, UserRole } from '../../../types';

import { NoGoal } from './NoGoal';
import { GoalDetailsContainer } from './GoalDetailsContainer';
import styles from './EmployeePage.module.css';

type Props = {
  employee: Employee;
  role: UserRole;
  goalStore?: IGoalStore;
  loadingStore?: ILoadingStore;
  clientStore?: IClientStore;
};

function _EmployeePage(props: Props) {
  const loadingStore = props.loadingStore!;
  const goalStore = props.goalStore!;
  const clientStore = props.clientStore!;

  const { employee, role } = props;
  const { filteredGoals, inactiveGoals, goals, showAllGoals } = goalStore;
  const isLoading = loadingStore.isLoading(FETCH_GOALS);
  const [loaded, setLoaded] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!employee.id) return;

    goalStore.fetchGoals(employee.id)
      .then(() => {
        setLoaded(true);
      });
  }, [clientStore.evaluationPeriod.id, goalStore, employee.id, setLoaded]);

  const { search, key } = useLocation();

  const query = new URLSearchParams(search);
  const queryId = query.get('goal');

  useEffect(() => {
    if (!queryId) return;
    inactiveGoals.forEach((item) => {
      if (item.goal.id === parseInt(queryId)) goalStore.setShowAllGoals(true);
    });
  }, [key, queryId, inactiveGoals, goalStore]);

  return (
    <>
      {!goals.length && loaded && (
        <NoGoal role={role} employeeId={employee.id} />
      )}

      {(!goals.length && !loaded) && (
        <Stack gap={8}>
          <Card key={0}>
            <GoalPlaceholder />
          </Card>

          <Card key={1}>
            <GoalPlaceholder />
          </Card>

          <Card key={2}>
            <GoalPlaceholder />
          </Card>
        </Stack>
      )}

      {!!goals.length && (
        <Stack gap={8}>
          {filteredGoals.map((item) => {
            const { comments_count, goal } = item;

            return (
              <Card key={goal.id}>
                <GoalCard
                  key={goal.id}
                  goal={goal}
                  comments={comments_count}
                  onEdit={editGoal(goal.id, role, goal.status)}
                  onDelete={deleteGoal(goal.id, role, goal.status)}
                  onStatusChange={changeGoalStatus(goal.id, role)}
                  onProgressChange={(newProgress) =>
                    goalStore.changeProgress(newProgress, goal.id)
                  }
                  background={getBackground(goal.status)}
                  isOpen={search.includes(`goal=${goal.id}`)}>
                  <Divider />
                  <GoalDetailsContainer
                    goal={goal}
                    role={role}
                  />
                </GoalCard>
              </Card>
            );
          })}
        </Stack>
      )}

      <Spacer vertical={8} />

      {renderToggleAllGoalsButton()}

      {!isLoading && (
        <>
          <Spacer vertical={16} />
          <div className={styles.add_goal_wrapper}>{renderAddGoalButton()}</div>
          <Spacer vertical={16} />
        </>
      )}
    </>
  );

  function renderAddGoalButton() {
    return (
      goals.length > 0 && (
        <AddGoalButton
          onClick={() => history.push(`/employee/${employee.id}/goal/new`)}
        />
      )
    );
  }

  function renderToggleAllGoalsButton() {
    if (
      goalStore.filteredGoals.length === 0 ||
      goalStore.inactiveGoals.length === 0 ||
      goalStore.activeGoals.length === 0
    ) {
      return null;
    }

    return (
      <Card>
        <button
          onClick={() => goalStore.setShowAllGoals(!showAllGoals)}
          className={styles.toggle_all_goals}>
          <Icon icon={IconEnum.GOAL} size={20} />
          <Spacer horizontal={8} />
          {showAllGoals ? 'Hide inactive goals' : 'Show all goals'}
        </button>
      </Card>
    );
  }

  function changeGoalStatus(goalId: number, role: UserRole) {
    if (role === 'employee') {
      return;
    }
    return (newStatus: GoalStatus) => goalStore.changeStatus(newStatus, goalId);
  }

  function editGoal(goalId: number, role: UserRole, status: GoalStatus) {
    if (role === 'employee' && status !== GoalStatus.PENDING && status !== GoalStatus.NOT_APPROVED) {
      return;
    }
    return () => history.push(`/goal/${goalId}/edit`);
  }

  function deleteGoal(goalId: number, role: UserRole, status: GoalStatus) {
    if (role === 'employee' && status !== GoalStatus.PENDING) {
      return;
    }
    return () => goalStore.deleteGoal(goalId);
  }
}

function getBackground(status: GoalStatus): string | undefined {
  switch (status) {
    case GoalStatus.OBSOLETE:
      return '#F7F7F7';

    case GoalStatus.NOT_COMPLETED:
      return '#FFF8F2';

    default:
      return;
  }
}

export const EmployeePage = inject(
  'clientStore',
  'loadingStore',
  'goalStore',
)(observer(_EmployeePage));
