import React from 'react';
import { useHistory } from 'react-router-dom';

import { UserRole } from '../../../types';
import {
  Icon,
  IconEnum,
  Heading,
  Text,
  Color,
  Spacer,
  AddGoalButton,
} from '../../';

import styles from './NoGoal.module.css';

const DESCRIPTION = new Map<UserRole, string>()
  .set(
    'manager',
    'Start adding goals by clicking the button bellow. We suggest you do that together with the employee.',
  )
  .set(
    'employee',
    'Waiting for your manager to set a meeting for setting goals.',
  );

type Props = {
  role: UserRole;
  employeeId: number;
};

export function NoGoal(props: Props) {
  const { role, employeeId } = props;
  const history = useHistory();

  return (
    <div className={styles.container}>
      <Icon icon={IconEnum.GOAL} size={55} />
      <Spacer vertical={24} />
      <Heading level={3}>No goals added for current period.</Heading>
      <Spacer vertical={4} />
      <Text color={Color.GREY}>{DESCRIPTION.get(role)}</Text>
      <Spacer vertical={32} />
      <AddGoalButton
        onClick={() => history.push(`/employee/${employeeId}/goal/new`)}
      />
    </div>
  );
}
