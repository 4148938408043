import React from 'react';
import styles from './ReportsScreen.module.css';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import {
  Card,
  Content,
  Divider,
  Heading,
  Spacer,
  Text,
  Wrapper,
} from '../../components';
import { IUserStore } from '../../stores';

interface Props {
  userStore?: IUserStore;
}

function ReportsScreen(props: Props) {
  const userStore = props.userStore!;

  return (
    <Wrapper>
      <Content
        title="Reports"
        header={<Heading level={1}>Reports</Heading>}
        body={renderBody()}
      />
    </Wrapper>
  );

  function renderBody() {
    return (
      <Card className={styles.reports_card}>
        {userStore.hasSuperRole && (
          <>
            <Link to="/reports/applicationUsage">
              <Text size={18} bold>Application Usage</Text>
              <Spacer vertical={4} />
              <Text size={14}>View application usage</Text>
            </Link>

            <Divider gap={32}></Divider>
          </>
        )}

        <Link to="/reports/employeePerformance">
          <Text size={18} bold>Employee Performance</Text>
          <Spacer vertical={4} />
          <Text size={14}>View employee performance</Text>
        </Link>

        <Divider gap={32}></Divider>

        <Link to="/reports/employeeStandards">
          <Text size={18} bold>Standards Evaluations</Text>
          <Spacer vertical={4} />
          <Text size={14}>View evaluation reports</Text>
        </Link>

        {userStore.hasAdminRole && (
          <>
            <Divider gap={32}></Divider>

            <Link to="/reports/managerPerformance">
              <Text size={18} bold>Manager Performance</Text>
              <Spacer vertical={4} />
              <Text size={14}>View manager performance</Text>
            </Link>
          </>
        )}
      </Card>
    );
  }
}

export default inject('userStore')(observer(ReportsScreen));
