import React from 'react';
import styles from './PerformanceDefinitionsModal.module.css';
import {
  Heading,
  Modal,
} from '../../';

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

export function PerformanceDefinitionsModal(props: Props) {
  const { isVisible, onClose } = props;

  return (
    <div className={styles.performanceDefinitionsModal}>
      <Modal isOpen={isVisible} onClose={closeModal}>
        <div className={styles.container}>
          {renderHeader()}

          {renderBody()}
        </div>
      </Modal>
    </div>
  );

  function renderHeader() {
    return (
      <div className={styles.header}>
        <Heading level={2} color={"inherit"}>Definitions</Heading>
      </div>
    );
  }

  function renderBody() {
    return (
      <div className={styles.body}>
        <div className={styles.definitions}>
          <div className={styles.definition}>
            <div className={styles.definition__term}>
              Advanced
            </div>

            <div className={styles.definition__data}>
              <ul>
                <li>Performance consistently exceeds specified criteria</li>
                <li>Reliably completes routing assignments in an accurate and timely fashion</li>
                <li>Is a role model in the job</li>
                <li>Exhibits mastery in all facets of a job</li>
                <li>Acts as a mentor, guid and teacher</li>
                <li>Seeks new and improved ways to perform the job</li>
              </ul>
            </div>
          </div>

          <div className={styles.definition}>
            <div className={styles.definition__term}>
              Proficient
            </div>

            <div className={styles.definition__data}>
              <ul>
                <li>Meeting the expectations of the specified criteria</li>
                <li>Demonstrates a sufficient level of expertise</li>
                <li>Makes a meaningful contribution to the success of the company</li>
                <li>Employee requires little to no amount of supervision</li>
              </ul>
            </div>
          </div>

          <div className={styles.definition}>
            <div className={styles.definition__term}>
              Developing
            </div>

            <div className={styles.definition__data}>
              <ul>
                <li>Performance meets some but not all the specified criteria</li>
                <li>Performance requires supervision and guidance</li>
                <li>Does not consistently complete job assignments in some areas in an accurate and timely fashion</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function closeModal() {
    onClose();
  }
}
