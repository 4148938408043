import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IClientStore, IEmployeeStore, IGoalStore, IUserStore } from '../../stores';
import {
  Button,
  Color,
  Content,
  Inline,
  Text,
  Wrapper,
  TaskList,
  EmployeeHeader,
  GoalForm,
} from '../../components';
import { Goal, GoalStatus } from '../../types';
import styles from './GoalScreen.module.css';
import { GoalPayload } from '../../stores/goalStore';
import { useAdaptiveLayout } from '../../utils/hooks';
import { EMPTY_USER } from '../../stores/userStore';
import { EMPTY_EMPLOYEE } from '../../stores/employeeStore';

const EMPTY_GOAL: Goal = {
  deadline: '',
  description: '',
  id: 0,
  progress: 0,
  progress_target: 0,
  progress_type: null,
  status: GoalStatus.NOT_COMPLETED,
  title: '',
  user: EMPTY_USER,
  review_window: false,
  created_at: '2020-09-14 10:24:26',
  comments_count: 0,
};

type RouteParams = {
  employee_id: string;
};
type RouteProps = RouteComponentProps<RouteParams>;

type Props = RouteProps & {
  clientStore: IClientStore;
  employeeStore: IEmployeeStore;
  goalStore: IGoalStore;
  userStore: IUserStore;
};

function NewGoalScreen(props: Props) {
  const employeeStore = props.employeeStore!;
  const goalStore = props.goalStore!;
  const userStore = props.userStore!;
  const history = useHistory();

  const employeeId = parseInt(props.match.params.employee_id);
  const { isDesktop } = useAdaptiveLayout();

  const [employee, setEmployee] = useState(EMPTY_EMPLOYEE);

  useEffect(() => {
    employeeStore.fetchEmployees(userStore.user);
    employeeStore.setEmployeeId(employeeId);
    setEmployee(employeeStore.employee!);
  }, [employeeId, employeeStore, userStore, employeeStore.employees.length]);

  return (
    <Wrapper>
      { employee ?
        <Content
          header={<EmployeeHeader employee={employee} />}
          title="New Goal"
          body={renderMainBody()}
          tabs={
            userStore.isManager
              ? [
                  {
                    title: 'Tasks',
                    body: <TaskList />,
                  },
                ]
              : undefined
          }
        /> : <></>
      }
    </Wrapper>
  );

  function renderMainBody() {
    return (
      <div className={styles.goalScreen}>
        <div className={isDesktop ? styles.pad32 : styles.pad16}>
          <Inline align={isDesktop ? 'start' : 'center'} gap={16}>
            <div>
              <Text bold size={16}>
                Set a new goal
              </Text>

              {isDesktop && (
                <Text color={Color.GREY}>
                  Check out your resources on the right for tips and tricks on
                  setting goals
                </Text>
              )}
            </div>

            <Button
              role={'tertiary'}
              onClick={() => history.push(`/employee/${employeeId}`)}>
              Cancel
            </Button>
          </Inline>
        </div>

        <GoalForm
          role={userStore.isManager ? 'manager' : 'employee'}
          onSubmit={addNewGoal}
          goal={EMPTY_GOAL}
          onCancel={() => history.goBack()}
        />
      </div>
    );
  }

  function addNewGoal(goal: GoalPayload) {
    goalStore.addGoal(employeeId, goal);
    history.goBack();
  }
}

export default inject(
  'clientStore',
  'employeeStore',
  'goalStore',
  'userStore',
)(observer(NewGoalScreen));
