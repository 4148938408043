import { Environment } from './types';

import { config as DEV } from './config.dev';
import { config as TEST } from './config.stage';
import { config as PROD } from './config.prod';

const Environments: Environment = {
  DEV,
  TEST,
  PROD,
};
let env = Environments.DEV;

if (process.env.REACT_APP_ENV === 'staging') {
  env = Environments.TEST;
}

if (process.env.REACT_APP_ENV === 'production') {
  env = Environments.PROD;
}

export default env;
