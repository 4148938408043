import React from 'react';
import styles from './StrengthMeter.module.css';
// import classNames from 'classnames/bind';

// const cx = classNames.bind(styles);

interface Props {
  value: number;
  max: number;
  showLabel?: boolean;
}

export function StrengthMeter(props: Props) {
  const {
    value,
    max,
    showLabel,
  } = props;
  const label = strengthLabel(value);
  const test = styles['-' + label];

  return (
    <div className={styles.container}>
      <progress
        value={value}
        max={max}
        className={styles.meter + ' ' + test}
      />
      {showLabel &&
        <label className={styles.label}>
          { label }
        </label>
      }
    </div>
  );

  function strengthLabel(value: number): string {
    switch(value) {
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  }
}

StrengthMeter.defaultProps = {
  value: 0,
  max: 4,
  showLabel: true,
};
