import React, { useEffect, useState } from 'react';
import styles from './EmployeeStandardsPerformanceReportScreen.module.css';
import { inject, observer } from 'mobx-react';
import uniqBy from 'lodash.uniqby';
import {
  Button,
  Card,
  Color,
  Content,
  Heading,
  Icon,
  IconEnum,
  Spacer,
  Table,
  Wrapper,
} from '../../components';
import { IClientStore, IEvaluationPeriodStore, IUserStore } from '../../stores';
import { EvaluationReport, EvaluationReportRating, ReportRequest } from '../../types';
import { Column } from 'react-table';
import { formatDate } from '../../utils/functions';

interface Props
{
  clientStore?: IClientStore;
  evaluationPeriodStore?: IEvaluationPeriodStore;
  userStore?: IUserStore;
}

function formatColumns(cols: EvaluationReportRating[]): Column<EvaluationReport>[] {
  return cols.map(({ id, name: Header }) => ({
    Header,
    accessor: ((id) => (row: EvaluationReport) => <>{
      row.ratings.find((item) => item.id === id)?.value
    }</>)(id),
  }));
}

function getColumns(data: EvaluationReport[]) {
  const cols = uniqBy(data.flatMap((item) => item.ratings), 'id');
  return formatColumns(cols);
}

function EmployeeStandardsPerformanceReportScreen(props: Props) {
  const clientStore = props.clientStore!;

  const userStore = props.userStore!;

  const [data, setData] = useState<EvaluationReport[]>([]);

  const [columns, setColumns] = useState<Column<EvaluationReport>[]>([]);

  useEffect(() => {
    const request: ReportRequest = { check_in_cycle: clientStore.checkInCycle.id };
    userStore.getStandardsEvaluation(request)
      .then(response => setData(response));
  }, [
    clientStore.checkInCycle.id,
    userStore,
  ]);

  useEffect(() => {
    const cols: Column<EvaluationReport>[] = [{
      Header: 'Employee Name',
      accessor: ({ name }: EvaluationReport) => <>{name}</>,
    }, {
      Header: 'Date',
      accessor: ({ completed_at }: EvaluationReport) => <>{
        completed_at ? formatDate(completed_at, 'MM/DD/YYYY') : ''
      }</>,
    }, ...getColumns(data)];

    setColumns(cols);
  }, [data, setColumns]);

  return (
    <Wrapper>
      <Content
        title="Standard Evaluation Report"
        header={
          <>
            <a href="/reports">
              <Icon
                icon={IconEnum.CHEVRON_LEFT}
                size={8}
                color={Color.GREY}
              />
              <Spacer horizontal={4} />
              Back
            </a>
            <Spacer vertical={8} />
            <Heading level={1}>Standard Evaluation Report</Heading>
          </>
        }
        body={renderBody()}
      />
    </Wrapper>
  );

  function renderBody() {
    return (
      <Card className={styles.reports_card}>
        <div className={styles.actions}>
          {/* <h2></h2> */}
          <Button onClick={() => savePdf()}>Save PDF</Button>
        </div>

        {!data.length
          ? (<div>No data to show.</div>)
          :
            (<Table
              data={data}
              columns={columns}
              className={styles.table}
            />)
        }
      </Card>
    );
  }

  async function savePdf()
  {
    const request: ReportRequest = { check_in_cycle: clientStore.checkInCycle.id };
    await userStore.downloadStandardsEvaluation(request);
  }
}

export default inject(
  'clientStore',
  'evaluationPeriodStore',
  'userStore'
)(observer(EmployeeStandardsPerformanceReportScreen));
