type OptionValue = string | number;

type Option<T extends OptionValue> = {
  value: T;
  label: string;
};

function getYears(span:number = 6): Option<OptionValue>[]
{
  const values = []
  const year = new Date().getFullYear()

  for (let i = 0; i < span; i++)
  {
    const newYear = year + i
    values[i] = {
      label: newYear.toString(),
      value: newYear,
    }
  }

  return values
}

export type EnumType = {[s:number]:string};

export function mapEnum(enumerable: EnumType, fn: Function): any[]
{
  let enumMembers: any[] = Object.keys(enumerable)
    .map((key:any) => enumerable[key]);

  return enumMembers.map((m, i) => fn(m, i));
}

export const YEARS: Option<OptionValue>[] = getYears()

export const MONTHS: Option<OptionValue>[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(v => ({
  label: new Date(2021, v, 1).toLocaleString('default', {month: 'long'}),
  value: v,
}))
