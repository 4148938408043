import React, { useEffect } from 'react';
import styles from './GoalReviewsContainer.module.css';
import { inject, observer } from 'mobx-react';

import { IReviewStore, IClientStore } from '../../stores';
import { useAdaptiveLayout } from '../../utils/hooks';

import { Goal } from '../../types';
import { Review, Divider, Spacer } from '../../components';

interface Props {
  goal: Goal;
  reviewStore?: IReviewStore;
  clientStore?: IClientStore;
}

function GoalReviews(props: Props) {
  const reviewStore = props.reviewStore!;
  const clientStore = props.clientStore!;
  const { goal } = props;
  const { isDesktop } = useAdaptiveLayout();
  useEffect(() => {
    reviewStore.fetchGoalReviews(goal.id);
  }, [goal.id, reviewStore]);

  const goalReviews = reviewStore.goalReviews.get(goal.id) || [];

  if (goalReviews.length === 0) return <></>;

  return (
    <>
      <Spacer vertical={isDesktop ? 32 : 24} />
      <Divider />
      {goalReviews.map((goalReview) => (
        <div key={goalReview.id} className={styles.review}>
          <Review
            review={goalReview}
            evaluationPeriod={clientStore.evaluationPeriod.label}
            readOnly
          />
        </div>
      ))}
    </>
  );
}

export const GoalReviewsContainer = inject(
  'reviewStore',
  'clientStore',
)(observer(GoalReviews));
