import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IClientStore, IEmployeeStore, IGoalStore, IUserStore } from '../../stores';
import {
  Button,
  Color,
  Content,
  GoalForm,
  Inline,
  Text,
  Wrapper,
  TaskList,
  EmployeeHeader,
} from '../../components';
import { Goal } from '../../types';
import styles from './GoalScreen.module.css';
import { GoalPayload } from '../../stores/goalStore';
import { useAdaptiveLayout } from '../../utils/hooks';
import { EMPTY_EMPLOYEE } from '../../stores/employeeStore';

type RouteParams = {
  goal_id: string;
};
type RouteProps = RouteComponentProps<RouteParams>;

type Props = RouteProps & {
  clientStore: IClientStore;
  employeeStore: IEmployeeStore;
  goalStore: IGoalStore;
  userStore: IUserStore;
};

function EditGoalScreen(props: Props) {
  const employeeStore = props.employeeStore!;
  const goalStore = props.goalStore!;
  const userStore = props.userStore!;
  const history = useHistory();

  const [goal, setGoal] = useState<Goal>();

  const goalId = parseInt(props.match.params.goal_id);

  const { isDesktop } = useAdaptiveLayout();

  const [employee, setEmployee] = useState(EMPTY_EMPLOYEE);

  const [employeeId, setEmployeeId] = useState(0);

  useEffect(() => {
    employeeStore.fetchEmployees(userStore.user);
    employeeStore.setEmployeeId(employeeId);
    setEmployee(employeeStore.employee!);
  }, [employeeId, employeeStore, userStore, employeeStore.employees.length]);

  useEffect(() => {
    goalStore.getGoal(goalId)
      .then((goal) => {
        if (!goal) return;
        setGoal(goal);
        setEmployeeId(goal.user.id);
      });
  }, [goalId, goalStore]);

  return (
    <Wrapper>
      { employee ?
        <Content
          header={<EmployeeHeader employee={employee} />}
          title="Edit goal"
          body={renderMainBody()}
          tabs={
            userStore.isManager
              ? [
                  {
                    title: 'Tasks',
                    body: <TaskList />,
                  },
                ]
              : undefined
          }
        /> : <></>
      }
    </Wrapper>
  );

  function renderMainBody() {
    if (!goal) {
      return <></>;
    }

    return (
      <div className={styles.goalScreen}>
        <div className={isDesktop ? styles.pad32 : styles.pad16}>
          <Inline align={isDesktop ? 'start' : 'center'} gap={16}>
            <div>
              <Text bold size={16}>
                Edit goal
              </Text>

              {isDesktop && (
                <Text color={Color.GREY}>
                  Check out your resources on the right for tips and tricks on
                  setting goals
                </Text>
              )}
            </div>

            <Button role={'tertiary'} onClick={() => history.goBack()}>
              Cancel
            </Button>
          </Inline>
        </div>

        <GoalForm
          role={userStore.isManager ? 'manager' : 'employee'}
          onSubmit={editGoal}
          goal={goal}
          onCancel={() => history.goBack()}
        />
      </div>
    );
  }

  async function editGoal(goal: GoalPayload) {
    await goalStore.editGoal(goal);
    history.goBack();
  }
}

export default inject(
  'clientStore',
  'employeeStore',
  'goalStore',
  'userStore',
)(observer(EditGoalScreen));
