import { RootStore } from "./index";
import {
  ClientStandard,
  ClientStandardsReview,
  ClientStandardsScore,
  Comment
} from "../types";
import {
  activateStandard,
  ClientStandardPayload,
  ClientStandardsScoreComment,
  createReview,
  createScoreComment,
  createStandard,
  deactivateStandard,
  fetchReviews as getReviews,
  fetchScores as getScores,
  fetchStandard as getStandard,
  fetchStandards as getStandards,
  updateScore,
  fetchReviewComments,
  fetchScoreComments,
  createReviewComment,
  markReviewComplete,
  updateStandard,
} from "../services/StandardRepository";
import {
  ObservableMap,
  action,
  decorate,
  observable,
} from "mobx";
import { CREATE_COMMENT } from "./commentStore";

export const ACTIVATE_STANDARD = 'DEACTIVATE_STANDARD';

export const CREATE_REVIEW = 'CREATE_REVIEW';

export const CREATE_STANDARD = 'CREATE_STANDARD';

export const DEACTIVATE_STANDARD = 'DEACTIVATE_STANDARD';

export const FETCH_REVIEW_COMMENTS = 'FETCH_REVIEW_COMMENTS';

export const FETCH_SCORE_COMMENTS = 'FETCH_SCORE_COMMENTS';

export const FETCH_STANDARD = 'FETCH_STANDARD';

export const FETCH_STANDARDS = 'FETCH_STANDARDS';

export const FETCH_STANDARDS_REVIEWS = 'FETCH_STANDARDS_REVIEWS';

export const FETCH_STANDARDS_SCORES = 'FETCH_STANDARDS_SCORES';

export interface IStandardStore
{
  isPerformanceDefinitionsModalOpen: boolean;

  reviewComments: ObservableMap<number, Comment[]>;

  reviews: ObservableMap<number, ClientStandardsReview>;

  scoreComments: ObservableMap<number, Comment[]>;

  scores: ObservableMap<number, ClientStandardsScore>;

  standards: ClientStandard[];

  activateStandard(standardId: number): Promise<ClientStandard|undefined>;

  addReview(review: ClientStandardsReview): void;

  addReviewComment(reviewId: number, comment: Comment): void;

  addScoreComment(scoreId: number, comment: Comment): void;

  addStandard(standard: ClientStandard): void;

  createReviewComment(reviewId: number, payload: ClientStandardsScoreComment): Promise<void>;

  createReview(employeeId: number): Promise<ClientStandardsReview|undefined>;

  createScoreComment(scoreId: number, payload: ClientStandardsScoreComment): Promise<void>;

  createStandard(payload: ClientStandardPayload): Promise<ClientStandard|undefined>;

  deactivateStandard(standardId: number): Promise<ClientStandard|undefined>;

  fetchReviewComments(reviewId: number): Promise<void>;

  fetchReviews(employeeId: number): Promise<void>;

  fetchScoreComments(scoreId: number): Promise<void>;

  fetchScores(reviewId: number): Promise<ClientStandardsScore[]>;

  fetchStandard(standardId: number): Promise<ClientStandard|undefined>;

  fetchStandards(clientId: number): Promise<void>;

  findStandard(standardId: number): Promise<ClientStandard|undefined>;

  hidePerformanceDefinitionsModal(): void;

  markReviewComplete(reviewId: number): Promise<void>;

  setReviewComments(reviewId: number, comments: Comment[]): void;

  setReviews(reviews: ClientStandardsReview[]): void;

  setReview(review: ClientStandardsReview): void;

  setScore(score: ClientStandardsScore): void;

  setScoreComments(scoreId: number, comments: Comment[]): void;

  setScores(scores: ClientStandardsScore[]): void;

  setStandard(standard: ClientStandard): void;

  setStandards(standards: ClientStandard[]): void;

  showPerformanceDefinitionsModal(): void;

  updateScore(record: ClientStandardsScore, score: number): Promise<void>;

  updateStandard(id: number, payload: ClientStandardPayload): Promise<ClientStandard>;
}

export default class StandardStore implements IStandardStore
{
  stores: RootStore;

  isPerformanceDefinitionsModalOpen: boolean;

  reviewComments: ObservableMap<number, Comment[]>;

  reviews: ObservableMap<number, ClientStandardsReview>;

  scoreComments: ObservableMap<number, Comment[]>;

  scores: ObservableMap<number, ClientStandardsScore>;

  standards: ClientStandard[] = [];

  constructor(stores: RootStore)
  {
    this.stores = stores;
    this.isPerformanceDefinitionsModalOpen = false;
    this.reviewComments = new ObservableMap<number, Comment[]>();
    this.reviews = new ObservableMap<number, ClientStandardsReview>();
    this.scoreComments = new ObservableMap<number, Comment[]>();
    this.scores = new ObservableMap<number, ClientStandardsScore>();
  }

  async activateStandard(standardId: number): Promise<ClientStandard | undefined> {
    this.stores.loadingStore.start(ACTIVATE_STANDARD);

    try {
      const { data } = await activateStandard(standardId);
      return data;
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(ACTIVATE_STANDARD);
    }
  }

  addReview(review: ClientStandardsReview): void {
    this.reviews.set(review.id, review);
  }

  addReviewComment(reviewId: number, comment: Comment): void {
    const comments = this.reviewComments.get(reviewId) ?? [];

    comments.push(comment);

    this.reviewComments.set(reviewId, comments);
  }

  addScoreComment(scoreId: number, comment: Comment): void {
    const comments = this.scoreComments.get(scoreId) ?? [];

    comments.push(comment);

    this.scoreComments.set(scoreId, comments);
  }

  addStandard(standard: ClientStandard): void {
    this.standards.push(standard);
  }

  async createReviewComment(reviewId: number, payload: ClientStandardsScoreComment): Promise<void> {
    this.stores.loadingStore.start(CREATE_COMMENT);

    try {
      const { data } = await createReviewComment(reviewId, payload);
      this.addReviewComment(reviewId, data);
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(CREATE_COMMENT);
    }
  }

  async createReview(employeeId: number): Promise<ClientStandardsReview|undefined> {
    this.stores.loadingStore.start(CREATE_REVIEW);

    const check_in_cycle = this.stores.clientStore.checkInCycle.id;

    try {
      const { data: review } = await createReview(employeeId, check_in_cycle);
      this.addReview(review);
      return review;
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(CREATE_REVIEW);
    }
  }

  async createScoreComment(scoreId: number, payload: ClientStandardsScoreComment): Promise<void> {
    this.stores.loadingStore.start(CREATE_COMMENT);

    try {
      const { data } = await createScoreComment(scoreId, payload);
      this.addScoreComment(scoreId, data);
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(CREATE_COMMENT);
    }
  }

  async createStandard(payload: ClientStandardPayload): Promise<ClientStandard|undefined> {
    this.stores.loadingStore.start(CREATE_STANDARD);

    try {
      const { user } = this.stores.userStore;
      const { data } = await createStandard(user.client_id, payload);
      this.addStandard(data);
      return data;
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(CREATE_STANDARD);
    }
  }

  async deactivateStandard(standardId: number): Promise<ClientStandard | undefined> {
    this.stores.loadingStore.start(DEACTIVATE_STANDARD);

    try {
      const { data } = await deactivateStandard(standardId);
      return data;
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(DEACTIVATE_STANDARD);
    }
  }

  async fetchReviewComments(reviewId: number): Promise<void> {
    this.stores.loadingStore.start(FETCH_REVIEW_COMMENTS);

    try {
      const { data } = await fetchReviewComments(reviewId);

      this.setReviewComments(reviewId, data);

    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_REVIEW_COMMENTS);
    }
  }

  async fetchReviews(employeeId: number): Promise<void> {
    const check_in_cycle = this.stores.clientStore.checkInCycle.id;

    if (!check_in_cycle) return;

    this.stores.loadingStore.start(FETCH_STANDARDS_REVIEWS);

    try {
      const reviews = await getReviews(employeeId, check_in_cycle);

      this.setReviews(reviews);
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_STANDARDS_REVIEWS);
    }
  }

  async fetchScoreComments(scoreId: number): Promise<void> {
    this.stores.loadingStore.start(FETCH_SCORE_COMMENTS);

    try {
      const { data } = await fetchScoreComments(scoreId);
      this.setScoreComments(scoreId, data);
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_SCORE_COMMENTS);
    }
  }

  async fetchScores(reviewId: number): Promise<ClientStandardsScore[]> {
    this.stores.loadingStore.start(FETCH_STANDARDS_SCORES);

    try {
      const { data } = await getScores(reviewId);
      this.setScores(data);
      return data;
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_STANDARDS_SCORES);
    }

    return [];
  }

  async fetchStandards(clientId: number): Promise<void> {
    this.stores.loadingStore.start(FETCH_STANDARDS);

    try {
      const { data } = await getStandards(clientId);
      const standards = data.map((item) => item);
      this.setStandards(standards);
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_STANDARDS);
    }
  }

  async fetchStandard(standardId: number): Promise<ClientStandard|undefined> {
    this.stores.loadingStore.start(FETCH_STANDARD);

    try {
      const response = await getStandard(standardId);
      const { data: standard} = response;
      if (this.standards.findIndex((s) => s.id === standard.id) === -1) {
        this.addStandard(standard);
      }
      return standard;
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_STANDARD);
    }

    return;
  }

  async findStandard(standardId: number): Promise<ClientStandard | undefined> {
    const standard = this.standards.find((s) => s.id === standardId);

    if (!standard) {
      return await this.fetchStandard(standardId);
    }

    return standard;
  }

  hidePerformanceDefinitionsModal(): void {
    this.isPerformanceDefinitionsModalOpen = false;
  }

  async markReviewComplete(reviewId: number): Promise<void> {
    const review = await markReviewComplete(reviewId);
    await this.stores.taskStore.fetchTasks();
    this.setReview(review);
  }

  setReviewComments(reviewId: number, comments: Comment[]): void {
    this.reviewComments.set(reviewId, comments);
  }

  setReviews(reviews: ClientStandardsReview[]): void {
    reviews.forEach(review => {
      this.setReview(review);
    });
  }

  setReview(review: ClientStandardsReview): void {
    this.reviews.set(review.id, review);
  }

  setScoreComments(scoreId: number, comments: Comment[]): void {
    this.scoreComments.set(scoreId, comments);
  }

  setScores(scores: ClientStandardsScore[]): void {
    scores.forEach(score => {
      this.setScore(score);
    });
  }

  setScore(score: ClientStandardsScore): void {
    this.scores.set(score.id, score);
  }

  setStandard(standard: ClientStandard): void {
    const idx = this.standards.findIndex(({ id }) => id === standard.id);

    if (idx === -1) return;

    this.standards[idx] = standard;
  }

  setStandards(standards: ClientStandard[]): void {
    this.standards = standards
  }

  showPerformanceDefinitionsModal(): void {
    this.isPerformanceDefinitionsModalOpen = true;
  }

  async updateScore(record: ClientStandardsScore, score: number): Promise<void> {
    const updated = await updateScore(record.id, { score });
    this.setScore(updated);
  }

  async updateStandard(id: number, payload: ClientStandardPayload): Promise<ClientStandard> {
    const updated = await updateStandard(id, payload);
    this.setStandard(updated);
    return updated;
  }
}

decorate(StandardStore, {
  isPerformanceDefinitionsModalOpen: observable,

  reviewComments: observable,

  reviews: observable,

  scoreComments: observable,

  scores: observable,

  standards: observable,

  activateStandard: action,

  addReview: action,

  addReviewComment: action,

  addScoreComment: action,

  addStandard: action,

  createReviewComment: action,

  createReview: action,

  createScoreComment: action,

  createStandard: action,

  deactivateStandard: action,

  fetchReviewComments: action,

  fetchReviews: action,

  fetchScoreComments: action,

  fetchScores: action,

  fetchStandards: action,

  fetchStandard: action,

  findStandard: action,

  hidePerformanceDefinitionsModal: action,

  setReviewComments: action,

  setReviews: action,

  setReview: action,

  setScoreComments: action,

  setScore: action,

  setScores: action,

  setStandard: action,

  setStandards: action,

  showPerformanceDefinitionsModal: action,

  updateScore: action,
});
