import { decorate, observable, action, configure } from 'mobx';
import { Notification, NotificationType } from '../types/index';

configure({ enforceActions: 'observed' });

export interface INotificationStore {
  notifications: Notification[];
  id: number;
  triggerSuccessNotification(message: string): void;
  triggerErrorNotification(message: string): void;
  triggerInfoNotification(message: string): void;
  removeNotification(id: number): void;
  reset(): void;
}

export default class NotificationStore implements INotificationStore {
  notifications = Array<Notification>();
  id = 0;

  triggerSuccessNotification(message: string) {
    this.notifications.push({
      message,
      type: NotificationType.SUCCESS,
      id: this.id++,
    });
  }

  triggerErrorNotification(message: string) {
    this.notifications.push({
      message,
      type: NotificationType.ERROR,
      id: this.id++,
    });
  }

  triggerInfoNotification(message: string) {
    this.notifications.push({
      message,
      type: NotificationType.INFO,
      id: this.id++,
    });
  }

  removeNotification(id: number) {
    const index = this.notifications.findIndex(
      (notification) => notification.id === id,
    );
    this.notifications.splice(index, 1);
  }

  reset() {
    this.notifications = [];
  }
}

decorate(NotificationStore, {
  notifications: observable,
  triggerSuccessNotification: action,
  triggerErrorNotification: action,
  triggerInfoNotification: action,
  removeNotification: action,
});
