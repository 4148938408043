import React, { useState } from 'react';

import styles from './ReviewForm.module.css';

import { Spacer, Button, Inline, Textarea } from '../..';

interface Props {
  children: React.ReactNode;
  buttonText: string;
  onSave: (review: string) => void;
}

export function ReviewForm(props: Props) {
  const { onSave, children, buttonText } = props;
  const [review, setReview] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.header}>{children}</div>
      <Spacer vertical={16} />
      <div className={styles.content}>
        {!isOpen ? (
          <div className={styles.add_review_btn_wrapper}>
            <Button role="primary" onClick={() => setIsOpen(true)}>
              {buttonText}
            </Button>
          </div>
        ) : (
          <>
            <Textarea
              onChange={(e) => setReview(e.target.value)}
              onKeyDown={() => {}}
              value={review}
              placeholder="Add your review here..."
            />
            <Spacer vertical={16} />
            <Inline gap={24}>
              <Button
                onClick={saveReview}
                disabled={review.trim().length === 0}>
                Save Review
              </Button>
              <Button role="tertiary" onClick={cancelReview}>
                Cancel
              </Button>
            </Inline>
          </>
        )}
      </div>
    </div>
  );

  function saveReview() {
    onSave(review);
    setIsOpen(false);
    setReview('');
  }

  function cancelReview() {
    setIsOpen(false);
    setReview('');
  }
}
