import React, { useRef, useState, useEffect } from 'react';
import styles from './GoalCard.module.css';

import {
  useAdaptiveLayout,
  useOnClickOutside,
  scrollInView,
} from '../../../utils/hooks';

import {
  Card,
  Text,
  Spacer,
  Icon,
  IconEnum,
  ToggleableCard,
  Divider,
} from '../..';

import { Color } from '../../styles';

import {
  Goal,
  GoalProgress as GOAL_PROGRESS,
  GoalStatus
} from '../../../types';

import {
  formatDate,
  formatDateShort,
  STATUS_STYLING,
  AVAILABLE_STATUS_TYPES,
} from './GoalCard.utils';

import { GoalProgress } from '../GoalProgress/GoalProgress';
import { Space } from '../../layout';

interface Props {
  goal: Goal;
  comments?: number;
  /** details to be displayed when toggling the Goal */
  children?: React.ReactNode;
  /** default background is white, not transparent */
  background?: string;
  spacing?: Space;
  isOpen?: boolean;
  onEdit?(): void;
  onDelete?(): void;
  onStatusChange?(status: GoalStatus): void;
  onProgressChange?(progress: number): void;
}

export function GoalCard(props: Props) {
  const {
    children,
    goal,
    comments = 0,
    onStatusChange,
    onProgressChange,
    onEdit,
    onDelete,
    background = '#ffffff',
    spacing,
    isOpen,
  } = props;

  const [isStatusOpen, setStatusVisibility] = useState(false);
  // used only on mobile
  const [isActionsVisible, setActionsVisibility] = useState(false);
  const { isDesktop } = useAdaptiveLayout();

  const currentStatus = STATUS_STYLING.get(goal.status);

  const statusRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(statusRef, () => setStatusVisibility(false));

  const deadline = new Date(goal.deadline);
  const formattedDeadline = isDesktop
    ? formatDate(deadline)
    : formatDateShort(deadline);

  const goal_styling = {
    '--background': background,
  } as React.CSSProperties;

  const goalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        scrollInView(goalRef.current);
      }, 100);
    }
  }, [isOpen]);

  return (
    <div
      className={styles.goal}
      data-actions={!isDesktop && isActionsVisible}
      style={goal_styling}
      ref={goalRef}>
      <ToggleableCard
        title={goal.title}
        extra={renderExtra()}
        spacing={spacing}
        opened={isOpen}>
        {renderGoalDetails()}
      </ToggleableCard>
    </div>
  );

  function renderExtra() {
    return (
      <div className={styles.content}>
        <Spacer vertical={4} />

        <div className={styles.description}>
          <Text color={Color.GREY}>{goal.description}</Text>
        </div>

        <Spacer vertical={4} />

        <div className={styles.footer}>
          <div className={styles.goal_details}>
            {renderStatus()}

            <Spacer horizontal={16} />

            {renderAdaptiveProgress()}

            <div className={styles.goal_stats}>
              <Icon icon={IconEnum.CALENDAR} color={Color.GREY} size={14} />
              <Spacer horizontal={8} />
              <Text color={Color.GREY} size={11} lineHeight={1} wrap={false}>
                {formattedDeadline}
              </Text>

              <Spacer horizontal={16} />

              <Icon icon={IconEnum.COMMENT} color={Color.GREY} size={14} />
              <Spacer horizontal={8} />
              <Text color={Color.GREY} size={11} lineHeight={1} wrap={false}>
                <b>{comments}</b> {isDesktop && 'comments'}
              </Text>
            </div>
          </div>

          {isDesktop ? renderActions() : renderActionsToggle()}
        </div>
      </div>
    );
  }

  function renderActionsToggle() {
    if (onEdit || onDelete) {
      return (
        <div className={styles.actions_wrapper}>
          <button
            onClick={() => setActionsVisibility(!isActionsVisible)}
            className={styles.actions_toggle}>
            <Icon icon={IconEnum.DOTS} color={Color.GREY} />
          </button>
          {renderActions()}
        </div>
      );
    }
  }

  function renderActions() {
    return (
      <div className={styles.actions}>
        {!!onEdit && (
          <button onClick={onEdit}>
            <Icon icon={IconEnum.EDIT} color={Color.GREY} />
          </button>
        )}

        {isDesktop ? <Spacer horizontal={4} /> : <Spacer vertical={16} />}

        {!!onDelete && (
          <button onClick={deleteGoal}>
            <Icon icon={IconEnum.DELETE} color={Color.GREY} />
          </button>
        )}
      </div>
    );
  }

  function renderAdaptiveProgress() {
    const { progress_type, status } = goal;

    if (progress_type === null || status === GoalStatus.PENDING) {
      return;
    }

    return (
      <div className={styles.goal_progress}>
        {isDesktop
          ? renderProgress(progress_type)
          : renderProgressSummary(progress_type)}
        <Spacer horizontal={16} />
      </div>
    );
  }

  function renderProgressSummary(progress_type: GOAL_PROGRESS) {
    const { progress } = goal;

    return <GoalProgress.Summary value={progress} type={progress_type} />;
  }

  function renderProgress(progress_type: GOAL_PROGRESS) {
    const { progress_target, progress, status } = goal;
    const isDisabled = status !== GoalStatus.IN_PROGRESS || !onProgressChange;

    return (
      <GoalProgress
        target={progress_target}
        value={progress || 0}
        type={progress_type}
        disabled={isDisabled}
        onChange={(value) => changeProgress(value)}
      />
    );
  }

  function renderStatus() {
    const textColor =
      goal.status === GoalStatus.IN_PROGRESS ? Color.DARK_GREY : Color.WHITE;

    const styling = {
      '--color':
        currentStatus?.color ??
        STATUS_STYLING.get(GoalStatus.IN_PROGRESS)?.color,
    } as React.CSSProperties;

    const canChangeStatus = !!onStatusChange;

    return (
      <div className={styles.status} ref={statusRef}>
        <button
          style={styling}
          className={styles.status_summary}
          onClick={toggleStatus}
          disabled={!canChangeStatus}>
          <Text color={textColor} size={11} bold lineHeight={1}>
            {currentStatus?.label}
          </Text>

          {canChangeStatus && (
            <>
              <Spacer horizontal={8} />
              <Icon
                icon={
                  isStatusOpen ? IconEnum.CHEVRON_UP : IconEnum.CHEVRON_DOWN
                }
                size={12}
                color={textColor}
              />
            </>
          )}
        </button>

        {isStatusOpen === true && (
          <Card className={styles.status_list}>
            <Spacer vertical={4} />

            <Text size={11} color={Color.GREY} bold>
              Change status
            </Text>

            <Spacer vertical={8} />

            {AVAILABLE_STATUS_TYPES(goal).map((status) => {
              const details = STATUS_STYLING.get(status);

              const styling = {
                '--color': details?.color,
              } as React.CSSProperties;

              return (
                <button
                  onClick={() => changeStatus(status)}
                  style={styling}
                  key={status}>
                  {details?.label}
                </button>
              );
            })}
          </Card>
        )}
      </div>
    );
  }

  function renderGoalDetails() {
    const { progress_type } = goal;

    if (isDesktop) {
      return children;
    } else {
      if (!onProgressChange) return children;
      return (
        <>
          {progress_type && (
            <>
              <Divider />
              <div className={styles.goal_progress_mobile}>
                {renderProgress(progress_type)}
              </div>
            </>
          )}
          {children}
        </>
      );
    }
  }

  function toggleStatus() {
    setStatusVisibility(!isStatusOpen);
  }

  function changeStatus(newStatus: GoalStatus) {
    setStatusVisibility(false);
    onStatusChange && onStatusChange(newStatus);
  }

  function changeProgress(newProgress: number) {
    onProgressChange && onProgressChange(newProgress);
  }

  function deleteGoal() {
    const confirm = window.confirm('Permanently delete this goal?');
    confirm && onDelete && onDelete();
  }
}

GoalCard.defaultProps = {
  background: '#ffffff',
};
