import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { IReviewStore } from '../../../stores';
import { EvaluationPeriod, Goal, UserRole } from '../../../types';
import {
  ReviewForm,
  Divider,
  Icon,
  IconEnum,
  Text,
  Spacer,
  Color,
} from '../../';
import { CommentsListContainer } from './CommentsListContainer';
import { ReviewContainer } from './ReviewContainer';
import styles from './GoalDetailsContainer.module.css';
import { ReviewPayload } from '../../../stores/reviewStore';

interface Props {
  goal: Goal;
  role: UserRole;
  reviewStore?: IReviewStore;
}

function GoalDetails(props: Props) {
  const reviewStore = props.reviewStore!;
  const { goal, role } = props;

  const [reviewAdded, setReviewAdded] = useState(false);

  useEffect(() => {
    reviewStore.fetchGoalReviews(goal.id);
  }, [goal.id, reviewStore]);

  const goalReviews = reviewStore.goalReviews.get(goal.id) || [];

  return (
    <div className={styles.goal_details}>
      {(goal.review_window && !reviewAdded) && renderReviewForm()}

      <CommentsListContainer goal={goal} />

      {goalReviews.map((goalReview) => (
        <React.Fragment key={goalReview.id}>
          <Divider />
          <div className={styles.review_container}>
            <ReviewContainer review={goalReview} goal={goal} role={role} />
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  function renderReviewForm() {
    return (
      <div className={styles.review_form_container}>
        <ReviewForm onSave={addReview} buttonText="Add check-in review">
          <>
            <Icon
              icon={IconEnum.CHECK_CIRCLE}
              color={Color.LIGHT_GREY}
              size={24}
            />
            <Spacer horizontal={16} />
            {/* error, review_window is undefined */}
            <Text bold>
              Check in review for {' '}
              <Text color={Color.PRIMARY} bold inline>
                {(goal.review_window as EvaluationPeriod).label}
              </Text>
            </Text>
          </>
        </ReviewForm>
      </div>
    );
  }

  async function addReview(review: string) {
    const payload: ReviewPayload = { review };

    if (goal.review_window && goal.review_window.hasOwnProperty('evaluation_period_id')) {
      payload.client_check_in_cycle_id = goal.review_window.id
    } else {
      payload.client_evaluation_period_id = (goal.review_window as EvaluationPeriod).id;
    }

    const success = await reviewStore.addGoalReview(goal.id, payload);

    if (success) setReviewAdded(true);
  }
}

export const GoalDetailsContainer = inject(
  'reviewStore',
)(observer(GoalDetails));
