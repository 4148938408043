import React from 'react';
import styles from './Select.module.css';

type OptionValue = string | number;

type Option<T extends OptionValue> = {
  value: T;
  label: string;
};

interface Props<T extends OptionValue> {
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  values: Option<T>[];
  defaultValue: string;
  placeholder?: string;
}

export function Select<T extends OptionValue>(props: Props<T>) {
  const { onChange, values, placeholder, defaultValue } = props;

  const renderOptions = () =>
    values.map((value: Option<OptionValue>, key) => (
      <option key={key} value={value.value}>
        {value.label}
      </option>
    ));

  const blankOption = () => (
    <option key={'default'} value={''}>
      {placeholder || 'Select ...'}
    </option>
  );

  return (
    <select
      defaultValue={defaultValue}
      onChange={onChange}
      className={styles.select}>
      {placeholder && blankOption()}
      {renderOptions()}
    </select>
  );
}

Select.defaultProps = {
  placeholder: 'Select an option',
};
