import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { IUserStore } from '../../stores';
import {
  Button,
  Heading,
  Icon,
  IconEnum,
  Input,
  Spacer,
  Stack,
  StrengthMeter,
  Text,
} from '../../components';
import styles from './PasswordResetScreen.module.css';
import { inject, observer } from 'mobx-react';
import { useAdaptiveLayout } from '../../utils/hooks';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import base64url from "base64url";

type RouteParams = {
  encoded: string;
};

type RouteProps = RouteComponentProps<RouteParams>;

type Props = RouteProps & {
  userStore: IUserStore;
};

interface TokenObject {
  email: string;
  token: string;
}

function PasswordResetScreen(props: Props) {
  const { userStore } = props;
  const [passwords, setPasswords] = useState({
    password: '',
    confirmPassword: '',
  })

  const history = useHistory();

  const {
    strength,
    validLength,
    match,
    valid,
  } = usePasswordValidation({
    password: passwords.password,
    confirmPassword: passwords.confirmPassword,
  });

  const setPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswords({ ...passwords, password: event.target.value })
  }

  const setConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswords({ ...passwords, confirmPassword: event.target.value })
  }

  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { isDesktop } = useAdaptiveLayout();
  const { encoded } = props.match.params;

  let decoded: TokenObject = JSON.parse(base64url.decode(encoded));

  const email = decoded.email;
  const token = decoded.token;

  return (
    <div data-mobile={!isDesktop} className={styles.container}>
      <div className={styles.loginContainer}>
        <Stack gap={8} align={'center'}>
          <Icon icon={IconEnum.HIGH5} size={36} />
          <Heading>Reset Password</Heading>
          { success
            ? passwordResetSuccess()
            : passwordResetForm()
          }
        </Stack>
      </div>
    </div>
  );

  function passwordResetSuccess() {
    return (
      <div>
        <Text>Password successfully reset!</Text>
        <Spacer vertical={16} />
        <div className={styles.centered}>
          <Button
            onClick={() => { history.push('/') }}
          >
            Go to Login
          </Button>
        </div>
      </div>
    );
  }

  function passwordResetForm() {
    return (
      <form
        onSubmit={(e) => e.preventDefault()}
        className={styles.formContainer}
      >
        <Text>Enter your new password. Must be a minimum of 8 characters</Text>
        <Spacer vertical={32} />

        <Stack gap={16} align={'stretch'}>
          <Input
            onChange={setPassword}
            value={passwords.password}
            placeholder={'Enter password'}
            type={'password'}
            alignText={'center'}
            error={ passwords.password.length > 0 && !validLength ? 'Too few characters' : undefined }
          />

          <StrengthMeter
            value={strength}
            max={4}
            showLabel={!!passwords.password}
          />

          <Input
            onChange={setConfirmPassword}
            value={passwords.confirmPassword}
            placeholder={'Confirm password'}
            type={'password'}
            alignText={'center'}
            error={ passwords.confirmPassword.length > 0 && !match ? 'Passwords don\'t match' : undefined }
          />
        </Stack>

        <Spacer vertical={32} />

        <div className={styles.centered}>
          <Button
            disabled={!valid || disabled}
            onClick={() => resetPassword()}
          >
            Reset my password!
          </Button>
        </div>

        {errorMessage.length !== 0 && (
          <div className={styles.centered}>
            <h4>{errorMessage}</h4>
          </div>
        )}
      </form>
    );
  }

  async function resetPassword() {
    const payload = {
      email,
      token,
      password: passwords.password,
      password_confirmation: passwords.confirmPassword,
    };

    try {
      setDisabled(true);
      await userStore.resetPassword(payload);
      setSuccess(true);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setDisabled(false);
    }
  }
}

export default inject('userStore')(observer(PasswordResetScreen));
