import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { Goal } from '../../../types';
import { ICommentStore, ILoadingStore } from '../../../stores';
import { FETCH_COMMENTS } from '../../../stores/commentStore';
import {
  Text,
  Color,
  Spacer,
  CommentsList,
} from '../../';

import styles from './CommentsListContainer.module.css';

type Props = {
  goal: Goal;
  commentStore?: ICommentStore;
  loadingStore?: ILoadingStore;
};

function _CommentsList(props: Props) {
  const commentStore = props.commentStore!;
  const loadingStore = props.loadingStore!;

  const { goal } = props;

  const goalComments = commentStore.goalComments.get(goal.id) || [];

  useEffect(() => {
    commentStore.fetchComments(goal.id);
  }, [goal.id, commentStore]);

  if (loadingStore.isLoading(FETCH_COMMENTS, { goalId: goal.id })) {
    // not ideal, I know
    return <div className={styles.comments}>Loading comments...</div>;
  }

  return (
    <div className={styles.comments}>
      <Text color={Color.GREY}>Current period activity</Text>{' '}
      <Spacer vertical={24} />
      <CommentsList
        canComment={true}
        comments={goalComments}
        onComment={addComment}
        onDelete={deleteComment}
      />
    </div>
  );

  async function addComment(comment: string): Promise<Boolean> {
    try {
      await commentStore.addComment(goal.id, comment);
      return true;
    } catch (error) {
      // @todo add error handling
    }
    return false;
  }

  async function deleteComment(commentId: number) {
    await commentStore.removeComment(goal.id, commentId);
  }
}

export const CommentsListContainer = inject(
  'commentStore',
  'loadingStore',
)(observer(_CommentsList));
