export function formatDeadline(date: string): string {
  try {
    let formattedDate = Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      day: '2-digit',
      month: '2-digit',
      timeZone: 'UTC',
    })
      .format(new Date(date))
      .replace(/\//gi, '-');
    const [day, month, year] = formattedDate.split('-');

    return [year, day, month].join('-');
  } catch (e) {
    return '';
  }
}

export function formatEvaluationPeriodEndDate(date: string): string {
  if (!date) {
    return '';
  }

  return Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    day: '2-digit',
    month: 'long',
    timeZone: 'UTC',
  }).format(new Date(date));
}
