import { decorate, observable, action } from 'mobx';

type LoadingItem = LoadingData & {
  type: string;
};

type LoadingData = {
  [item: string]: any;
};

export interface ILoadingStore {
  loading: Array<LoadingItem>;
  isLoading(type?: string, data?: LoadingData): boolean;
  start(type: string, data?: LoadingData): void;
  end(type: string, data?: LoadingData): void;
  endAll(): void;
}

export default class LoadingStore {
  loading = Array<LoadingItem>();

  isLoading = (type?: string, data?: LoadingData) => {
    if (!type) {
      return !!this.loading.length;
    }
    return this.getIndex(type, data) > -1;
  };

  start = action((type: string, data: LoadingData = {}) => {
    this.loading.push({ type, ...data });
  });

  end = action((type: string, data: LoadingData = {}) => {
    const index = this.getIndex(type, data);
    this.loading.splice(index, 1);
  });

  endAll = action(() => {
    this.loading = [];
  });

  getIndex = (type: string, data: LoadingData = {}) => {
    return this.loading.findIndex((e) => {
      return type && e.type === type && containsObject(e, data);
    });
  };
}

function containsObject(source: LoadingItem, obj: LoadingData) {
  return Object.entries(obj).every((prop) => {
    const [key, value] = prop;
    return source[key] === value;
  });
}

decorate(LoadingStore, {
  loading: observable,

  start: action,
  end: action,
  endAll: action,
});
