import React, { useState, PropsWithChildren } from 'react';
import classNames from 'classnames/bind';

import styles from './Content.module.css';
import { useAdaptiveLayout } from '../../../utils/hooks';
import { TabButton } from '../TabButton/TabButton';
import { Inline, Spacer } from '../../layout';
import { Heading, Icon, IconEnum, Text } from '../../base';
import { High5Button } from '..';
import stores from '../../../stores';

const cx = classNames.bind(styles);

type SidebarTab = {
  title: string;
  body: React.ReactElement;
  count?: number;
};

type MainContent = {
  /** the title is used to add a tab button on mobile devices */
  title: string;
  /** the header will be displayed above the sidebar tabs on mobile devices */
  header?: React.ReactElement;
  /** the main content */
  body?: React.ReactElement;
};

type Props = MainContent & {
  /** optional tabs to be displayed in the side bar */
  tabs?: SidebarTab[];
};

const DEFAULT_TAB = '';

export function Content(props: Props) {
  const { tabs = [], ...main } = props;
  const [selectedTab, setTab] = useState(DEFAULT_TAB);
  const { isDesktop } = useAdaptiveLayout();
  const hasSidebar = tabs.length > 0;

  const style = cx({
    content: true,
    with_sidebar: hasSidebar,
  });

  return (
    <div className={style}>
      {renderAdaptiveMain()}
      {hasSidebar && renderAdaptiveSidebar()}
    </div>
  );

  function renderAdaptiveSidebar() {
    const sidebar = renderSidebar();
    if (isDesktop) {
      return sidebar;
    } else {
      return <MainContainer>{sidebar}</MainContainer>;
    }
  }

  function renderSidebar() {
    const tab_list: JSX.Element[] = tabs.map((tab) => (
      <TabButton
        key={`tab_${tab.title}`}
        onClick={() => setTab(tab.title)}
        text={tab.title}
        active={getActiveTab()?.title === tab.title}
        count={tab.count}
      />
    ));

    // on mobile, we add the main content as the first tab
    isDesktop === false &&
      tab_list.unshift(
        <TabButton
          key={`tab_${main.title}`}
          onClick={() => setTab(DEFAULT_TAB)}
          text={main.title}
          active={selectedTab === DEFAULT_TAB}
        />,
      );

    return (
      <aside className={styles.sidebar}>
        <div className={styles.sidebar_tabs}>
          {tab_list.length === 1 ? (
            <Heading>{tabs[0].title}</Heading>
          ) : (
            <Inline gap={isDesktop ? 8 : 16}>{tab_list}</Inline>
          )}
        </div>

        <div className={styles.scrollable}>{getActiveTab()?.body}</div>

        <div className={styles.high5s_wrapper}>{renderHigh5s()}</div>
      </aside>
    );
  }

  function renderAdaptiveMain() {
    const { header, body } = main;

    // main content will be displayed by default (if no other tab is selected)
    // or all the time on desktop
    const _body = !selectedTab || isDesktop ? body : null;

    const _header = (
      <header className={`${styles.main_header}`}>{header}</header>
    );

    if (isDesktop) {
      // on desktop, header and body stay (and scroll) together
      return (
        <main className={styles.main}>
          <MainContainer>
            {_header}
            {_body}
          </MainContainer>
        </main>
      );
    } else {
      // on mobile, header and body are separated by the tabs (using CSS)
      return (
        <>
          <MainContainer>{_header}</MainContainer>
          <main className={styles.main}>
            <MainContainer>{_body}</MainContainer>
          </main>
        </>
      );
    }
  }

  function getActiveTab(): SidebarTab | undefined {
    if ((!selectedTab && isDesktop) || selectedTab === tabs[0].title) {
      return tabs[0];
    }
    return tabs.find((tab) => tab.title === selectedTab);
  }

  function renderHigh5s() {
    if (!isDesktop) {
      return <High5Button onClick={openHigh5Modal} isRound={true} />;
    }

    return (
      <div className={styles.high5s_box}>
        <div className={styles.high5s_icon}>
          <Icon icon={IconEnum.HAND} size={200} color="inherit" />
        </div>
        <div className={styles.high5s_content}>
          <Text bold size={16}>
            Give someone an applause!
          </Text>

          <Spacer vertical={16} />

          <Text>
            Have you seen a job well done recently? Then, go ahead and give an applause!
            Acknowledging the hard work and achievements of others is a great way to
            keep them motivated and engaged. So, let them know how much you
            appreciate them by giving them an applause.
          </Text>

          <Spacer vertical={16} />

          <High5Button onClick={openHigh5Modal} />
        </div>
      </div>
    );
  }
}

// This makes sure that the various containers displayed in the Main area
// have a consistent styling
function MainContainer(props: PropsWithChildren<{}>) {
  return <div className={styles.main_wrapper}>{props.children}</div>;
}

function openHigh5Modal() {
  const storedEmployee = stores.employeeStore.employee;
  stores.high5Store.showModal(storedEmployee ?? undefined);
}
