import { Task } from '../../../types';
import styles from './TaskList.module.css';
import { Button, Text } from '../../base';
import { Color } from '../../styles';
import { Spacer } from '../../layout';
import React from 'react';
import { createdAtDateFormat, evaluationPeriodYearFormat } from './Task.utils';

interface Props {
  task: Task;
  onComplete: () => void;
}

export function TaskGoalSetting(props: Props) {
  const { task, onComplete } = props;
  const { created_at, employee_name, evaluation_period_end_date } = task;

  return (
    <div className={styles.task}>
      <div>
        <Text bold color={Color.GREY}>
          {createdAtDateFormat(created_at)}
        </Text>
        <Text>
          Set{' '}
          <Text inline bold wrap={false}>
            {employee_name}
          </Text>{' '}
          goals for{' '}
          <Text inline bold wrap={false}>
            {evaluationPeriodYearFormat(evaluation_period_end_date)}
          </Text>
        </Text>
      </div>
      <Spacer horizontal={16} />
      <Button role="secondary" size="small" onClick={onComplete}>
        Set
      </Button>
    </div>
  );
}
