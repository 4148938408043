import React, { useEffect } from 'react';
import {
  Wrapper,
  EmployeeHeader,
  EmployeePage,
  StandardsReviews,
} from '../../components';
import { IUserStore, IEmployeeStore, IClientStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import { SidebarLayout } from '../../components/layout/SidebarLayout/SidebarLayout';
import { OverallReviewCard } from '../../components/custom/EmployeePage/OverallReviewCard';
import { EvaluationDetailsContainer } from '../../components/custom/EmployeePage/EvaluationDetailsContainer';

type Props = {
  clientStore?: IClientStore;
  employeeStore?: IEmployeeStore;
  userStore?: IUserStore;
};

function EmployeeDashboard(props: Props) {
  const clientStore = props.clientStore!;
  const employeeStore = props.employeeStore!;
  const userStore = props.userStore!;

  const { user } = userStore;
  const { employee } = employeeStore;

  useEffect(() => {
    employeeStore.fetchEmployees(userStore.user);
  }, [
    user.id,
    employeeStore,
    userStore.user,
    clientStore.checkInCycle.id
  ]);

  return (
    <Wrapper>
      {employee ? <SidebarLayout
        header={<EmployeeHeader employee={employee} />}
        tabs={[{
          title: "Goal Management",
          body: <EmployeePage employee={employee} role={'employee'} />,
          location: "main"
        }, {
          title: "Performance Standard Reviews",
          body: <StandardsReviews employee={employee} />,
          location: "main"
        }, {
          title: "Reviews",
          body: (
            <OverallReviewCard>
              <EvaluationDetailsContainer employee={employee} role={'employee'} />
            </OverallReviewCard>
          ),
          location: "main"
        }]}
      /> : <></>}
    </Wrapper>
  );
}

export default inject(
  'clientStore',
  'employeeStore',
  'userStore',
)(observer(EmployeeDashboard));
