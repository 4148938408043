import { now } from 'lodash';
import { configure } from 'mobx';
import { api } from '../services/http';
import { Document } from '../types';
import { RootStore } from './index';

configure({ enforceActions: 'observed' });

export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';

export interface IDocumentStore {
  updateDocument(documentId: number, file: File): Promise<Document>;
  removeDocument(documentId: number): Promise<void>;
}

export const EMPTY_DOCUMENT: Document = {
  id: 0,
  filename: '',
  nicename: '',
  uri: '',
  expires_at: now().toString(),
  created_at: now().toString(),
  updated_at: now().toString(),
}

export default class DocumentStore implements IDocumentStore {
  stores: RootStore;

  constructor(stores: RootStore) {
    this.stores = stores;
  }

  async updateDocument(documentId: number, file: File): Promise<Document> {
    this.stores.loadingStore.start(UPDATE_DOCUMENT);

    try {
      const document = await updateDocument(documentId, file);
      this.stores.notificationStore.triggerSuccessNotification('Document updated successfully!')
      return document;
    }

    catch (e) {
      console.warn(e);
      this.stores.notificationStore.triggerErrorNotification('An error occurred updating document.');
    }

    finally {
      this.stores.loadingStore.end(UPDATE_DOCUMENT);
    }

    return EMPTY_DOCUMENT;
  }

  async removeDocument(documentId: number): Promise<void> {
    this.stores.loadingStore.start(REMOVE_DOCUMENT);

    try {
      await removeDocument(documentId);
      this.stores.notificationStore.triggerSuccessNotification('Document successfully removed!');
    }

    catch (e) {
      console.warn(e);
      this.stores.notificationStore.triggerErrorNotification('An error occurred removing document.');
    }

    finally {
      this.stores.loadingStore.end(REMOVE_DOCUMENT);
    }
  }
}

async function updateDocument(documentId: number, file: File): Promise<Document> {
  const formData = new FormData();
  formData.append('document', file);
  const { data } = await api.post<Document>(`documents/${documentId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
}

async function removeDocument(documentId: number): Promise<void> {
  await api.delete(`documents/${documentId}`);
}
