import React, { useState } from 'react';
import styles from './ToggleableCard.module.css';
import { useAdaptiveLayout } from '../../../utils/hooks';

import { Heading, Spacer, Icon, IconEnum } from '../..';
import { Space } from '../../layout';

interface Props {
  title: string|React.ReactNode;
  /** details to be displayed when toggling the Goal */
  children?: React.ReactNode;
  /** extra content to be displayed */
  extra?: React.ReactNode;
  // style?: React.CSSProperties;
  opened?: boolean;
  spacing?: Space;
}

export function ToggleableCard(props: Props) {
  const { children, title, extra, opened = false, spacing } = props;

  const [isOpen, setIsOpen] = useState(opened);
  const { isDesktop } = useAdaptiveLayout();

  const card_styling = {
    '--spacing': `${getSpacing(spacing)}px`,
  } as React.CSSProperties;

  return (
    <div className={styles.card}>
      <div
        className={`${styles.summary} ${isOpen && styles.summary_expanded}`}
        style={card_styling}>
        {renderHeader()}
        {extra && <div className={styles.extra}>{extra}</div>}
      </div>

      {isOpen && children}
    </div>
  );

  function renderHeader() {
    const hasDetails = !!children;

    return (
      <header className={styles.header}>
        <div>
          {hasDetails ? (
            <button
              className={`${styles.title} ${styles.title_button}`}
              onClick={toggleDetails}>
              {renderTitle()}
            </button>
          ) : (
            <div className={styles.title}>{renderTitle()}</div>
          )}
        </div>

        <Spacer horizontal={16} />

        {children && (
          <button
            className={`${styles.toggle} ${isOpen && styles.toggle_open}`}
            onClick={toggleDetails}
            tabIndex={-1}>
            <Icon icon={IconEnum.CHEVRON_DOWN} size={isDesktop ? 20 : 16} />
          </button>
        )}
      </header>
    );
  }

  function renderTitle() {
    return <Heading level={3}>{title}</Heading>;
  }

  function getSpacing(spacing?: Space) {
    if (typeof spacing !== 'undefined') {
      return spacing;
    }
    return isDesktop ? 24 : 16;
  }

  function toggleDetails() {
    setIsOpen(!isOpen);
  }
}
