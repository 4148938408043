import React, { useMemo, useState } from 'react';
import styles from './CreateUserScreen.module.css';
import { inject, observer } from 'mobx-react';
import { IClientStore, IEmployeeStore, IUserStore } from '../../stores';

import {
  Button,
  Card,
  Checkbox,
  Color,
  ConfirmationModal,
  Content,
  Heading,
  Icon,
  IconEnum,
  Input,
  MultiSelect,
  Option,
  ReassignUsersModal,
  Select,
  Spacer,
  Text,
  Wrapper,
} from '../../components';
import { User } from '../../types';
import { UserEdit } from '../../stores/clientStore';
import { RouteComponentProps, useHistory } from 'react-router';
import { EMPTY_USER } from '../../stores/userStore';

interface RouteParams {
  user_id: string;
};

type RouteProps = RouteComponentProps<RouteParams>;

type Props = RouteProps & {
  clientStore: IClientStore;
  employeeStore: IEmployeeStore;
  userStore: IUserStore;
}

function EditUserScreen(props: Props) {
  const clientStore: IClientStore = props.clientStore!;
  const employeeStore: IEmployeeStore = props.employeeStore!;
  const userStore: IUserStore = props.userStore!;
  const userId: number = Number(props.match.params.user_id);
  const history = useHistory();

  const user: User = useMemo(() => clientStore.users
    .find(u => u.id === userId)
    || EMPTY_USER,
    [clientStore.users, userId]
  );

  const managers: Array<User> = useMemo(() => clientStore.users
    .filter(user => (
        user.id !== userId &&
        clientStore.users.some(u => u.manager_id === user.id)
      ) || user.manager_id === null
    ),
    [clientStore.users, userId]
  );

  const [name, setName] = useState<string>(user.name);
  const [email, setEmail] = useState<string>(user.email);
  const [password, setPassword] = useState<string>('');
  const [manager, setManager] = useState<number|null>(user.manager_id);
  const [isManager, setIsManager] = useState<boolean>(
    clientStore.users.some(u => u.manager_id === user.id)
    || user.manager_id === null
  );
  const [employees, setEmployees] = useState<Array<Option<number>>>(
    clientStore.users
      .sort((a, b) => a.name < b.name
        ? -1
        : b.name < a.name
          ? 1
          : 0
      )
      .filter(u => u.id !== userId)
      .map(user => ({
        label: user.name,
        value: user.id,
        selected: user.manager_id === userId,
      }))
  );

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isReassignVisible, setIsReassignVisible] = useState<boolean>(false);

  const isDisabled = useMemo(
    () => {
      return name.length === 0 || email.length === 0
    },
    [name, email]
  );

  return (
    <Wrapper>
      <Content
        title="Edit User"
        header={
          <>
            <a href="/users">
              <Icon
                icon={IconEnum.CHEVRON_LEFT}
                size={8}
                color={Color.GREY}
              />
              <Spacer horizontal={4} />
              Back
            </a>
            <Spacer vertical={8} />
            <Heading level={1}>Edit User</Heading>
          </>
        }
        body={renderBody()}
      />
    </Wrapper>
  );

  function renderBody() {
    return (
      <Card className={styles.users}>
        <div className={styles.form}>
          { renderName() }

          { renderEmail() }

          { renderPassword() }

          { renderManager() }

          { renderIsManager() }

          {isManager && renderEmployees()}
        </div>

        <div className={styles.actions}>
          <Button
            onClick={editUser}
            disabled={isDisabled}
          >
            Update
          </Button>

          <Button
            onClick={() => checkCanDelete()}
            role="secondary"
          >
            Delete
          </Button>
        </div>

        <ConfirmationModal
          message={`Are you sure you want to delete ${user.name}?`}
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          onConfirm={() => deleteUser()}
        />

        <ReassignUsersModal
          isVisible={isReassignVisible}
          users={employees
            .filter(user => user.selected)
            .map(user => clientStore.users
              .find(u => u.id === user.value)!
            )}
          managers={managers}
          user={user}
          onClose={() => setIsReassignVisible(false)}
          onSend={() => deleteUser()}
        />
      </Card>
    );
  }

  function renderName()
  {
    return (
      <label>
        <Input
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Name"
        ></Input>
      </label>
    );
  }

  function renderEmail()
  {
    return (
      <label>
        <Input
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Email"
        ></Input>
      </label>
    );
  }

  function renderPassword()
  {
    return (
      <label>
        <Input
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="Password"
        ></Input>
      </label>
    );
  }

  function renderManager()
  {
    return (
      <label className={styles.managerInput}>
        <Select
          defaultValue={manager === null ? '' : manager.toString()}
          values={managers.map(manager => ({
            value: manager.id.toString(),
            label: manager.name,
          }))}
          onChange={e => setManager(Number(e.target.value))}
          placeholder="Select a Manager"
        />
      </label>
    );
  }

  function renderIsManager()
  {
    return (
      <label>
        <Text inline>Is a manager</Text>
        <Checkbox
          checked={isManager}
          onChange={e=>setIsManager(e.target.checked)}
        />
      </label>
    );
  }

  function renderEmployees()
  {
    return (
      <div className={styles.employees}>
        <MultiSelect
          label="Employees"
          onChange={values => setEmployees(values)}
          values={employees}
        />

        { renderManaging() }
      </div>
    );
  }

  function renderManaging()
  {
    function removeAll()
    {
      const selected = false;
      setEmployees(employees.map(user => ({ ...user, selected })))
    }

    return (
      <div className={styles.managing}>
        <div className={styles.managingHeader}>
          <Text>Managing</Text>

          {employees.some(user => user.selected) && <button
            type="button"
            title="Remove all employees"
            onClick={removeAll}
          >
            Remove All
            <Icon
              icon={IconEnum.DELETE}
              size={12}
              color={Color.PRIMARY}
            />
          </button>}
        </div>

        <div className={styles.managingBody}>
          {employees.map(employee => employee.selected && (
            renderEmployee(employee)
          ))}
        </div>
      </div>
    );
  }

  function renderEmployee(employee: Option<number>)
  {
    function removeEmployee()
    {
      const selected = false;
      setEmployees(employees.map(user =>
        user.value === employee.value
          ? { ...user, selected }
          : user
      ));
    }

    return (
      <div
        className={styles.managingEmployee}
        key={employee.value}
      >
        <button
          type="button"
          title="Remove Employee"
          onClick={removeEmployee}
        >
          <Icon
            icon={IconEnum.CLOSE}
            size={10}
            color={Color.PRIMARY}
          />
        </button>
        { employee.label }
      </div>
    );
  }

  function checkCanDelete()
  {
    if (employees.some(user => user.selected))
    {
      setIsReassignVisible(true);
    }

    else {
      setIsVisible(true);
    }
  }

  async function editUser()
  {
    const userEdit: UserEdit = {
      client_id: 0,
      email,
      manager_id: manager,
      name,
    };

    const user = await clientStore.editUser(userId, userEdit);

    if (user.id && password !== '')
    {
      await userStore.updatePassword(password, user.id);
    }

    if (user.id && isManager)
    {
      for (let i = 0; i < employees.length; i++) {
        const employee = employees[i];
        const original = clientStore.users.find(u => u.id === employee.value)!;

        if (employee.selected && original.manager_id !== user.id)
        {
          await employeeStore.setManager(employee.value, user.id);
        }

        if (!employee.selected && original.manager_id === user.id)
        {
          await employeeStore.setManager(employee.value, null);
        }
      }
    }

    if (user.id)
    {
      back();
    }
  }

  async function deleteUser()
  {
    await clientStore.deleteUser(userId);
    back();
  }

  async function back()
  {
    await clientStore.fetchUsers();
    history.push('/users');
  }
}

export default inject(
  'clientStore',
  'employeeStore',
  'userStore'
)(observer(EditUserScreen));
