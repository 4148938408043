import styles from './TaskList.module.css';
import { Divider, Inline, Spacer, Stack } from '../../layout';
import { Dropdown } from '..';
import React, { useEffect, useState } from 'react';
import { Task, TaskType } from '../../../types';
import { TaskGeneralEvaluation } from './TaskGeneralEvaluation';
import { TaskGoalSetting } from './TaskGoalSetting';
import { inject, observer } from 'mobx-react';
import { ITaskStore } from '../../../stores/taskStore';
import { TaskPeriodicReview } from './TaskPeriodicReview';
import { TaskMeetingRequest } from './TaskMeetingRequest';
import { TaskEmployeeDraft } from './TaskEmployeeDraft';
import { useHistory } from 'react-router-dom';
import { TaskPerformanceReview } from './TaskPerformanceReview';
import { TaskGoalReview } from './TaskGoalReview';

interface Props {
  taskStore?: ITaskStore;
}

function TaskListNoStore(props: Props) {
  const taskStore = props.taskStore!;
  const history = useHistory();
  const [filterByEmployeeId, setFilterByEmployeeName] = useState<number>(0);
  const [filteredByTaskType, setFilterByTaskType] = useState<string>('');

  useEffect(() => {
    taskStore.fetchTasks();
  }, [taskStore]);

  function renderTask(task: Task) {
    switch (task.type) {
      case TaskType.GENERAL_EVALUATION:
        return (
          <TaskGeneralEvaluation
            key={task.id}
            task={task}
            onComplete={() =>
              history.push(`/employee/${task.employee_id}?tab=Reviews&review=true`)
            }
          />
        );
      case TaskType.GOAL_SETTING:
        return (
          <TaskGoalSetting
            task={task}
            key={task.id}
            onComplete={() =>
              history.push(`/employee/${task.employee_id}/goal/new?evaluation-period=${task.client_evaluation_period_id}`)
            }
          />
        );
      case TaskType.PERIODIC_REVIEW:
        return (
          <TaskPeriodicReview
            task={task}
            key={task.id}
            onComplete={() =>
              history.push(`/employee/${task.employee_id}?tab=Reviews&review=true`)
            }
          />
        );
      case TaskType.MEETING_REQUEST:
        return (
          <TaskMeetingRequest
            task={task}
            key={task.id}
            onComplete={() => {
              taskStore.completeTask(task.id);
            }}
          />
        );
      case TaskType.EMPLOYEE_DRAFT:
        return (
          <TaskEmployeeDraft
            task={task}
            key={task.id}
            onComplete={() => history.push(`/goal/${task.goal_id}/edit`)}
          />
        );
      case TaskType.PERFORMANCE_REVIEW:
        return (
          <TaskPerformanceReview
            task={task}
            key={task.id}
            onComplete={() => history.push(`/employee/${task.employee_id}/?tab=Performance Standard Reviews`)}
          />
        );
      case TaskType.GOAL_REVIEW:
        return (
          <TaskGoalReview
            task={task}
            key={task.id}
            onComplete={() => history.push(`/employee/${task.employee_id}/?tab=Goal Management&goal=${task.goal_id}`)}
          />
        );
      default:
        console.warn('Task type unknown: ', task.type);
    }
  }

  // because we have mixed types for values, having included the default option also
  // we cannot strongly type the values
  // clear selection + placeholder can fix this issue
  // https://github.com/e-spres-oh/impacthr-ui/issues/56
  const employeeOptions = [
    { value: 0, label: 'All employees' },
    ...getUniqueEmployees(taskStore.tasks),
  ];
  const taskTypeOptions = [
    { value: '', label: 'All tasks types' },
    ...taskTypesDropDownValues(),
  ];

  return (
    <div className={styles.tasks}>
      <div className={styles.tasks_header}>
        <Divider />
        <Spacer vertical={16} />

        <div className={styles.tasks_container}>
          <Inline gap={16}>
            <Dropdown
              // placeholder="All employees"
              value={filterByEmployeeId}
              options={employeeOptions}
              onChange={(value) => setFilterByEmployeeName(value)}
            />
            <Dropdown
              // placeholder="All tasks types"
              value={filteredByTaskType}
              options={taskTypeOptions}
              onChange={(value) => setFilterByTaskType(value)}
            />
          </Inline>
        </div>

        <Spacer vertical={16} />
      </div>

      <div className={`${styles.tasks_container} ${styles.tasks_list}`}>
        <Stack gap={32}>
          {filterTasks().length
            ? filterTasks().map((task: Task) => renderTask(task))
            : <p>Nothing to do <span role="img" aria-label="Party Popper">🎉</span></p>
          }
        </Stack>
        <Spacer vertical={16} />
      </div>
    </div>
  );

  function filterTasks() {
    return taskStore.tasks
      .filter((task) =>
        filterByEmployeeId ? task.employee_id === filterByEmployeeId : true,
      )
      .filter((task) =>
        filteredByTaskType ? task.type === filteredByTaskType : true,
      );
  }
}

function taskTypesDropDownValues() {
  return Object.values(TaskType).map((type) => {
    const label = type.replace(/_/g, ' ');
    return {
      label: label[0].toUpperCase() + label.substr(1),
      value: type,
    };
  });
}

function getUniqueEmployees(tasks: Task[]) {
  const options = tasks.map((task) => ({
    value: task.employee_id,
    label: task.employee_name,
  }));

  const ids = new Set<number>();

  return options.filter((option) => {
    if (!ids.has(option.value)) {
      ids.add(option.value);
      return true;
    }
    return false;
  });
}

export const TaskList = inject('taskStore')(observer(TaskListNoStore));
