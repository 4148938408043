import React, { ChangeEvent, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './PIPModal.module.css';
import {
  Button,
  File,
  Heading,
  Modal,
  Spacer,
  Text
} from '../../';
import { User } from '../../../types';
import { IEmployeeStore } from '../../../stores';

interface Props {
  employeeStore?: IEmployeeStore;
  employee?: User;
  isVisible: boolean;
  onClose: () => void;
}

function PIPModalWithoutStore(props: Props) {
  const { employee, isVisible, onClose } = props;
  const employeeStore = props.employeeStore!;
  const [selectedEmployee, setSelectedEmployee] = useState<User | null>(null);
  const [file, setFile] = useState<File|undefined>(undefined);

  useEffect(() => { setSelectedEmployee(employee || null) }, [employee]);

  return (
    <Modal isOpen={isVisible} onClose={closeModal}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Heading level={2}>Request PIP for {selectedEmployee?.name}</Heading>
        </div>

        <div className={styles.body}>
          <Text>Add an optional document</Text>

          <Spacer vertical={4} />

          <File
            onChange={parseFiles}
            accept='.doc,.docx,.pdf'
          />
          <Spacer vertical={8} />
          <Text size={11}>(Must be one of these types: .doc, .docx, .pdf, .txt, .xls, .xlsx)</Text>
        </div>

        <div className={styles.footer}>
          <Button
            role='primary'
            onClick={submit}
          >Submit</Button>
        </div>
      </div>
    </Modal>
  );

  function closeModal() {
    onClose();
  }

  async function submit() {
    await employeeStore.addEmployeeToPIP(selectedEmployee!.id, file);
    closeModal();
  }

  function parseFiles(e: ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;

    if (files?.length) {
      setFile(files[0]);
    }
  }
}

export const PIPModal = inject('employeeStore')(observer(PIPModalWithoutStore));
