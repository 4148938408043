import React from 'react';
import styles from './ConfirmationModal.module.css';
import {
  Button,
  Text,
  Spacer,
  Color,
  Modal,
} from '../..';

interface Props {
  message?: string;
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export function ConfirmationModal(props: Props) {
  const {
    message,
    isVisible,
    onClose,
    onConfirm
  } = props;

  return (
    <Modal isOpen={isVisible} onClose={onClose}>
      <div className={styles.container}>
        <Text size={14} bold color={Color.GREY}>
          { message || 'Are You Sure?' }
        </Text>

        <Spacer vertical={32} />

        <div className={styles.actions}>
          <Button role="primary" onClick={onConfirm}>
            Yes
          </Button>

          <Button role="secondary" onClick={onClose}>
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
}
