import { ObservableMap } from "mobx";
import { CheckInCycle, ClientStandardsReview } from "../../../types";

export function filterReviews(reviews: ObservableMap<number, ClientStandardsReview>, employeeId: number, evaluationPeriodId: number): ClientStandardsReview[] {
  return Array.from(reviews.values())
    .filter(({ user, period }) =>
      user.id === employeeId && (period as CheckInCycle).evaluation_period_id === evaluationPeriodId
    );
}

export function filterCompleted(reviews: ClientStandardsReview[]): ClientStandardsReview[] {
  return reviews.filter((review) => review.completed_at !== null);
}
