import { RootStore } from './index';
import {
  decorate,
  action,
  runInAction,
  observable,
  configure,
  computed,
} from 'mobx';
import { Activity, Document, Employee, PIP, User } from '../types';
import { api } from '../services/http';
import { getRole } from '../services/AuthRepository';
import { EMPTY_USER } from './userStore';

configure({ enforceActions: 'observed' });

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const FETCH_ACTIVITY = 'FETCH_ACTIVITY';
export const FETCH_EMPLOYEE = 'FETCH_EMPLOYEE';
export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES';
export const FETCH_EMPLOYEES_BY_MANAGER_ID = 'FETCH_EMPLOYEES_BY_MANAGER_ID';
export const FETCH_PIPS = 'FETCH_PIPS';
export const ADD_DOCUMENT_TO_PIP = 'ADD_DOCUMENT_TO_PIP';

export const EMPTY_EMPLOYEE: Employee = {
  id: 0,
  manager_id: 0,
  client_id: 0,
  name: '',
  email: '',
  roles: [],
  notification_enabled: false,
  on_pip: false,
  goals_count: 0,
  high_fives_count: 0,
  employees_count: 0,
  review_window: false,
  performance_review_window: false,
};

export const EMPTY_PIP: PIP = {
  id: '0',
  user_id: '',
  author_id: '',
  type: 'added',
  created_at: '',
  updated_at: '',
  author: EMPTY_USER,
  documents: [],
}

export interface IEmployeeStore {
  selectedEmployeeId: number;
  employee?: Employee;
  employees: Employee[];
  employeePIPs: PIP[];
  isRequestMeetingModalOpen: boolean;

  setManager(employeeId: number, managerId: number | null): Promise<void>;
  addEmployeeToPIP(employeeId: number, file?: File): Promise<void>;
  fetchActivity(employeeId: number): Promise<Activity|undefined>;
  fetchEmployee(userId: number): Promise<Employee|undefined>;
  fetchEmployees(user: User): Promise<void>;
  fetchEmployeesByManagerId(managerId: number): Promise<void>;
  fetchPIPs(employeeId: number): void;
  increaseHighFiveCount(employeeId: number): void;
  removeEmployeeFromPIP(employeeId: number): Promise<void>;
  reset(): void;
  resetEmployee(): void;
  setEmployeeId(id: number): void;
  updateEmployee(employeeId: number): Promise<void>;
  addDocumentToPIP(pipId: string, file: File): Promise<void>;
  showRequestMeetingModal(): void;
  hideRequestMeetingModal(): void;
}

export default class EmployeeStore implements IEmployeeStore {
  stores: RootStore;

  selectedEmployeeId = 0;
  employees = Array<Employee>();
  employeePIPs = Array<PIP>();
  isRequestMeetingModalOpen = false;

  constructor(stores: RootStore) {
    this.stores = stores;
  }

  get employee() {
    return this.getEmployeeById(this.selectedEmployeeId);
  }

  async fetchActivity(employeeId: number): Promise<Activity|undefined> {
    this.stores.loadingStore.start(FETCH_ACTIVITY);

    const checkInCycleId = this.stores.clientStore.checkInCycle.id

    if (!checkInCycleId) return undefined;

    try {
      return getActivity(employeeId, checkInCycleId);
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_ACTIVITY);
    }
  }

  async fetchEmployee(userId: number): Promise<Employee|undefined> {
    this.stores.loadingStore.start(FETCH_EMPLOYEE);

    try {
      const checkInCycleId = this.stores.clientStore.checkInCycle.id

      if (!checkInCycleId)
        throw new Error("Check in cycle not found.");

      return getEmployee(userId, checkInCycleId);
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_EMPLOYEE);
    }
  }

  async fetchEmployees(user: User)
  {
    this.stores.loadingStore.start(FETCH_EMPLOYEES);

    try
    {
      const employees = await this.hydrateEmployees(user);

      runInAction(() => {
        this.employees = employees;
      });
    }

    catch (error)
    {
      console.warn(error);
    }

    finally
    {
      this.stores.loadingStore.end(FETCH_EMPLOYEES);
    }
  }

  async fetchEmployeesByManagerId(managerId: number): Promise<void>
  {
    this.stores.loadingStore.start(FETCH_EMPLOYEES_BY_MANAGER_ID);

    try
    {
      const employees = await getEmployeesByManagerId(managerId);

      runInAction(() => {
        this.employees = employees;
      });
    }

    catch (error)
    {
      console.warn(error);
    }

    finally
    {
      this.stores.loadingStore.end(FETCH_EMPLOYEES_BY_MANAGER_ID);
    }
  }

  async hydrateEmployees(user: User)
  {
    const checkInCycleId = this.stores.clientStore.checkInCycle.id
    let employees: Array<Employee> = [];

    if (!checkInCycleId) return [];

    if (getRole() === 'admin')
    {
      employees = await getManagers(checkInCycleId);
      this.resetEmployee();
    }

    else if (getRole() === 'manager')
    {
      employees = await getEmployees(checkInCycleId);
      this.resetEmployee();
    }

    else
    {
      employees.push(await getEmployee(user.id, checkInCycleId));
      this.setEmployeeId(user.id);
    }

    return employees;
  }

  increaseHighFiveCount(employeeId: number) {
    const employee = this.getEmployeeById(employeeId);
    if (employee) {
      employee.high_fives_count++;
    }
  }

  setEmployeeId(id: number) {
    this.selectedEmployeeId = id;
  }

  getEmployeeById(employeeId: number) {
    return this.employees.find((employee) => employee.id === employeeId);
  }

  async updateEmployee(employeeId: number) {
    try {
      const checkInCycleId = this.stores.clientStore.checkInCycle.id;
      const updatedEmployee = await getEmployee(employeeId, checkInCycleId);

      runInAction(() => {
        this.employees = this.employees.map((item) => {
          return item.id === employeeId
            ? { ...item, ...updatedEmployee }
            : item;
        });
      });
    } catch (error) {
      console.warn(error);
    }
  }

  async fetchPIPs(employeeId: number) {
    this.stores.loadingStore.start(FETCH_PIPS);

    const check_in_cycle = this.stores.clientStore.checkInCycle.id;

    try {
      const employeePIPs = await getPIPs(employeeId, check_in_cycle);

      runInAction(() => {
        this.employeePIPs = employeePIPs;
      });
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_PIPS);
    }
  }

  async setManager(employeeId: number, managerId: number | null)
  {
    this.stores.loadingStore.start(ADD_EMPLOYEE);

    try
    {
      await setManager(employeeId, managerId);
    }

    catch (error)
    {
      console.warn(error);
    }

    finally
    {
      this.stores.loadingStore.end(ADD_EMPLOYEE);
    }
  }

  async addEmployeeToPIP(employeeId: number, file?: File) {
    try {
      const updatedEmployee = await addToPIP(employeeId);

      await this.fetchPIPs(employeeId);

      const pip = this.employeePIPs[0];

      if (file) {
        await uploadPIPFile(pip.id, file);
      }

      this.stores.notificationStore.triggerSuccessNotification(
        'PIP successfully requested!',
      );

      const employees = [...this.employees];

      const employeeIndex = employees.findIndex(({ id }) => id === employeeId);

      let employee = employees[employeeIndex];

      employee = { ...employee, ...updatedEmployee };

      employees[employeeIndex] = employee;

      runInAction(() => {
        this.employees = employees;
      });
    } catch (error) {
      console.warn(error);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
    }
  }

  async addDocumentToPIP(pipId: string, file: File): Promise<void> {
    this.stores.loadingStore.start(ADD_DOCUMENT_TO_PIP);

    try {
      await uploadPIPFile(pipId, file);
      this.stores.notificationStore.triggerSuccessNotification('Document successfully added!');
    }

    catch (error) {
      console.warn(error);
      this.stores.notificationStore.triggerErrorNotification('An error occurred adding document.');
    }

    finally {
      this.stores.loadingStore.end(ADD_DOCUMENT_TO_PIP);
    }
  }

  async removeEmployeeFromPIP(employeeId: number) {
    try {
      const updatedEmployee = await removeFromPIP(employeeId);
      this.stores.employeeStore.fetchPIPs(employeeId);

      this.stores.notificationStore.triggerSuccessNotification(
        'PIP successfully removed!',
      );

      runInAction(() => {
        this.employees = this.employees.map((item) => {
          return item.id === employeeId
            ? { ...item, ...updatedEmployee }
            : item;
        });
      });
    } catch (error) {
      console.warn(error);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
    }
  }

  resetEmployee()
  {
    this.selectedEmployeeId = 0;
  }

  resetCheckInReview() {
    runInAction(() => {
      if (this.employee)
        this.employee.review_window = false;
    })
  }

  reset() {
    this.resetEmployee();
    this.employees = [];
    this.employeePIPs = [];
  }

  showRequestMeetingModal(): void {
    this.isRequestMeetingModalOpen = true;
  }

  hideRequestMeetingModal(): void {
    this.isRequestMeetingModalOpen = false;
  }
}

decorate(EmployeeStore, {
  employee: computed,
  employees: observable,
  employeePIPs: observable,
  selectedEmployeeId: observable,
  isRequestMeetingModalOpen: observable,

  setEmployeeId: action,
  reset: action,
  resetEmployee: action,
  fetchEmployees: action,
  updateEmployee: action,
  increaseHighFiveCount: action,
  fetchPIPs: action,
  addEmployeeToPIP: action,
  removeEmployeeFromPIP: action,
  resetCheckInReview: action,
  showRequestMeetingModal: action,
  hideRequestMeetingModal: action,
});

async function getActivity(employeeId: number, check_in_cycle: number): Promise<Activity> {
  const { data } = await api.get(`employees/${employeeId}/activity`, {
    params: { check_in_cycle },
  });

  return data.data;
}

async function getEmployee(id: number, check_in_cycle: number): Promise<EmployeeResponse> {
  const { data } = await api.get(`employees/${id}`, {
    params: { check_in_cycle }
  });
  return data.data;
}

async function getEmployees(check_in_cycle: number): Promise<EmployeesResponse> {
  const { data } = await api.get('employees', {
    params: { check_in_cycle }
  });
  return data.data;
}

async function getManagers(check_in_cycle: number): Promise<EmployeesResponse> {
  const response = await api.get('managers', {
    params: { check_in_cycle }
  });
  return response.data;
}

async function getEmployeesByManagerId(manager_id: number): Promise<EmployeesResponse> {
  const response = await api.get(`managers/${manager_id}/employees`);
  return response.data;
}

async function getPIPs(id: number, check_in_cycle: number): Promise<PIPsResponse> {
  const response = await api.get(`employees/${id}/pips`, {
    params: { check_in_cycle },
  });
  return response.data;
}

async function addToPIP(id: number): Promise<EmployeeResponse> {
  const response = await api.post(`employees/${id}/pip`);
  return response.data;
}

async function removeFromPIP(id: number): Promise<EmployeeResponse> {
  const response = await api.delete(`employees/${id}/pip`);
  return response.data;
}

async function uploadPIPFile(id: string, file: File): Promise<Document> {
  const formData = new FormData();
  formData.append('document', file);
  const { data } = await api.post(`pips/${id}/files`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
}

async function setManager(employee_id: number, manager_id: number | null)
{
  const { data } = await api.post(`employees/${employee_id}/manager`, { manager_id });
  return data;
}

type EmployeesResponse = Array<Employee>;
type EmployeeResponse = Employee;
type PIPsResponse = Array<PIP>;
