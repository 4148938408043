import React from 'react';
import styles from './Radio.module.css';

interface Props {
  name?: string;
  value: string;
  checked: boolean;
  onChange: () => void;
}

export function Radio(props: Props) {
  const { name, value, checked, onChange } = props;

  return (
    <input
      onChange={onChange}
      className={styles.radio}
      type="radio"
      name={name}
      value={value}
      checked={checked}
    />
  );
}
