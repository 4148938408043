import React from 'react';
import styles from './Spacer.module.css';

import { SpaceValues } from '../../styles';
export type Space = typeof SpaceValues[number];

interface Props {
  horizontal?: Space;
  vertical?: Space;
}

export function Spacer(props: Props) {
  const { horizontal = 0, vertical = 0 } = props;

  return (
    <span
      style={{
        width: horizontal,
        minWidth: horizontal,
        height: vertical,
        display: vertical !== 0 ? 'block' : 'inline-block',
      }}
      className={styles.spacer}
    />
  );
}

Spacer.defaultProps = {
  horizontal: 0,
  vertical: 0,
};
