import React, { useEffect } from 'react';
import styles from './NotificationCard.module.css';

import { Notification } from '../../../types/index';
import { getBackgroundColor, getIcon } from './Notification.utils';

import { Card, Icon, IconEnum, Text, Inline } from '../../';

const TIMEOUT = 4000;

interface Props {
  notification: Notification;
  onRemove: (id: number) => void;
}

export function NotificationCard(props: Props) {
  const { notification, onRemove } = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      onRemove(notification.id);
    }, TIMEOUT);

    return () => {
      clearTimeout(timer);
    };
  }, [notification.id, onRemove]);

  return (
    <Card
      className={styles.notification}
      background={getBackgroundColor(notification.type)}>
      <button
        className={styles.close_btn}
        onClick={() => onRemove(notification.id)}>
        <Icon icon={IconEnum.CLOSE} size={12} />
      </button>
      <Inline gap={16}>
        <Icon icon={getIcon(notification.type)} size={24} />
        <Text inline bold size={14} lineHeight={1} align="center">
          {notification.message}
        </Text>
      </Inline>
    </Card>
  );
}
