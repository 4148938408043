import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { IReviewStore, ICommentStore, IClientStore } from '../../../stores';
import { Goal, Review as ReviewType, UserRole } from '../../../types';
import { Stack, CommentCard, Spacer, Review } from '../../';

import styles from './ReviewContainer.module.css';

type Props = {
  goal: Goal;
  role: UserRole;
  review: ReviewType;
  commentStore?: ICommentStore;
  reviewStore?: IReviewStore;
  clientStore?: IClientStore;
};

export function ReviewContainerWithoutStore(props: Props) {
  const commentStore = props.commentStore!;
  const reviewStore = props.reviewStore!;
  const clientStore = props.clientStore!;

  const { goal, review, role } = props;

  const [showComments, toggleShowComments] = useState(false);

  useEffect(() => {
    !showComments && commentStore.fetchReviewComments(review.id);
  }, [showComments, commentStore, review.id]);

  const reviewComments = commentStore.reviewComments.get(review.id) || [];

  return (
    <div className={styles.review}>
      <Review
        review={review}
        onEdit={updateReview}
        readOnly={role === 'employee'}
        evaluationPeriod={clientStore.evaluationPeriod.label}>
        {renderComments()}
      </Review>
    </div>
  );

  function renderComments() {
    return (
      <>
        {showComments && (
          <>
            <Spacer vertical={24} />
            <Stack gap={16}>
              {reviewComments.map((comment) => (
                <CommentCard key={comment.id} comment={comment} />
              ))}
            </Stack>
          </>
        )}
        <Spacer vertical={16} />
        <button
          className={styles.toggle_activity_btn}
          onClick={() => {
            toggleShowComments(!showComments);
          }}>
          {showComments ? 'Hide' : 'View'} All Activity
        </button>
      </>
    );
  }

  function updateReview(reviewId: number, review: string) {
    reviewStore.editGoalReview(goal.id, reviewId, review);
  }
}

export const ReviewContainer = inject(
  'commentStore',
  'reviewStore',
  'clientStore',
)(observer(ReviewContainerWithoutStore));
