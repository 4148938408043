import {
  configure,
  ObservableMap,
  decorate,
  observable,
  action,
  runInAction,
  computed,
} from 'mobx';
import stores, { RootStore } from './index';
import { CheckInCycle, Review } from '../types';
import { api } from '../services/http';

configure({ enforceActions: 'observed' });

export const FETCH_GOAL_REVIEWS = 'FETCH_GOAL_REVIEWS';
export const FETCH_EMPLOYEE_REVIEWS = 'FETCH_EMPLOYEE_REVIEWS';
export const ADD_GOAL_REVIEW = 'ADD_GOAL_REVIEW';
export const ADD_EMPLOYEE_REVIEW = 'ADD_EMPLOYEE_REVIEW';
export const EDIT_REVIEW = 'EDIT_REVIEW';

const EMPTY_GOAL_REVIEWS = new ObservableMap<number, Review[]>();
const EMPTY_EMPLOYEE_REVIEWS = new Array<Review>();

export interface IReviewStore {
  goalReviews: ObservableMap<number, Review[]>;
  employeeReviews: Array<Review>;

  employeeCheckInReviews: Array<Review>;
  employeeFinalReview?: Review;

  fetchGoalReviews(goalId: number): Promise<void>;
  fetchEmployeeReviews(employeeId: number): Promise<void>;
  addGoalReview(goalId: number, review: ReviewPayload): Promise<boolean>;
  addEmployeeReview(employeeId: number, review: ReviewPayload): Promise<void>;
  editGoalReview(goalId: number, reviewId: number, text: string): Promise<void>;
  editEmployeeReview(reviewId: number, text: string): Promise<void>;
  reset(): void;
}

export default class ReviewStore implements IReviewStore {
  stores: RootStore;

  goalReviews = EMPTY_GOAL_REVIEWS;
  employeeReviews = EMPTY_EMPLOYEE_REVIEWS;

  constructor(stores: RootStore) {
    this.stores = stores;
  }

  get employeeCheckInReviews() {
    return this.employeeReviews.filter(
      (review) => !!(review.period as CheckInCycle).evaluation_period_id,
    );
  }

  get employeeFinalReview() {
    return this.employeeReviews.find(
      (review) => !(review.period as CheckInCycle).evaluation_period_id,
    );
  }

  async fetchGoalReviews(goalId: number) {
    this.stores.loadingStore.start(FETCH_GOAL_REVIEWS, { goalId });
    try {
      const goalReviews = await getGoalReviews(goalId);
      runInAction(() => {
        this.goalReviews.set(goalId, goalReviews);
      });
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_GOAL_REVIEWS, { goalId });
    }
  }

  async addGoalReview(goalId: number, review: ReviewPayload) {
    this.stores.loadingStore.start(ADD_GOAL_REVIEW);
    try {
      const goalReview = await createGoalReview(goalId, review);

      this.stores.notificationStore.triggerSuccessNotification(
        'Check-in successfully added!',
      );

      runInAction(() => {
        const list = this.goalReviews.get(goalId);
        list && list.unshift(goalReview);
      });

      this.stores.goalStore.resetCheckInReviewForGoal(goalId);
      this.stores.commentStore.clearCommentsForGoal(goalId);
      return true;
    } catch (error) {
      console.warn(error);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
      return false;
    } finally {
      this.stores.loadingStore.end(ADD_GOAL_REVIEW);
    }
  }

  async fetchEmployeeReviews(employeeId: number) {
    this.stores.loadingStore.start(FETCH_EMPLOYEE_REVIEWS, { employeeId });

    const check_in_cycle = this.stores.clientStore.checkInCycle.id;

    try {
      const employeeReviews = await getEmployeeReviews(employeeId, check_in_cycle);

      runInAction(() => {
        this.employeeReviews = employeeReviews;
      });
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_EMPLOYEE_REVIEWS, { employeeId });
    }
  }

  async addEmployeeReview(employeeId: number, review: ReviewPayload) {
    this.stores.loadingStore.start(ADD_EMPLOYEE_REVIEW);
    try {
      const employeeReview = await createEmployeeReview(employeeId, review);
      this.stores.notificationStore.triggerSuccessNotification(
        'Review successfully added!',
      );

      runInAction(() => {
        this.employeeReviews.unshift(employeeReview);
      });

      this.stores.employeeStore.resetCheckInReview();
      // update the tasks list, to remove the task
      await this.stores.taskStore.fetchTasks();
    } catch (error) {
      console.warn(error);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
    } finally {
      this.stores.loadingStore.end(ADD_EMPLOYEE_REVIEW);
    }
  }

  async editGoalReview(goalId: number, reviewId: number, review: string) {
    this.stores.loadingStore.start(EDIT_REVIEW);
    try {
      const updatedReview = await updateReview(reviewId, { review });

      const reviewsList = this.goalReviews.get(goalId) || [];
      const updatedList = reviewsList.map((item) => {
        return item.id === reviewId
          ? { ...item, review: updatedReview.review }
          : item;
      });

      this.stores.notificationStore.triggerSuccessNotification(
        'Check-in successfully updated!',
      );
      runInAction(() => {
        this.goalReviews.set(goalId, updatedList);
      });
    } catch (error) {
      console.warn(error);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
    } finally {
      this.stores.loadingStore.end(EDIT_REVIEW);
    }
  }

  async editEmployeeReview(reviewId: number, text: string) {
    this.stores.loadingStore.start(EDIT_REVIEW);
    try {
      const updatedReview = await updateReview(reviewId, { review: text });

      const updatedReviews = this.employeeReviews.map((item) => {
        return item.id === reviewId
          ? { ...item, review: updatedReview.review }
          : item;
      });

      this.stores.notificationStore.triggerSuccessNotification(
        'Review successfully updated!',
      );

      runInAction(() => {
        this.employeeReviews = updatedReviews;
      });
    } catch (error) {
      console.warn(error);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
    } finally {
      this.stores.loadingStore.end(EDIT_REVIEW);
    }
  }

  reset() {
    this.goalReviews = EMPTY_GOAL_REVIEWS;
    this.employeeReviews = EMPTY_EMPLOYEE_REVIEWS;
  }
}

decorate(ReviewStore, {
  goalReviews: observable,
  employeeReviews: observable,

  employeeCheckInReviews: computed,
  employeeFinalReview: computed,

  fetchGoalReviews: action,
  fetchEmployeeReviews: action,
  addGoalReview: action,
  addEmployeeReview: action,
  editGoalReview: action,
  editEmployeeReview: action,
  reset: action,
});

async function createGoalReview(
  goalId: number,
  payload: ReviewPayload,
): Promise<ReviewResponse> {
  const { data } = await api.post(`goals/${goalId}/reviews`, payload);

  const task = stores.taskStore.tasks.find((t) => t.goal_id === goalId && t.client_check_in_cycle_id === payload.client_check_in_cycle_id);

  if (task) {
    stores.taskStore.completeTask(task.id);
  }

  return data.data;
}

async function getGoalReviews(goalId: number): Promise<ReviewsResponse> {
  const { data } = await api.get(`goals/${goalId}/reviews`);
  return data.data;
}

async function createEmployeeReview(
  employeeId: number,
  payload: ReviewPayload,
): Promise<ReviewResponse> {
  const { data } = await api.post(`employees/${employeeId}/reviews`, payload);

  return data.data;
}

async function getEmployeeReviews(
  employeeId: number,
  check_in_cycle: number,
): Promise<ReviewsResponse> {
  const { data } = await api.get(`employees/${employeeId}/reviews`, {
    params: { check_in_cycle },
  });
  return data.data;
}

async function updateReview(reviewId: number, payload: ReviewPayload): Promise<ReviewResponse> {
  const { data } = await api.patch(`reviews/${reviewId}`, payload);
  return data.data;
}

export type ReviewPayload = {
  review: string;
  client_check_in_cycle_id?: number | null;
  client_evaluation_period_id?: number | null;
};

type ReviewResponse = Review;
type ReviewsResponse = Review[];
