import React, { useEffect, useState } from 'react';
import styles from './ManageValues.module.css';
import { IUserStore, IHigh5Store } from '../../stores';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Card,
  Content,
  CreateCompanyValueModal,
  EditCompanyValueModal,
  Divider,
  Heading,
  Icon,
  IconEnum,
  Spacer,
  Text,
  Wrapper,
} from '../../components';
import { CompanyValue } from '../../types';
// import { runInAction } from 'mobx';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  userStore?: IUserStore;
  high5Store?: IHigh5Store;
}

function ManageValues(props: Props) {
  const high5Store = props.high5Store!;
  const userStore = props.userStore!;
  const { user } = userStore;
  const { companyValues } = high5Store;

  const [isCreateModalVisible, setCreateModalVisible] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [value, setValue] = useState<CompanyValue>();

  useEffect(() => {
    high5Store.fetchCompanyValues();
  }, [user.client_id, high5Store]);

  return (
    <>
      <Wrapper>
        <Content
          title="Manage Company Values"
          header={<Heading level={1}>Manage Company Values</Heading>}
          body={renderBody()}
        />
      </Wrapper>

      {isCreateModalVisible && (
        <CreateCompanyValueModal
          isVisible={isCreateModalVisible}
          onClose={() => {
            setCreateModalVisible(false);
          }}
        />
      )}

      {!!value && (
        <EditCompanyValueModal
          value={value}
          isVisible={true}
          onClose={() => {setValue(undefined)}}
        />
      )}
    </>
  );

  function renderBody() {
    return (
      <Card className={styles.manage_values_card}>
        <div className={styles.card_header}>
          <Text>Create or modify your organization's values</Text>
          <div className={styles.card_header__actions}>
            <Button role="secondary" onClick={() => { setIsEditable(!isEditable) }}>
              {isEditable ? 'Cancel' : 'Edit'}
            </Button>
            <Button onClick={() => { setCreateModalVisible(true) }}>Add a value</Button>
          </div>
        </div>

        <div className={styles.card_body}>
          {renderValues()}
        </div>
      </Card>
    );
  }

  function renderValues() {
    return (
      <>
        <Divider gap={24} />
        <Heading level={2}>Values</Heading>
        <Spacer vertical={16} />
        <div className={styles.values__list}>
          { companyValues.length
            ? companyValues.map((value) => renderValue(value))
            : <Text>No values were found.</Text>
          }
        </div>
      </>
    );
  }

  function renderValue(value: CompanyValue) {
    const valueStyles = cx({
      value: true,
      is_editable: isEditable,
    });

    return (
      <div className={valueStyles} key={value.id}>
        {isEditable &&
          <button
            className={styles.standard__edit}
            onClick={() => setValue(value)}
          >
            <Icon icon={IconEnum.EDIT} />
          </button>
        }

        <div className={styles.standard__header}>
          <h3>{value.name}</h3>
        </div>
      </div>
    );
  }
}

export default inject(
  'userStore',
  'high5Store'
)(observer(ManageValues));
