export function clamp(
  min: number,
  max: number,
  val: number
): number {
  return Math.min(
    Math.max(min, +val),
    max
  )
}
