import React from 'react';

import { useAdaptiveLayout } from '../../utils/hooks';

import { Icon, Spacer, Text, IconEnum } from '../../components';

interface Props {
  status: string;
  count: number;
  color: string;
  total?: number;
}

export function GoalCount(props: Props) {
  const { status, count, color, total } = props;

  const { isDesktop } = useAdaptiveLayout();
  return (
    <div
      style={{
        opacity: count > 0 ? 1 : 0.6,
        color: color,
        display: 'flex',
        alignItems: 'flex-end',
      }}>
      <Icon icon={IconEnum.GOAL} size={24} color={'inherit'} />

      <Spacer horizontal={8} />

      <Text size={16} inline bold>
        {count}
        {total !== undefined && (
          <Text size={14} inline>
            /{total}
          </Text>
        )}
        {isDesktop && (
          <Text bold inline size={11}>
            {' '}
            {status}
          </Text>
        )}
      </Text>
    </div>
  );
}
