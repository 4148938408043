import React, { useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Location } from 'history';

import { IUserStore } from '../../stores';
import {
  Button,
  // Color,
  Heading,
  Icon,
  IconEnum,
  Input,
  Spacer,
  Stack,
  Text,
} from '../../components';
import styles from './LoginScreen.module.css';
import { inject, observer } from 'mobx-react';
import { useAdaptiveLayout } from '../../utils/hooks';
// import GoogleLogin, {
//   GoogleLoginResponse,
//   GoogleLoginResponseOffline,
// } from 'react-google-login';
// import config from '../../config';

type RouteParams = {};
type RouteState = { from: Location };
type RouteProps = RouteComponentProps<RouteParams, {}, RouteState>;

type Props = RouteProps & {
  userStore: IUserStore;
};

function LoginScreen(props: Props) {
  const { userStore, location } = props;
  const [redirectToReferrer, setRedirect] = React.useState(false);
  const { from } = location.state || { from: { pathname: '/dashboard' } };
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { isDesktop } = useAdaptiveLayout();

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  if (userStore.isAuthenticated()) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div data-mobile={!isDesktop} className={styles.container}>
      <div className={styles.loginContainer}>
        <Stack gap={8} align={'center'}>
          <Icon icon={IconEnum.HIGH5} size={36} />
          <Heading>Sign in to Plaudify</Heading>
          <Text>Please use your company credentials</Text>
          <Spacer vertical={32} />

          <form
            onSubmit={(e) => e.preventDefault()}
            className={styles.formContainer}>
            <Stack gap={16} align={'stretch'}>
              <Input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                placeholder={'john@mail.com'}
                alignText={'center'}
              />

              <Input
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                placeholder={'Enter password'}
                type={'password'}
                alignText={'center'}
              />

              <p className={styles.forgotPassword}><a href="/passwordrecovery">Forgot Password?</a></p>
            </Stack>
            <Spacer vertical={32} />
            <div className={styles.centered}>
              <Button disabled={submitting} onClick={() => login()}>
                Sign In
              </Button>
            </div>
          </form>
          {/* <Spacer vertical={4} />
          <Text color={Color.GREY}>or</Text>
          <Spacer vertical={4} />
          <GoogleLogin
            clientId={config.GOOGLE_CLIENT_ID}
            onSuccess={loginWithGoogle}
            onFailure={onFailure}
          /> */}

          {errorMessage.length !== 0 && (
            <div className={styles.centered}>
              <h4>{errorMessage}</h4>
            </div>
          )}
        </Stack>
      </div>
    </div>
  );

  // function onFailure() {
  //   setErrorMessage('Please contact your administrator');
  //   setSubmitting(false);
  // }

  // async function loginWithGoogle(
  //   response: GoogleLoginResponse | GoogleLoginResponseOffline,
  // ) {
  //   try {
  //     setSubmitting(true);
  //     await userStore.ssoLogin((response as GoogleLoginResponse).tokenId);
  //     setRedirect(true);
  //   } catch (e) {
  //     setSubmitting(false);
  //     setErrorMessage(e.message);
  //   }
  // }

  async function login() {
    const userPayload = { email, password };
    try {
      setSubmitting(true);
      await userStore.login(userPayload);
      setRedirect(true);
    } catch (error) {
      setSubmitting(false);
      setErrorMessage(error.message);
    }
  }
}

export default inject('userStore')(observer(LoginScreen));
