import React from 'react';
import { EvaluationPeriodModal } from '../EvaluationPeriodModal/EvaluationPeriodModal';
import { inject, observer } from 'mobx-react';
import { IEvaluationPeriodStore } from '../../../stores';
import { CheckInCycleTypes, EvaluationPeriodTypes } from '../../../types';

type Props = {
  evaluationPeriodStore?: IEvaluationPeriodStore;
};

function EvaluationPeriodModalContainerWithoutStore(props: Props) {
  const evaluationPeriodStore = props.evaluationPeriodStore!;

  return (
    <EvaluationPeriodModal
      isVisible={evaluationPeriodStore.isVisible}
      onClose={() => evaluationPeriodStore.hideModal()}
      onSend={(data: {
        label: string,
        type: EvaluationPeriodTypes,
        cycleType: CheckInCycleTypes,
        startYear: number,
        startMonth: number,
      }) =>
        evaluationPeriodStore.createEvaluationPeriod(
          data.label,
          data.type,
          data.cycleType,
          data.startYear,
          data.startMonth
        ).then(() => {
          evaluationPeriodStore.fetchTimeFrames()
        })
      }
    />
  );
}

export const EvaluationPeriodModalContainer = inject(
  'evaluationPeriodStore',
)(observer(EvaluationPeriodModalContainerWithoutStore));
