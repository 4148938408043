import React from 'react';
import classNames from 'classnames/bind';

import styles from './Heading.module.css';

const cx = classNames.bind(styles);

interface Props {
  level?: 1 | 2 | 3;
  children: React.ReactNode;
  color?: 'inherit';
}

export function Heading({ children, level, color, ...props }: Props) {
  const element = `h${level}`;
  const className = cx({
    h1: level === 1,
    h2: level === 2,
    h3: level === 3,
    inherit_color: color === 'inherit',
  });

  return React.createElement(element, { ...props, className }, [children]);
}

Heading.defaultProps = {
  level: 1,
};
