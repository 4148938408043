import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './EditStandardModal.module.css';
import {
  Button,
  Heading,
  Input,
  Modal,
  Spacer,
  Text,
  Textarea,
} from '../../';
import { IStandardStore } from '../../../stores';
import { ClientStandard } from '../../../types';

interface Props {
  standardStore?: IStandardStore;
  standard: ClientStandard;
  isVisible: boolean;
  onClose: () => void;
}

function _EditStandardModal(props: Props) {
  const { isVisible, standard, onClose } = props;
  const standardStore = props.standardStore!;
  const [name, setName] = useState(standard.name);
  const [description, setDescription] = useState(standard.description);

  return (
    <Modal isOpen={isVisible} onClose={closeModal}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Heading level={2}>Edit Standard</Heading>
        </div>

        <div className={styles.body}>
          <label>
            <Text bold>Standard Title</Text>

            <Spacer vertical={8} />

            <Input
              placeholder={''}
              size={'large'}
              value={name}
              onChange={({ target }) => setName(target.value)}
            />
          </label>

          <Spacer vertical={24} />

          <label>
            <Text bold>Standard Description</Text>

            <Spacer vertical={8} />

            <Textarea
              placeholder={''}
              value={description}
              onChange={({ target }) => setDescription(target.value)}
            />
          </label>
        </div>

        <div className={styles.footer}>
          <Button
            role='primary'
            onClick={submit}
            disabled={!name && !description}
          >Submit</Button>
        </div>
      </div>
    </Modal>
  );

  function closeModal() {
    onClose();
  }

  function reset() {
    setName('');
    setDescription('');
  }

  async function submit() {
    await standardStore.updateStandard(standard.id, { name, description });

    reset();

    closeModal();
  }
}

export const EditStandardModal = inject(
  'standardStore',
)(observer(_EditStandardModal));
