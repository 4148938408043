import React, { useRef, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import {
  Color,
  Heading,
  High5ListModalContainer,
  Icon,
  IconEnum,
  ProfileImage,
  Spacer,
  Stack,
  ActionButton,
  PipBadge,
  Inline,
  RequestMeetingModal,
  PIPModal,
  High5Button,
  PerformanceDefinitionsModal,
} from '../..';
import { useAdaptiveLayout, useOnClickOutside } from '../../../utils/hooks';

import styles from './EmployeeHeader.module.css';
import {
  IEmployeeStore,
  IHigh5Store,
  IUserStore,
  ITaskStore,
  IStandardStore,
} from '../../../stores';
import { Employee } from '../../../types';

type Props = {
  employee: Employee;
  high5Store?: IHigh5Store;
  employeeStore?: IEmployeeStore;
  userStore?: IUserStore;
  standardStore?: IStandardStore;
  taskStore?: ITaskStore;
};

function EmployeeHeaderWithoutStore(props: Props) {
  const high5Store = props.high5Store!;
  const employeeStore = props.employeeStore!;
  const userStore = props.userStore!;
  const standardStore = props.standardStore!;
  const taskStore = props.taskStore!;
  // const { employee } = props;
  const { isRequestMeetingModalOpen } = employeeStore;
  const { isPerformanceDefinitionsModalOpen } = standardStore;
  const { isDesktop } = useAdaptiveLayout();
  const history = useHistory();
  const [showMenu, toggleMenu] = useState(false);
  const [isHigh5ListModalOpen, setIsHigh5ListModalOpen] = useState(false);
  const [isPIPVisible, setIsPIPVisible] = useState(false);

  const { search, key } = useLocation();
  const query = new URLSearchParams(search);
  const queryId = query.get('highfive');

  const { employee } = employeeStore;

  useEffect(() => {
    if (!queryId) return;
    setIsHigh5ListModalOpen(true);
  }, [key, queryId]);

  useEffect(() => {
    employee && taskStore.fetchManager(employee.manager_id);
  }, [employee, taskStore]);

  const menuRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(menuRef, () => toggleMenu(false));

  //TOOD check if this is needed
  if (!employee) return <></>;

  return (
    <div className={styles.container}>
      <div className={styles.employeeHeader}>
        <div className={styles.left}>{
          renderEmployeeName()
        }</div>

        <Spacer horizontal={16} />

        <div className={styles.right}>
          {isDesktop ? (
            <>
              <ActionButton
                onClick={() =>
                  high5Store.showModal(
                    userStore.isManager ? employee : undefined,
                  )
                }
                icon={IconEnum.HIGH5}
                iconSize={24}
                text="Give Applause"
              />
              <Spacer horizontal={8} />
            </>
          ) : (
            <>
              {userStore.isEmployee && (
                <div className={styles.fixed_give_high5_button}>
                  <High5Button
                    onClick={() =>
                      high5Store.showModal(
                        userStore.isManager ? employee : undefined,
                      )
                    }
                    isRound
                  />
                </div>
              )}
            </>
          )}

          <button
            onClick={() => setIsHigh5ListModalOpen(true)}
            className={styles.high5sDisplay}>
            <Icon icon={IconEnum.HIGH5} size={24} />
            <Spacer horizontal={8} />
            <Heading level={3}>{employee?.high_fives_count}</Heading>
          </button>

          {userStore.isEmployee && isDesktop && (
            <>
              <Spacer horizontal={8} />

              <ActionButton
                onClick={handleOnRequestMeetingClick}
                icon={IconEnum.CALENDAR}
                iconColor={Color.PRIMARY}
                text="Request Manager Meeting"
              />
            </>
          )}
          {userStore.isManager && renderActionsMenu()}
        </div>
      </div>
      {userStore.isEmployee && !isDesktop && (
        <>
          <ActionButton
            onClick={handleOnRequestMeetingClick}
            icon={IconEnum.CALENDAR}
            iconColor={Color.PRIMARY}
            text="Request Manager Meeting"
          />
          <Spacer vertical={24} />
        </>
      )}

      {isHigh5ListModalOpen && (
        <High5ListModalContainer
          isOpen={isHigh5ListModalOpen}
          onClose={() => setIsHigh5ListModalOpen(false)}
          employee={employee}
          highlightedHigh5Id={queryId}
        />
      )}
      {isRequestMeetingModalOpen && (
        <RequestMeetingModal
          managerName={taskStore.manager.name}
          isVisible={isRequestMeetingModalOpen}
          onClose={() => employeeStore.hideRequestMeetingModal()}
          onSend={requestMeeting}
        />
      )}
      {isPIPVisible && (
        <PIPModal
          employee={employee!}
          isVisible={isPIPVisible}
          onClose={() => setIsPIPVisible(false)}
        />
      )}

      {isPerformanceDefinitionsModalOpen && (
        <PerformanceDefinitionsModal
          isVisible={isPerformanceDefinitionsModalOpen}
          onClose={() => standardStore.hidePerformanceDefinitionsModal()}
        />
      )}
    </div>
  );

  function renderEmployeeName() {
    if (!employee) return <></>;

    const {
      id,
      name,
      on_pip,
    } = employee;

    const to = userStore.isManager
      ? `/employee/${id}`
      : '/dashboard';

    return (
      <Link
        to={to}>
        <div className={styles.employee}>
          <ProfileImage name={name} size={'large'} />

          <Spacer horizontal={isDesktop ? 16 : 8} />

          <Inline gap={isDesktop ? 16 : 8}>
            <Heading level={isDesktop ? 2 : 3} color="inherit">
              {name}
            </Heading>
            {on_pip && <PipBadge />}
          </Inline>
        </div>
      </Link>
    );
  }

  function renderActionsMenu() {
    return (
      <>
        <Spacer horizontal={isDesktop ? 16 : 8} />

        <div className={styles.dotsMenu} ref={menuRef}>
          <button
            onClick={() => toggleMenu(!showMenu)}
            className={styles.dotsBtn}>
            <Icon icon={IconEnum.DOTS} size={20} />
          </button>

          {showMenu && (
            <div className={styles.menuOptions}>
              <Stack gap={8}>
                <button
                  className={styles.menuOption}
                  onClick={() =>
                    history.push(`/employee/${employee?.id}/goal/new`)
                  }>
                  <span className={styles.plus}>+ </span>Add New Goal
                </button>
                <button
                  className={styles.menuOption}
                  onClick={() =>
                    history.push(`/employee/${employee?.id}/report`)
                  }>
                  Export Activity Report
                </button>

                {employee?.on_pip ? (
                  <button
                    className={styles.menuOption}
                    onClick={() => removeFromPIP()}
                  >Remove from PIP</button>
                ): (
                  <button
                    className={styles.menuOption}
                    onClick={() => addToPIP()}
                  >Request PIP</button>
                )}
              </Stack>
            </div>
          )}
        </div>
      </>
    );
  }

  function addToPIP() {
    setIsPIPVisible(true);
    toggleMenu(false);
  }

  async function removeFromPIP() {
    await employeeStore.removeEmployeeFromPIP(employee!.id);
    toggleMenu(false);
  }

  function handleOnRequestMeetingClick() {
    if (taskStore.manager.id) employeeStore.showRequestMeetingModal();
  }

  async function requestMeeting() {
    await taskStore.requestMeeting();
    employeeStore.hideRequestMeetingModal();
  }
}

export const EmployeeHeader = inject(
  'high5Store',
  'employeeStore',
  'userStore',
  'standardStore',
  'taskStore',
)(observer(EmployeeHeaderWithoutStore));
