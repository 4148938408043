import React from 'react';
import styles from './Checkbox.module.css';
import { Icon, IconEnum } from '../../base';
import { Color } from '../../styles';

interface Props {
  name?: string;
  value?: string;
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function Checkbox(props: Props) {
  const { name, value, checked = false, onChange } = props;

  return (
    <div className={styles.checkboxContainer}>
      {checked && (
        <div className={styles.iconContainer}>
          <Icon icon={IconEnum.CHECK} color={Color.WHITE} size={14} />
        </div>
      )}
      <input
        onChange={onChange}
        className={styles.checkbox}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
      />
    </div>
  );
}
