import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Column } from 'react-table';
import styles from './CheckInCyclesScreen.module.css';
import { inject, observer } from 'mobx-react';
import { IEvaluationPeriodStore } from '../../stores';
import { CheckInCycle, EvaluationPeriod } from '../../types';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import EvaluationPeriodTable from '../../components/custom/EvaluationPeriodTable/EvaluationPeriodTable';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween'
import { clamp } from './CheckInCyclesScreen.utils'

import {
  Button,
  Card,
  Content,
  Heading,
  Input,
  Spacer,
  Table,
  Text,
  Wrapper,
} from '../../components';

dayjs.extend(utc)
dayjs.extend(isBetween)

type RouteParams = {
  evaluation_period_id: string;
};

type RouteProps = RouteComponentProps<RouteParams>;

type Props = RouteProps & {
  evaluationPeriodStore: IEvaluationPeriodStore;
}

type Focused = {
  input: 'label' | 'start' | 'end';
  index: number;
}

function CheckInCyclesScreen(props: Props) {
  const history = useHistory()

  const evaluationPeriodStore = props.evaluationPeriodStore!;

  const evaluation_period_id = Number(props.match.params.evaluation_period_id);

  const evaluationPeriod = evaluationPeriodStore.getPeriod(evaluation_period_id);

  const isVisible = !evaluationPeriod.active &&
    dayjs.utc(evaluationPeriod.start_date).isAfter(dayjs.utc())

  const min_window_value = 1;
  const max_window_value = 20;

  const [focused, setFocused] = useState<Focused|null>(null)
  const [cycles, setCycles] = useState<Array<CheckInCycle>>(
    evaluationPeriodStore.checkInCycles.filter(cycle => cycle.evaluation_period_id === evaluation_period_id)
  );

  const [startWindow, setStartWindow] = useState<number>(10);
  const [endWindow, setEndWindow] = useState<number>(10);

  const labelRef = useRef<Array<HTMLInputElement>>([])
  const startRef = useRef<Array<HTMLInputElement>>([])
  const endRef = useRef<Array<HTMLInputElement>>([])

  useEffect(() => {
    if (focused !== null)
    {
      if (focused.input === 'label')
        labelRef?.current[focused.index]?.focus()

      if (focused.input === 'start')
        startRef?.current[focused.index]?.focus()

      if (focused.input === 'end')
        endRef?.current[focused.index]?.focus()
    }
  })

  const data: Array<CheckInCycle> = useMemo(() => {
    return cycles;
  }, [cycles]);

  const columns: Array<Column<CheckInCycle>> = useMemo(() => {
    return [{
      Header: 'Label',
      accessor: 'label',
      Cell: (({cell: { row }, value}) => {
        return <input
          ref={ref => {if (ref) labelRef.current[row.index] = ref}}
          type="text"
          value={value}
          onFocus={() => {
            setFocused({
              input: 'label',
              index: row.index,
            })
          }}
          onChange={(e) => {
            const cycle = cycles[row.index]
            cycle.label = e.target.value
            setCycles(
              cycles.map(c => c.id === cycle.id ? cycle : c)
            )
          }}
        />
      }),
    },
    {
      Header: 'Start',
      accessor: 'start_date',
      Cell: (({ value }) => {
        return dayjs.utc(value).format('MM/DD/YYYY')
      }),
    },
    {
      Header: 'End',
      accessor: 'end_date',
      Cell: (({ value }) => {
        return dayjs.utc(value).format('MM/DD/YYYY')
      }),
    },
    {
      Header: 'Review Start',
      accessor: 'review_window_start_date',
      Cell: (({cell: { row }, value}) => {
        const date = dayjs.utc(value).format('YYYY-MM-DD')
        const cycle = cycles[row.index]
        const endDate = dayjs.utc(cycle.end_date).subtract(min_window_value, 'day')
        const startDate = dayjs.utc(cycle.end_date).subtract(max_window_value, 'day')

        return <input
          ref={ref => {if (ref) startRef.current[row.index] = ref}}
          type="date"
          value={date}
          max={endDate.format('YYYY-MM-DD')}
          min={startDate.format('YYYY-MM-DD')}
          onFocus={() => {
            setFocused({
              input: 'start',
              index: row.index,
            })
          }}
          onChange={(e) => {
            let date = dayjs.utc(e.target.value)

            if (!date.isBetween(startDate, endDate))
            {
              date = startDate
            }

            cycle.review_window_start_date = date.startOf('day').toISOString()

            setCycles(
              cycles.map(c => c.id === cycle.id ? cycle : c)
            )
          }}
        />
      }),
    },
    {
      Header: 'Review End',
      accessor: 'review_window_end_date',
      Cell: (({cell: { row }, value}) => {
        const date = dayjs.utc(value).format('YYYY-MM-DD');
        const cycle = cycles[row.index]
        const endDate = dayjs.utc(cycle.end_date).add(max_window_value, 'day')
        const startDate = dayjs.utc(cycle.end_date).add(min_window_value, 'day')

        return <input
          ref={ref => {if (ref) endRef.current[row.index] = ref}}
          type="date"
          value={date}
          max={endDate.format('YYYY-MM-DD')}
          min={startDate.format('YYYY-MM-DD')}
          onFocus={() => {
            setFocused({
              input: 'end',
              index: row.index,
            })
          }}
          onChange={(e) => {
            let date = dayjs.utc(e.target.value)

            if (!date.isBetween(startDate, endDate))
            {
              date = startDate
            }

            cycle.review_window_end_date = date.endOf('day').toISOString()

            setCycles(
              cycles.map(c => c.id === cycle.id ? cycle : c)
            )
          }}
        />
      }),
    },
  ]}, [cycles]);

  const EPdata: Array<EvaluationPeriod> = useMemo(() => {
    return [evaluationPeriod]
  }, [evaluationPeriod])

  return (
    <Wrapper>
      <Content
        title="Check In Cycles for Evaluation Period"
        header={<Heading level={1}>Check In Cycles</Heading>}
        body={renderBody()}
      />
    </Wrapper>
  );

  function renderBody() {
    return (
      <>
        <Card className={styles.evaluation_period}>
          <div className={styles.actions}>
            {isVisible &&
              <Button
                onClick={removeEvaluationPeriod}
              >Delete Evaluation Period</Button>
            }
          </div>
          <EvaluationPeriodTable
            data={EPdata}
          ></EvaluationPeriodTable>
        </Card>

        <Card className={styles.check_in_cycles}>
          <Heading level={3}>Modify the review start and end dates for your check in cycles</Heading>

          <Spacer></Spacer>

          <div className={styles.form}>
            <Text>Auto assign the review start and end dates</Text>
            <label>
              <Text inline={true}>Start</Text>
              <Input
                type="number"
                value={startWindow.toString()}
                min={min_window_value}
                max={max_window_value}
                onChange={e => {
                  const value = clamp(
                    min_window_value,
                    max_window_value,
                    Number(e.target.value)
                  )
                  setStartWindow(value)
                  updateReviewStartWindow(value)
                }}
              ></Input>
              <Text inline={true}>day(s) before</Text>
            </label>

            <label>
              <Text inline={true}>End</Text>
              <Input
                type="number"
                value={endWindow.toString()}
                min={min_window_value}
                max={max_window_value}
                onChange={e => {
                  const value = clamp(
                    min_window_value,
                    max_window_value,
                    Number(e.target.value)
                  )
                  setEndWindow(value)
                  updateReviewEndWindow(value)
                }}
              ></Input>
              <Text inline={true}>day(s) after</Text>
            </label>
          </div>

          <Table
            columns={columns}
            data={data}
          ></Table>

          <Button
            onClick={saveCheckInCycles}
          >Save</Button>
        </Card>
      </>
    );
  }

  function updateReviewStartWindow(increment: number)
  {
    setCycles(
      cycles.map(cycle => {
        const end_date = dayjs.utc(cycle.end_date)
        cycle.review_window_start_date = end_date
          .subtract(increment, 'day')
          .startOf('day')
          .toISOString()
        return cycle
      })
    )
  }

  function updateReviewEndWindow(increment: number)
  {
    setCycles(
      cycles.map(cycle => {
        const end_date = dayjs.utc(cycle.end_date)
        cycle.review_window_end_date = end_date
          .add(increment, 'day')
          .endOf('day')
          .toISOString()
        return cycle
      })
    )
  }

  async function saveCheckInCycles()
  {
    await evaluationPeriodStore.updateCheckInCycles(cycles)
    history.push('/evaluationPeriods')
  }

  async function removeEvaluationPeriod()
  {
    await evaluationPeriodStore.deleteEvaluationPeriod(evaluation_period_id)
    evaluationPeriodStore.fetchTimeFrames()
    history.push('/evaluationPeriods')
  }
}

export default inject('evaluationPeriodStore')(observer(CheckInCyclesScreen));
