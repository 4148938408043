import React, { useEffect, useState } from 'react';
import styles from './EvaluationDetailsContainer.module.css';
import { inject, observer } from 'mobx-react';
import { useAdaptiveLayout, useCurrentUser } from '../../../utils/hooks';

import { IClientStore, IReviewStore, IEmployeeStore, IDocumentStore, IEvaluationPeriodStore } from '../../../stores';
import { Employee, UserRole, PIP, Review as ReviewType, Document, EvaluationPeriod, CheckInCycle } from '../../../types';
import {
  ReviewForm,
  Divider,
  Review,
  Icon,
  IconEnum,
  Text,
  Color,
  Spacer,
  Card,
  PipBadge,
  DocumentModal,
} from '../../';
import { EMPTY_DOCUMENT } from '../../../stores/documentStore';
import { EMPTY_PIP } from '../../../stores/employeeStore';
import { ReviewPayload } from '../../../stores/reviewStore';

interface Props {
  employee: Employee;
  role: UserRole;
  clientStore?: IClientStore;
  documentStore?: IDocumentStore;
  employeeStore?: IEmployeeStore;
  evaluationPeriodStore?: IEvaluationPeriodStore;
  reviewStore?: IReviewStore;
}

function EvaluationDetails(props: Props) {
  const clientStore = props.clientStore!;
  const documentStore = props.documentStore!;
  const employeeStore = props.employeeStore!;
  const evaluationPeriodStore = props.evaluationPeriodStore!;
  const reviewStore = props.reviewStore!;
  const { employee, role } = props;

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [document, setDocument] = useState(EMPTY_DOCUMENT);
  const [pip, setPip] = useState(EMPTY_PIP);

  const { isDesktop } = useAdaptiveLayout();
  const currentUser = useCurrentUser();

  useEffect(() => {
    Promise.all([
      reviewStore.fetchEmployeeReviews(employee.id),
      employeeStore.fetchPIPs(employee.id),
    ]).then(() => setIsLoadingData(false));
  }, [employee.id, reviewStore, employeeStore, clientStore.checkInCycle.id]);

  const employeeReviews = reviewStore.employeeReviews || [];

  const employeePIPs = employeeStore.employeePIPs || [];

  const sortedReviewsAndPIPs: Array<
    ReviewType | PIP
  > = getSortedReviewsAndPIPs([...employeeReviews, ...employeePIPs]);

  // TODO add spinner component
  if (isLoadingData) return <></>;

  return (
    <div>
      {(role !== 'employee' && employee.review_window) && renderReview()}

      {sortedReviewsAndPIPs.map((item: ReviewType | PIP) => {
        return (
          <React.Fragment key={item.created_at}>
            <Divider />
            <div
              className={
                isPIP(item) ? styles.pip_container : styles.review_container
              }>
              {isPIP(item) ? (
                renderPIP(item)
              ) : (
                <Review
                  review={item}
                  onEdit={updateReview}
                  readOnly={role === 'employee'}
                  evaluationPeriod={getEvaluationPeriodLabel(item.period)}
                />
              )}
            </div>
          </React.Fragment>
        );
      })}

      {isVisible && (
        <DocumentModal
          document={document}
          pip={pip}
          isVisible={isVisible}
          onClose={() => {
            employeeStore.fetchPIPs(employee.id);
            setIsVisible(false)
          }}
        />
      )}
    </div>
  );

  function renderReview() {
    return (
      <>
        <Divider />

        <div className={styles.check_in_container}>
          <ReviewForm
            onSave={storeReview}
            buttonText={'Add review'}
          >
            <Icon
              icon={IconEnum.CHECK_CIRCLE}
              color={Color.LIGHT_GREY}
              size={24}
            />

            <Spacer horizontal={16} />

            <Text bold>
              Add a review for {' '}
              <Text color={Color.PRIMARY} bold inline>
                {(employee.review_window as EvaluationPeriod).label}
              </Text>
            </Text>
          </ReviewForm>
        </div>
      </>
    );
  }

  function renderPIP(pip: PIP) {
    return (
      <Card
        className={styles.pip_card}
        background={pip.type === 'added' ? '#FFEDEE' : '#C3EAD8'}>
        <div className={styles.pip_content}>
          <PipBadge />
          <Spacer horizontal={16} />
          <div className={styles.pip_text_wrapper}>
            <Text bold>
              {pip.type === 'added'
                ? 'Added on Performance Improvement Plan'
                : 'Removed from Performance Improvement Plan'}
            </Text>
            {isDesktop ? <Spacer horizontal={16} /> : <Spacer vertical={8} />}

            <Text color={Color.GREY} size={11} lineHeight={1}>
              {formatDate(new Date(pip.created_at))} by{' '}
              {currentUser.id === pip.author.id ? 'you' : pip.author.name}
            </Text>

            {
              currentUser.roles && currentUser.roles.includes('manager') &&
              !pip.documents.length &&
              pip.type === "added" && (
                <>
                  {isDesktop ? <Spacer horizontal={16} /> : <Spacer vertical={8} />}
                  <button
                    className={styles.file_button}
                    type="button"
                    onClick={() => {
                      setDocument(EMPTY_DOCUMENT);
                      setPip(pip);
                      setIsVisible(true);
                    }}
                  >
                    Add File
                  </button>
                </>
            )}

            {pip.documents.length ? (
              <ul className={styles.pip_documents}>
                {pip.documents.map((document, i) => {
                  return (
                    <li className={styles.pip_document} key={document.id}>
                      <a
                        href={document.uri}
                        download={document.nicename}
                        target='blank'
                      >
                        <Icon
                          icon={getDocumentIcon(document.nicename.split('.').pop() || '')}
                          color={Color.DARK_GREY}
                          size={24}
                        />
                        {document.nicename}
                      </a>

                      {currentUser.roles?.includes('manager') && (
                        <>
                          <button
                            className={styles.file_button}
                            type="button"
                            onClick={() => {
                              setDocument(document);
                              setPip(EMPTY_PIP);
                              setIsVisible(true);
                            }}
                          >
                            Replace
                          </button>

                          <button
                            className={styles.file_button}
                            type="button"
                            onClick={() => {
                              removeDocument(document);
                            }}
                          >
                            Delete
                          </button>
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            ) : ""}
          </div>
        </div>
      </Card>
    );
  }

  function getDocumentIcon(fileType: string): IconEnum {
    if (fileType === 'doc') return IconEnum.DOCUMENT_DOC;
    if (fileType === 'docx') return IconEnum.DOCUMENT_DOCX;
    if (fileType === 'pdf') return IconEnum.DOCUMENT_PDF;
    if (fileType === 'ppt') return IconEnum.DOCUMENT_PPT;
    if (fileType === 'txt') return IconEnum.DOCUMENT_TXT;
    if (fileType === 'xls') return IconEnum.DOCUMENT_XLS;
    if (fileType === 'xlsx') return IconEnum.DOCUMENT_XLSX;

    return IconEnum.DOCUMENT;
  }

  async function removeDocument(document: Document) {
    await documentStore.removeDocument(document.id);
    employeeStore.fetchPIPs(employee.id);
  }

  async function storeReview(review: string) {
    const payload: ReviewPayload = { review };

    if (employee.review_window && employee.review_window.hasOwnProperty('evaluation_period_id')) {
      payload.client_check_in_cycle_id = employee.review_window.id
    } else {
      payload.client_evaluation_period_id = (employee.review_window as EvaluationPeriod).id;
    }

    await reviewStore.addEmployeeReview(employee.id, payload);
    await employeeStore.updateEmployee(employee.id);
  }

  function updateReview(reviewId: number, review: string) {
    reviewStore.editEmployeeReview(reviewId, review);
  }

  function formatDate(date: Date): string {
    return new Intl.DateTimeFormat('default', {
      day: 'numeric',
      month: 'short',
      timeZone: 'UTC',
    }).format(date);
  }

  function getSortedReviewsAndPIPs(items: Array<ReviewType | PIP>) {
    return items.sort((a, b) => (b.created_at > a.created_at ? 1 : -1));
  }

  function getEvaluationPeriodLabel(period: CheckInCycle | EvaluationPeriod): string {
    if ((period as CheckInCycle).evaluation_period_id !== undefined) {
      const evaluationPeriod = evaluationPeriodStore.evaluationPeriods.find(
        ({ id }) => (period as CheckInCycle).evaluation_period_id === id
      );

      return evaluationPeriod?.label || '';
    }

    return period.label;
  }

  function isPIP(item: ReviewType | PIP): item is PIP {
    return (item as PIP).type !== undefined;
  }
}

export const EvaluationDetailsContainer = inject(
  'clientStore',
  'documentStore',
  'employeeStore',
  'evaluationPeriodStore',
  'reviewStore',
)(observer(EvaluationDetails));
