import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './CreateCompanyValueModal.module.css';
import {
  Button,
  Heading,
  Input,
  Modal,
  Spacer,
  Text,
} from '../../';
import { IHigh5Store } from '../../../stores';

interface Props {
  high5Store?: IHigh5Store;
  isVisible: boolean;
  onClose: () => void;
}

function CreateCompanyValueModalWithoutStore(props: Props) {
  const { isVisible, onClose } = props;
  const high5Store = props.high5Store!;
  const [name, setName] = useState('');

  return (
    <Modal isOpen={isVisible} onClose={closeModal}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Heading level={2}>Create Company Value</Heading>
        </div>

        <div className={styles.body}>
          <label>
            <Text bold>Company Value</Text>

            <Spacer vertical={8} />

            <Input
              placeholder={''}
              size={'large'}
              value={name}
              onChange={({ target }) => setName(target.value)}
            />
          </label>
        </div>

        <div className={styles.footer}>
          <Button
            role='primary'
            onClick={submit}
            disabled={!name}
          >Submit</Button>
        </div>
      </div>
    </Modal>
  );

  function closeModal() {
    onClose();
  }

  function reset() {
    setName('');
  }

  async function submit() {
    const value = await high5Store.createCompanyValue({ name });

    if (value) {
      reset();
      closeModal();
    }
  }
}

export const CreateCompanyValueModal = inject(
  'high5Store',
)(observer(CreateCompanyValueModalWithoutStore));
