import React, { useMemo, useState } from 'react';
import styles from './UsersScreen.module.css';
import { inject, observer } from 'mobx-react';
import { Column } from 'react-table';
import { IClientStore } from '../../stores';

import {
  Button,
  Card,
  Content,
  Heading,
  ImportUsersModal,
  Input,
  Spacer,
  Table,
  Text,
  Wrapper,
} from '../../components';
import { User } from '../../types';
import { Link, useHistory } from 'react-router-dom';
import { getClient } from '../../services/ClientRepository';
import { EMPTY_CLIENT } from '../../stores/clientStore';

interface Props {
  clientStore: IClientStore;
}

function UsersScreen(props: Props) {
  const clientStore = props.clientStore!;
  const history = useHistory();

  const [search, setSearch] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const client = getClient() || EMPTY_CLIENT;

  const data: Array<User> = useMemo(() => clientStore.users
    .sort((a, b) => a.name < b.name
      ? -1
      : b.name < a.name
        ? 1
        : 0
    )
    .filter(user => user.name
      .toLocaleLowerCase()
      .includes(search.toLocaleLowerCase())
    ), [clientStore.users, search]);

  const columns: Array<Column<User>> = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ cell: { row }, value }) => {
        if (client.gsuite_id)
        {
          return value
        }

        else
        {
          return (
            <Link to={`/users/${row.original.id}`}>
              { value }
            </Link>
          )
        }
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Manager',
      accessor: (row: User) => {
        const manager = clientStore.users.find(user => user.id === row.manager_id)
        return manager?.name || ''
      }
    }
  ], [client.gsuite_id, clientStore.users]);

  return (
    <Wrapper>
      <Content
        title="Users"
        header={<Heading level={1}>Users</Heading>}
        body={renderBody()}
      />
    </Wrapper>
  );

  function renderBody() {
    return (
      <Card className={styles.users}>
        <div className={styles.header}>
          <Text>Manage your oganization's users</Text>

          <Spacer vertical={16}></Spacer>

          <div className={styles.actions}>
            <Input
              value={search}
              placeholder="Search users"
              onChange={e => setSearch(e.target.value)}
            ></Input>

            {!client.gsuite_id &&
              <Button onClick={createUser}>New User</Button>
            }

            <Button
              onClick={exportUsers}
              role="secondary"
            >
              Export
            </Button>

            {!client.gsuite_id &&
              <Button
                onClick={() => setIsVisible(true)}
                role="secondary"
              >
                Import
              </Button>
            }
          </div>
        </div>

        <Table
          columns={columns}
          data={data}
        ></Table>

        <ImportUsersModal
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          onSend={(file: File) => importUsers(file)}
        />
      </Card>
    );
  }

  function createUser()
  {
    history.push('/users/create');
  }

  function exportUsers()
  {
    clientStore.exportUsers()
  }

  async function importUsers(file: File)
  {
    const formData = new FormData();
    formData.append('users', file);

    await clientStore.importUsers(formData);

    await clientStore.fetchUsers();

    setIsVisible(false);
  }
}

export default inject('clientStore')(observer(UsersScreen));
