import { RootStore } from './index';
import { Comment } from '../types';
import { api } from '../services/http';
import { runInAction, decorate, observable, action, ObservableMap } from 'mobx';

export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export interface ICommentStore {
  goalComments: ObservableMap<number, Comment[]>;
  reviewComments: ObservableMap<number, Comment[]>;

  fetchComments(goalId: number): Promise<void>;
  fetchReviewComments(reviewId: number): Promise<void>;
  addComment(goalId: number, text: string): Promise<void>;
  removeComment(goalId: number, commentId: number): Promise<void>;
  clearCommentsForGoal(goalId: number): void;
  reset(): void;
}

const EMPTY_COMMENTS = new ObservableMap<number, Comment[]>();
const EMPTY_REVIEW_COMMENTS = new ObservableMap<number, Comment[]>();

export default class CommentStore implements ICommentStore {
  stores: RootStore;

  goalComments = EMPTY_COMMENTS;
  reviewComments = EMPTY_REVIEW_COMMENTS;

  constructor(stores: RootStore) {
    this.stores = stores;
  }

  async fetchComments(goalId: number) {
    this.stores.loadingStore.start(FETCH_COMMENTS, { goalId });
    try {
      const comments = await getGoalComments(goalId);
      runInAction(() => {
        this.goalComments.set(goalId, comments);
      });
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_COMMENTS, { goalId });
    }
  }

  async fetchReviewComments(reviewId: number) {
    this.stores.loadingStore.start(FETCH_COMMENTS, { reviewId });
    try {
      const comments = await getReviewComments(reviewId);
      runInAction(() => {
        this.reviewComments.set(reviewId, comments);
      });
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_COMMENTS, { reviewId });
    }
  }

  async addComment(goalId: number, text: string) {
    this.stores.loadingStore.start(CREATE_COMMENT);
    try {
      const comment = await addComment(goalId, { comment: text });

      this.stores.notificationStore.triggerSuccessNotification(
        'Comment sucessfully added!',
      );

      runInAction(() => {
        const list = this.goalComments.get(goalId);
        list && list.push(comment);
      });

      this.stores.goalStore.incrementCommentCount(goalId);
    } catch (error) {
      console.warn(error);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
    } finally {
      this.stores.loadingStore.end(CREATE_COMMENT);
    }
  }

  async removeComment(goalId: number, commentId: number) {
    this.stores.loadingStore.start(DELETE_COMMENT);
    try {
      await deleteComment(commentId);

      const comments = this.goalComments.get(goalId) || [];
      const updatedComments = comments.filter((c) => c.id !== commentId);

      this.stores.notificationStore.triggerSuccessNotification(
        'Comment sucessfully deleted!',
      );

      runInAction(() => {
        this.goalComments.set(goalId, updatedComments);
        this.stores.goalStore.decrementCommentCount(goalId);
      });
    } catch (error) {
      console.warn(error);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
    } finally {
      this.stores.loadingStore.end(DELETE_COMMENT);
    }
  }

  clearCommentsForGoal(goalId: number) {
    this.goalComments.set(goalId, []);
  }

  reset() {
    this.goalComments = EMPTY_COMMENTS;
    this.reviewComments = EMPTY_REVIEW_COMMENTS;
  }
}

decorate(CommentStore, {
  goalComments: observable,
  reviewComments: observable,

  fetchComments: action,
  fetchReviewComments: action,
  addComment: action,
  removeComment: action,
  clearCommentsForGoal: action,
  reset: action,
});

async function getGoalComments(goalId: number): Promise<CommentsResponse> {
  const response = await api.get(`goals/${goalId}/comments`);
  return response.data;
}

async function getReviewComments(reviewId: number): Promise<CommentsResponse> {
  const { data } = await api.get(`reviews/${reviewId}/comments`);
  return data.data;
}

async function addComment(
  goalId: number,
  payload: AddCommentPayload,
): Promise<AddCommentResponse> {
  const response = await api.post(`goals/${goalId}/comments`, payload);
  return response.data;
}

async function deleteComment(commentId: number): Promise<void> {
  return await api.delete(`comments/${commentId}`);
}

type CommentsResponse = Comment[];

type AddCommentPayload = {
  comment: string;
};

type AddCommentResponse = Comment;
