import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react"
import { ClientStandardsReview, Employee } from "../../../types"
import { IClientStore, IStandardStore, IUserStore } from '../../../stores';
import { Button, Spacer, Stack, Text } from '../../index';
import { ReviewCard } from './ReviewCard';
import { EmployeeReviewCard } from './EmployeeReviewCard';
import { filterCompleted, filterReviews } from './StandardsReviews.utils';
import { autorun } from 'mobx';
import dayjs from 'dayjs';

type Props = {
  clientStore?: IClientStore
  standardStore?: IStandardStore
  userStore?: IUserStore
  employee: Employee
}

function _StandardsReviews(props: Props) {
  const { employee } = props;
  const clientStore = props.clientStore!;
  const standardStore = props.standardStore!;
  const userStore = props.userStore!;
  const { reviews } = standardStore;
  const { role } = userStore;
  const [visibleReviews, setVisibleReviews] = useState<ClientStandardsReview[]>([]);
  const [canCreate, setCanCreate] = useState(employee.performance_review_window !== false);
  const [created, setCreated] = useState(false);

  useEffect(
    () => { (async function () { await standardStore.fetchReviews(employee.id) })(); },
    [standardStore, employee.id, clientStore.checkInCycle.id]
  );

  useEffect(
    () => autorun(() => {
      let visible = filterReviews(reviews, employee.id, clientStore.evaluationPeriod.id);

      if (role === "employee") {
        visible = filterCompleted(visible);
      }

      setVisibleReviews(
        visible.sort((a, b) =>
          dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf()
        )
      );
    }),
    [reviews, employee.id, role, clientStore.evaluationPeriod.id]
  );

  useEffect(
    () => setCanCreate(
      role === 'manager' && employee.performance_review_window !== false && !created
    ),
    [employee, role, created, setCanCreate]
  );

  return (
    <>
      <div className="review__body">
        {!visibleReviews.length && <Text>No reviews found</Text>}

        {!!visibleReviews.length && renderReviews()}

        {canCreate &&
          <>
            <Spacer vertical={16} />
            <Button onClick={addReview}>Add a Performance Standard Review</Button>
          </>
        }
      </div>
    </>
  );

  function renderReviews() {
    return (
      <Stack gap={8}>
        {visibleReviews.map((review) => {
          return renderReview(review);
        })}
      </Stack>
    );
  }

  function renderReview(review: ClientStandardsReview) {
    return (
      role === 'employee'
        ? <EmployeeReviewCard
            key={review.id}
            review={review}
            employee={employee}
          />
        : <ReviewCard
          key={review.id}
          review={review}
        />
    );
  }

  async function addReview() {
    const review = await standardStore.createReview(employee.id);

    if (!review) return;

    setCreated(true);
    standardStore.fetchReviews(employee.id);
  }
}

export const StandardsReviews = inject(
  'clientStore',
  'standardStore',
  'userStore',
)(observer(_StandardsReviews));
