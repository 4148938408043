import React from 'react';
import styles from './NotificationList.module.css';
import { inject, observer } from 'mobx-react';
import { INotificationStore } from '../../../stores';

import { NotificationCard } from './NotificationCard';
import { Stack } from '../../';

interface Props {
  notificationStore?: INotificationStore;
}

function Notifications(props: Props) {
  const notificationStore = props.notificationStore!;

  if (!notificationStore.notifications.length) return <></>;

  return (
    <div className={styles.container}>
      <Stack gap={8}>
        {notificationStore.notifications.map((notification) => (
          <NotificationCard
            key={notification.id}
            notification={notification}
            onRemove={() =>
              notificationStore.removeNotification(notification.id)
            }
          />
        ))}
      </Stack>
    </div>
  );
}

export const NotificationList = inject('notificationStore')(
  observer(Notifications),
);
