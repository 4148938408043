import React from 'react';
import styles from './CommentCard.module.css';
import { Comment } from '../../../types';
import { useAdaptiveLayout } from '../../../utils/hooks';
import { Text, Spacer, Icon } from '../..';
import { Space } from '../../layout';
import { ProfileImage } from '../ProfileImage/ProfileImage';
import { IconEnum } from '../../base';

interface Props {
  comment: Comment;
  layout?: "basic"|"full";
  onDelete?: (commentId: number) => void;
}

export function CommentCard(props: Props) {
  const {
    comment,
    layout = "full",
    onDelete
  } = props;
  const { isDesktop } = useAdaptiveLayout();

  const spacing: Space = isDesktop ? 16 : 8;

  return (
    <div className={styles.container}>
      {layout === "full" && (<>
        <ProfileImage
          name={comment.commenter.name}
          size={isDesktop ? 'regular' : 'small'}
        />

        <Spacer horizontal={spacing} />
      </>)}

      <div className={styles.comment}>
        <div className={styles.body}>
          <header className={styles.header}>
            <Text bold>
              {formatDate(new Date(comment.created_at))} | {comment.commenter.name}
              :
            </Text>

            {renderDelete()}
          </header>
          <Spacer vertical={4} />
          <Text>{comment.comment}</Text>
        </div>
      </div>
    </div>
  );

  function renderDelete() {
    if (!onDelete) return null;

    return (
      <>
        <Spacer horizontal={16} />
        <button onClick={deleteComment} className={styles.delete}>
          <Icon icon={IconEnum.DELETE} />
        </button>
      </>
    );
  }

  function deleteComment() {
    const confirm = window.confirm('Permanently delete this comment?');
    confirm && onDelete && onDelete(comment.id);
  }

  function formatDate(date: Date): string {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC',
    }).format(date);
  }
}
