import { getCssVariableName } from '../../utils/functions';

export const SpaceValues = [0, 4, 8, 16, 24, 32] as const;

SpaceValues.forEach((value) => {
  const _name = _getCssSpaceName(String(value));
  document.documentElement.style.setProperty(_name, `${value}px`);
});

/** Don't use this in product code, used only for Style Guide */
export function _getCssSpaceName(name: string) {
  return getCssVariableName(String(name), 'space');
}
