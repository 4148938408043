import React, { useState } from 'react';
import styles from './ImportUsersModal.module.css';
import {
  Button,
  Color,
  File as FileInput,
  Heading,
  Modal,
  Spacer,
  Table,
  Text,
} from '../..';
import { parse } from 'papaparse';
import isMatch from 'lodash.ismatch';
import { Column } from 'react-table';
import { inject, observer } from 'mobx-react';
import { IClientStore } from '../../../stores';

interface User {
  name: string,
  email: string,
  manager_email: string,
  delete: string,
}

interface Props {
  clientStore?: IClientStore,
  isVisible: boolean;
  onClose: () => void;
  onSend: (files: File) => void;
}

export function ImportUsersModalFunction(props: Props) {
  const {
    isVisible,
    onClose,
    onSend
  } = props;

  const clientStore = props.clientStore!;

  const [file, setFile] = useState<File|undefined>(undefined);
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState<string>('');

  const userFields = ['name', 'email', 'manager_email', 'delete'];

  const columns: Array<Column<User>> = [{
    Header: 'Name',
    accessor: 'name',
  }, {
    Header: 'Email',
    accessor: 'email',
  }, {
    Header: 'Manager Email',
    accessor: 'manager_email',
  }, {
    Header: 'Delete?',
    accessor: 'delete',
  }];

  function hasValidHeaders(fields: Array<string>) {
    return isMatch(fields, userFields);
  }

  function importUsers({ data, meta }: any) {
    if (!hasValidHeaders(meta.fields)) {
      setError(`The csv must have headers of ${userFields.join(', ')}`);
      return;
    }

    const users: User[] = [];

    for (let i = 0; i < data.length; i++) {
      const user = data[i];
      const keys = Object.keys(user);

      if (!isMatch(keys, userFields)) continue;

      users.push(user);
    }

    setUsers(users);
  }

  function parseFile(file: File) {
    const args = {
      header: true,
      preview: 7,
      complete: importUsers,
    };

    parse(file, args);
  }

  function onFileChange({ target }: React.ChangeEvent<HTMLInputElement>) {
    const { files } = target;
    setError('');
    setUsers([]);
    if (!files) return;
    setFile(files[0]);
    parseFile(files[0]);
  }

  return (
    <Modal isOpen={isVisible} onClose={onClose}>
      <div className={styles.container}>
        <Heading level={3}>Import Users</Heading>

        <Spacer vertical={8} />

        <Text size={14} color={Color.GREY} align="center">
          Choose a csv file to import users into Plaudify.<br />
          The csv must follow this <button
            className={styles.templateButton}
            onClick={() => downloadTemplate()}
          >
            template
          </button>
        </Text>

        <Spacer vertical={32} />

        <FileInput
          onChange={onFileChange}
          accept=".csv"
          error={error}
        />

        <Spacer vertical={32} />

        {users.length > 0 && <>
          <Text>Verify the following data is correct. Only a subset is shown.</Text>
          <Table
            columns={columns}
            data={users}
          />
        </>}

        <div className={styles.actions}>
          <Button
            role="primary"
            onClick={() => {
              if (file)
              {
                setUsers([]);
                onSend(file);
              }
            }}
            disabled={!file || !users.length}
          >
            Import
          </Button>
        </div>
      </div>
    </Modal>
  );

  function downloadTemplate() {
    clientStore.getImportTemplate();
  }
}

export const ImportUsersModal = inject(
  'clientStore'
)(observer(ImportUsersModalFunction));
