export function evaluationPeriodYearFormat(evaluation_period_end: string) {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    timeZone: 'UTC',
  }).format(new Date(evaluation_period_end));
}

export function createdAtDateFormat(created_at: string) {
  return new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'numeric',
    timeZone: 'UTC',
  }).format(new Date(created_at));
}

export function dateMonthView(created_at: string) {
  const date = new Date(created_at);
  // @todo: find a more elegant way to handle timezone, for now, this works fine
  return date.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
}
