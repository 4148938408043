import React, { useState, useEffect, useRef } from 'react';

import styles from './High5ListModal.module.css';

import { useAdaptiveLayout, scrollInView } from '../../../utils/hooks';

import { CompanyValue, Employee, HighFive, User } from '../../../types';
import {
  Text,
  Icon,
  IconEnum,
  Heading,
  Divider,
  Color,
  Spacer,
  ProfileImage,
  Inline,
  Stack,
} from '../../../components';

interface Props {
  highFives: HighFive[];
  companyValues: CompanyValue[];
  employee: Employee;
  expandable?: boolean;
  highlightedHigh5Id?: string | null;
}

// not sure if this is better than the previous way
// but in this type I don't want to have client_id
type CompanyValueWithHighFives = Omit<CompanyValue, 'client_id'> & {
  highFives: HighFive[];
};
//TODO - rename this compnent to High5List
export function High5ListModal(props: Props) {
  const {
    highFives,
    companyValues,
    employee,
    expandable,
    highlightedHigh5Id,
  } = props;

  const [companyValuesWithHigh5s, setCompanyValuesWithHigh5s] = useState<
    CompanyValueWithHighFives[]
  >([]);

  const [openedCompanyValues, setOpenedCompanyValues] = useState<string[]>(
    expandable ? [] : companyValuesWithHigh5s.map((c) => c.name),
  );

  const [highlightedHighFive, setHighlightedHighFive] = useState<HighFive>();

  const { isDesktop } = useAdaptiveLayout();

  const refCompanyValue = useRef<HTMLDivElement>(null);
  const refComment = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const companyValuesWithHigh5s = addHighFivesToCompanyValues(
      companyValues,
      highFives,
    );
    setCompanyValuesWithHigh5s(companyValuesWithHigh5s);
  }, [companyValues, highFives]);

  useEffect(() => {
    if (!highlightedHigh5Id) return;

    const targetHigh5 = highFives.find(
      (high5) => high5.id === +highlightedHigh5Id,
    );

    if (targetHigh5) {
      setHighlightedHighFive(targetHigh5);
      const expandedCompanyValue = companyValuesWithHigh5s.find(
        (c) => c.id === targetHigh5.client_company_value_id,
      );

      if (targetHigh5.comment)
        expandedCompanyValue &&
          setOpenedCompanyValues([expandedCompanyValue.name]);
    }
  }, [companyValuesWithHigh5s, highFives, highlightedHigh5Id]);

  useEffect(() => {
    if (highlightedHighFive) {
      setTimeout(() => {
        highlightedHighFive.comment
          ? scrollInView(refComment.current)
          : scrollInView(refCompanyValue.current);
      }, 100);
    }
  }, [highlightedHighFive]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <Text color={Color.GREY}>{employee.name}</Text>
          <Spacer vertical={4} />
          <Heading level={2}>Applause</Heading>
        </div>
        <div
          onClick={toggleAllCompanyValues}
          className={`${styles.high_fives_info} ${styles.right} ${
            expandable && styles.expandable
          }`}>
          <Icon icon={IconEnum.HIGH5} size={45} />

          <span className={styles.high_fives_count}>{highFives.length}</span>

          {isDesktop && (
            <>
              {highFives.length === 0 ? (
                <Text inline color={Color.GREY}>
                  No applause yet
                </Text>
              ) : (
                <div>{renderAuthors(highFives)}</div>
              )}
            </>
          )}
        </div>
      </div>
      <Divider />
      <div className={styles.company_values}>
        {companyValuesWithHigh5s.map((companyValue) => (
          <React.Fragment key={companyValue.id}>
            {renderCompanyValue(companyValue)}
            {companyValue.id && <Divider />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );

  function renderCompanyValue(companyValue: CompanyValueWithHighFives) {
    const { highFives, name } = companyValue;
    return (
      <>
        <div
          onClick={() => toggleCompanyValue(name)}
          ref={
            !highlightedHighFive?.comment &&
            companyValue.id === highlightedHighFive?.client_company_value_id
              ? refCompanyValue
              : null
          }
          className={`${styles.company_value} ${
            expandable && styles.expandable
          }`}>
          <Text size={16} bold={openedCompanyValues.includes(name)}>
            {name}
          </Text>

          <div className={styles.right}>
            <div className={styles.high_five_container}>
              <div className={styles.high_five}>
                <div
                  style={{
                    opacity: `${highFives.length === 0 ? 0.5 : 1}`,
                  }}
                  className={styles.icon_with_count}>
                  <div className={styles.icon_wrapper}>
                    <Icon icon={IconEnum.HIGH5} size={32} />
                  </div>

                  <Spacer horizontal={8} />
                  <Text inline size={16}>
                    {highFives.length}
                  </Text>
                </div>
                <Spacer horizontal={24} />

                {isDesktop && (
                  <>
                    {highFives.length === 0 ? (
                      <Text inline color={Color.GREY}>
                        No applause yet
                      </Text>
                    ) : (
                      <div>{renderAuthors(highFives)}</div>
                    )}
                  </>
                )}
              </div>
              {expandable && (
                <div className={styles.arrow_icon}>
                  <Icon
                    icon={
                      openedCompanyValues.includes(name)
                        ? IconEnum.CHEVRON_UP
                        : IconEnum.CHEVRON_DOWN
                    }
                    size={16}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {openedCompanyValues.includes(name) && (
          <React.Fragment>
            {renderComments(highFives)}
            <Spacer vertical={24} />
          </React.Fragment>
        )}
      </>
    );
  }

  function renderAuthors(highFives: HighFive[]) {
    const authors: { [id: number]: User } = {};

    highFives.forEach((highFive) => {
      if (!authors[highFive.author_id]) {
        authors[highFive.author_id] = highFive.author;
      }
    });

    const authorsCount = Object.keys(authors).length;

    const sortedAuthors = Object.values(authors).sort((a, b) =>
      a.manager_id > b.manager_id ? 1 : -1,
    );

    return (
      <div>
        <Text color={Color.GREY} size={11}>
          from
        </Text>
        <div className={styles.authors}>
          <Inline gap={4}>
            {sortedAuthors.map((author: User, index: number) => {
              if (index > 1) return '';
              return (
                <Text
                  key={author.id}
                  color={!author.manager_id ? Color.SECONDARY : Color.DARK_GREY}
                  inline>
                  {author.name}
                  {index === 0 && authorsCount > 1 ? ', ' : ''}
                </Text>
              );
            })}
          </Inline>
          <Spacer horizontal={4} />
          {authorsCount > 2 && (
            <div className={styles.other_authors}>
              <Text color={Color.GREY} inline>
                and {authorsCount - 2} other
              </Text>
              <div className={styles.other_authors_list}>
                <Stack gap={4}>
                  {Object.values(sortedAuthors)
                    .slice(2)
                    .map((author) => (
                      <Text key={author.id}>{author.name}</Text>
                    ))}
                </Stack>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderComments(highFives: HighFive[]) {
    return (
      <div className={styles.comments}>
        <span />
        <div className={styles.right}>
          <Stack gap={16}>
            {highFives.map((highFive) => {
              const { comment, author, created_at } = highFive;
              const isManager = author.manager_id === null;

              if (!comment) return '';

              return (
                <Inline key={author.id} gap={16} align="start">
                  <ProfileImage name={author.name} />

                  <div
                    ref={
                      highlightedHighFive?.comment &&
                      highFive.id === highlightedHighFive?.id
                        ? refComment
                        : null
                    }
                    className={styles.comment}>
                    <Text
                      color={isManager ? Color.SECONDARY : Color.DARK_GREY}
                      bold>
                      {formatDate(new Date(created_at))} | {author.name}
                    </Text>

                    <Spacer vertical={4} />
                    <Text color={Color.DARK_GREY}>{comment}</Text>
                  </div>
                </Inline>
              );
            })}
          </Stack>
        </div>
      </div>
    );
  }

  function toggleAllCompanyValues() {
    if (openedCompanyValues.length > 0) {
      setOpenedCompanyValues([]);
    } else {
      setOpenedCompanyValues(companyValuesWithHigh5s.map((c) => c.name));
    }
  }

  function toggleCompanyValue(name: string) {
    if (openedCompanyValues.includes(name)) {
      setOpenedCompanyValues(
        openedCompanyValues.filter((value) => value !== name),
      );
    } else {
      setOpenedCompanyValues((isOpen) => [...isOpen, name]);
    }
  }
}

function addHighFivesToCompanyValues(
  companyValues: CompanyValue[],
  highFives: HighFive[],
): CompanyValueWithHighFives[] {
  const companyValuesWithOtherValue = [
    ...companyValues,
    { id: null, name: 'Other', highFives: [] },
  ];

  return companyValuesWithOtherValue.map((companyValue) => {
    const companyValueHigh5s: HighFive[] = [];

    highFives.forEach((high5) => {
      if (high5.client_company_value_id === companyValue.id) {
        companyValueHigh5s.push(high5);
      }
    });

    return { ...companyValue, highFives: companyValueHigh5s };
  });
}

//@TODO move this function in a utils folder maybe? - we use it for comments as well
function formatDate(date: Date): string {
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  }).format(date);
}

High5ListModal.defaultProps = {
  expandable: true,
};
