import React, { useEffect } from 'react';
import { High5Modal } from '..';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { IHigh5Store, IUserStore } from '../../../stores';

type Props = {
  high5Store?: IHigh5Store;
  userStore?: IUserStore;
};

function High5ModalContainerWithoutStore(props: Props) {
  const high5Store = props.high5Store!;
  const userStore = props.userStore!;

  const isLoggedIn = userStore.isAuthenticated();

  useEffect(() => {
    if (!isLoggedIn) return;
    high5Store.fetchCompanyValues();
    high5Store.fetchUsers();
  }, [high5Store, isLoggedIn, userStore.user.id]);

  return (
    <High5Modal
      isVisible={high5Store.isVisible}
      companyValues={high5Store.companyValues}
      onClose={() => high5Store.hideModal()}
      onSend={({ comment, company_value_id, user_id }) =>
        high5Store.addHighFive(comment, company_value_id, user_id)
      }
      selected={toJS(high5Store.selected)}
      users={high5Store.users}
    />
  );
}

export const High5ModalContainer = inject(
  'high5Store',
  'userStore',
)(observer(High5ModalContainerWithoutStore));
