import React from 'react';
import styles from './Button.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  children: React.ReactNode;
  role?: 'primary' | 'secondary' | 'tertiary';
  disabled?: boolean;
  size?: 'small' | 'normal';
  wrap?: boolean;
  onClick: () => void;
}

export function Button(props: Props) {
  const { children, onClick, role, disabled, size, wrap } = props;

  const btnStyles = cx({
    btn: true,
    primary: role === 'primary',
    secondary: role === 'secondary',
    tertiary: role === 'tertiary',
    small: size === 'small',
    noWrap: !wrap,
  });

  return (
    <button onClick={handleClick} className={btnStyles} disabled={disabled}>
      {children}
    </button>
  );

  function handleClick() {
    if (disabled) return;
    onClick();
  }
}

Button.defaultProps = {
  size: 'normal',
  role: 'primary',
  wrap: true,
};
