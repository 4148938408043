import { RootStore } from './index';
import { decorate, action, runInAction, observable, configure } from 'mobx';
import { HighFive, CompanyValue, Employee, User } from '../types';
import { getUser } from '../services/AuthRepository';
import { api } from '../services/http';

configure({ enforceActions: 'observed' });

export const FETCH_COMPANY_VALUES = 'FETCH_COMPANY_VALUES';
export const FETCH_HIGH_FIVES = 'FETCH_HIGH_FIVES';
export const ADD_HIGH_FIVE = 'ADD_HIGH_FIVE';

export interface IHigh5Store {
  users: User[];
  highFives: HighFive[];
  companyValues: CompanyValue[];

  isVisible: boolean;
  selected?: User;
  showModal(employee?: User): void;
  hideModal(): void;

  fetchHighFives(employeeId: number): Promise<void>;
  addHighFive(
    comment: string,
    company_value_id: number | null,
    user_id: number,
  ): Promise<void>;
  addCompanyValue(companyValue: CompanyValue): void;
  createCompanyValue(payload: CompanyValuePayload): Promise<CompanyValue|undefined>;
  updateCompanyValue(companyValueId: number, payload: CompanyValuePayload): Promise<CompanyValue|undefined>;
  fetchCompanyValues(): Promise<void>;
  fetchUsers(): Promise<void>;
  reset(): void;
  setCompanyValue(value: CompanyValue): void;
}

export default class High5Store implements IHigh5Store {
  stores: RootStore;

  users = Array<User>();
  highFives = Array<HighFive>();
  companyValues = Array<CompanyValue>();

  isVisible = false;
  selected?: User = undefined;

  constructor(stores: RootStore) {
    this.stores = stores;
  }

  showModal(employee?: Employee): void {
    employee && (this.selected = employee);
    this.isVisible = true;
  }

  hideModal(): void {
    this.isVisible = false;
    this.selected = undefined;
  }

  async fetchHighFives(employeeId: number) {
    this.stores.loadingStore.start(FETCH_HIGH_FIVES);
    try {
      const check_in_cycle_id = this.stores.clientStore.checkInCycle.id
      const highFives = await getHighFives(employeeId, check_in_cycle_id);

      runInAction(() => {
        this.highFives = highFives;
      });
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_HIGH_FIVES);
    }
  }

  async addHighFive(
    comment: string,
    company_value_id: number | null,
    user_id: number,
  ) {
    this.stores.loadingStore.start(ADD_HIGH_FIVE);
    try {
      await addHighFive(comment, company_value_id, user_id);
      this.stores.employeeStore.increaseHighFiveCount(user_id);
      this.stores.notificationStore.triggerSuccessNotification(
        'Applause successfully sent!',
      );
    } catch (error) {
      console.warn(error);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
    } finally {
      this.stores.loadingStore.end(ADD_HIGH_FIVE);
    }
  }

  addCompanyValue(companyValue: CompanyValue): void {
    this.companyValues.push(companyValue);
  }

  async createCompanyValue(payload: CompanyValuePayload): Promise<CompanyValue | undefined> {
    this.stores.loadingStore.start(ADD_HIGH_FIVE);

    try {
      const { user } = this.stores.userStore;
      const companyValue = await createCompanyValue(user.client_id, payload);
      this.addCompanyValue(companyValue);
      return companyValue;
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(ADD_HIGH_FIVE);
    }
  }

  async updateCompanyValue(companyValueId: number, payload: CompanyValuePayload): Promise<CompanyValue | undefined> {
    this.stores.loadingStore.start(ADD_HIGH_FIVE);

    try {
      const companyValue = await updateCompanyValue(companyValueId, payload);
      this.setCompanyValue(companyValue);
      return companyValue;
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(ADD_HIGH_FIVE);
    }
  }

  setCompanyValue(value: CompanyValue): void {
    const idx = this.companyValues.findIndex(({ id }) => id === value.id);

    if (idx === -1) return;

    this.companyValues[idx] = value;
  }

  async fetchCompanyValues() {
    // these usually don't change during an evaluation cycle
    if (this.companyValues.length) {
      return;
    }

    this.stores.loadingStore.start(FETCH_COMPANY_VALUES);
    try {
      const companyValues = await getCompanyValues();

      runInAction(() => {
        this.companyValues = companyValues;
      });
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_COMPANY_VALUES);
    }
  }

  async fetchUsers() {
    // @todo this might be too brutal for this, maybe refresh once every day
    if (this.users.length) {
      return;
    }

    try {
      const users = await getUsers();
      runInAction(() => {
        this.users = users;
      });
    } catch (error) {
      console.warn(error);
    }
  }

  reset() {
    this.users = [];
    this.highFives = [];
    this.companyValues = [];
    this.isVisible = false;
    this.selected = undefined;
  }
}

decorate(High5Store, {
  highFives: observable,
  companyValues: observable,
  isVisible: observable,
  selected: observable,

  showModal: action,
  hideModal: action,
  reset: action,
  fetchHighFives: action,
  addHighFive: action,
  addCompanyValue: action,
  createCompanyValue: action,
  fetchCompanyValues: action,
  setCompanyValue: action,
});

async function getCompanyValues(): Promise<CompanyValueResponse> {
  const { client_id } = getUser();
  const response = await api.get(`clients/${client_id}/values`);
  return response.data;
}

async function getHighFives(id: number, check_in_cycle: number): Promise<GetHighFivesResponse> {
  const response = await api.get(`employees/${id}/high-fives`, {
    params: { check_in_cycle }
  });
  return response.data;
}

// @todo not sure if this is the best place
async function getUsers(): Promise<GetUsersResponse> {
  const response = await api.get(`users`);
  return response.data;
}

async function addHighFive(
  comment: string,
  client_company_value_id: number | null,
  user_id: number,
) {
  const response = await api.post(`employees/${user_id}/high-fives`, {
    comment,
    client_company_value_id,
  });

  return response.data;
}

async function createCompanyValue(clientId: number, payload: CompanyValuePayload): Promise<CompanyValue> {
  const { data } = await api.post(`clients/${clientId}/values`, payload);
  return data;
}

async function updateCompanyValue(companyValueId: number, payload: CompanyValuePayload): Promise<CompanyValue> {
  const { data } = await api.put(`company-values/${companyValueId}`, payload);
  return data;
}

export type CompanyValuePayload = {
  name: string;
}

type CompanyValueResponse = Array<CompanyValue>;
type GetHighFivesResponse = Array<HighFive>;
type GetUsersResponse = Array<User>;
