import React, { useEffect, useState, } from "react";
import { ClientStandardsScore, Comment } from "../../../types";
import { CommentsList, Heading, Spacer } from '../../index';
import StandardsRatings from '../StandardsRatings/StandardsRatings';
import { IStandardStore } from "../../../stores";
import { inject, observer } from "mobx-react";
import styles from "./EmployeeScore.module.css";
import { autorun } from "mobx";

type Props = {
  score: ClientStandardsScore;
  standardStore?: IStandardStore;
}

function _Score(props: Props) {
  const standardStore = props.standardStore!;
  const { score } = props;
  const { standard } = score;
  const { reviews, scoreComments } = standardStore;
  const [comments, setComments] = useState<Comment[]>([]);
  const review = reviews.get(score.review_id);
  const isComplete = !!review?.completed_at;

  useEffect(
    () => { standardStore.fetchScoreComments(score.id) },
    [score.id, standardStore]
  );

  useEffect(
    () => autorun(() => setComments(scoreComments.get(score.id) ?? [])),
    [scoreComments, score.id]
  );

  return (
    <div className={styles.score}>
      <div className={styles.score__heading}>
        <Heading level={3}>{standard.name}</Heading>
      </div>

      <div className={styles.score__body}>
        <p>{standard.description}</p>

        <StandardsRatings
          score={score.score||undefined}
          onClick={(record) => standardStore.updateScore(score, record)}
          disabled={isComplete}
        />

        <Spacer vertical={32} />

        <CommentsList
          canComment={false}
          comments={comments}
          layout={"basic"}
        />
      </div>
    </div>
  );
}

export const EmployeeScore = inject(
  'standardStore'
)(observer(_Score));
