import { getCssVariableName } from '../../utils/functions';

export enum Color {
  PRIMARY = '#ed2e38',
  SECONDARY = '#8d3534',
  DARK_GREY = '#3f3737',
  GREY = '#999999',
  LIGHT_GREY = '#e3e3e3',
  WHITE = '#ffffff',
}

Object.entries(Color).forEach(([name, value]) => {
  const _name = _getCssColorName(name);
  document.documentElement.style.setProperty(_name, value);
});

/** Don't use this in product code, used only for Style Guide */
export function _getCssColorName(name: string) {
  return getCssVariableName(name, 'color');
}
