import { CheckInCycle, Goal, GoalProgress, GoalStatus } from '../types';
import { RootStore } from './index';
import {
  decorate,
  observable,
  action,
  runInAction,
  computed,
  configure,
} from 'mobx';
import { api } from '../services/http';
import { compareDates } from '../utils/functions';

configure({ enforceActions: 'observed' });

export const FETCH_GOALS = 'FETCH_GOALS';
export const FETCH_GOAL = 'FETCH_GOAL';
export const ADD_GOAL = 'ADD_GOAL';
export const EDIT_GOAL = 'EDIT_GOAL';
export const UPDATE_GOAL_PROGRESS = 'UPDATE_GOAL_PROGRESS';
export const UPDATE_GOAL_STATUS = 'UPDATE_GOAL_STATUS';

export interface IGoalStore {
  goals: GoalsList;
  showAllGoals: boolean;

  filteredGoals: GoalsList;
  inactiveGoals: GoalsList;
  activeGoals: GoalsList;

  setShowAllGoals(value: boolean): void;
  fetchGoals(employeeId: number): Promise<void>;
  incrementCommentCount(goalId: number): void;
  decrementCommentCount(goalId: number): void;
  changeStatus(newStatus: GoalStatus, goalId: number): Promise<void>;
  changeProgress(progress: number, goalId: number): Promise<void>;
  resetCheckInReviewForGoal(goalId: number): void;
  deleteGoal(goalId: number): Promise<void>;
  getGoal(goalId: number): Promise<Goal | undefined>;
  addGoal(employeeId: number, goal: GoalPayload): Promise<void>;
  editGoal(goal: GoalPayload): Promise<void>;
  reset(): void;
}

const EMPTY_GOALS: GoalsList = [];

export default class GoalStore implements IGoalStore {
  stores: RootStore;

  showAllGoals = false;
  goals = EMPTY_GOALS;

  constructor(stores: RootStore) {
    this.stores = stores;
  }

  setShowAllGoals(value: boolean): void {
    this.showAllGoals = value;
  }

  get filteredGoals() {
    if (this.activeGoals.length === 0) {
      return this.goals;
    }

    const checkInCycle = this.stores.clientStore.checkInCycle;
    const collection = this.showAllGoals
    ? [...this.activeGoals, ...this.inactiveGoals]
    : this.activeGoals;

    return collection.filter((item) => compareDates(item.goal.deadline, checkInCycle.start_date, '>='));
  }

  get notApprovedGoals() {
    return this.getGoalsByStatus(GoalStatus.NOT_APPROVED);
  }

  get inProgressGoals() {
    return this.getGoalsByStatus(GoalStatus.IN_PROGRESS);
  }

  get pendingGoals() {
    return this.getGoalsByStatus(GoalStatus.PENDING);
  }

  get completedGoals() {
    return this.getGoalsByStatus(GoalStatus.COMPLETED);
  }

  get notCompletedGoals() {
    return this.getGoalsByStatus(GoalStatus.NOT_COMPLETED);
  }

  get obsoleteGoals() {
    return this.getGoalsByStatus(GoalStatus.OBSOLETE);
  }

  get activeGoals() {
    return [
      ...this.notApprovedGoals,
      ...this.inProgressGoals,
      ...this.pendingGoals,
    ];
  }

  get inactiveGoals() {
    return [
        ...this.completedGoals,
        ...this.notCompletedGoals,
        ...this.obsoleteGoals,
    ];
  }

  getGoalsByStatus(status: GoalStatus) {
    return this.goals
      .filter((item) => status === item.initial_status)
      .sort((item1, item2) => item2.goal.id - item1.goal.id);
  }

  async fetchGoals(employeeId: number): Promise<void> {
    if (!employeeId) return;

    this.stores.loadingStore.start(FETCH_GOALS);

    // this is needed when fetching a different Employee when another Employee is already loaded
    // i.e. when accessing Periodic Review Tasks
    if (this.stores.employeeStore.employee?.id !== employeeId) {
      this.stores.employeeStore.resetEmployee();
      this.setGoals([]);
    }

    try {
      const checkInCycleID = this.stores.clientStore.checkInCycle.id;
      const response = await getEmployeeGoals(employeeId, checkInCycleID);
      const goalsList = transformGoalsResponse(response);
      this.setGoals(goalsList);
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(FETCH_GOALS);
    }
  }

  async changeStatus(status: GoalStatus, goal_id: number): Promise<void> {
    this.stores.loadingStore.start(UPDATE_GOAL_STATUS);

    const index = this.goals.findIndex(({ goal }) => goal.id === goal_id);

    if (index === -1) throw new Error(`Goal with ID ${goal_id} not found`);

    const { goal } = this.goals[index];

    const initial_status = goal.status;

    try {
      goal.status = status;

      this.goals.splice(index, 1, { ...this.goals[index], goal });

      const response = await changeGoalStatus({ goal_id, status });

      this.stores.notificationStore.triggerSuccessNotification("Goal status successfully changed");

      runInAction(() => {
        this.goals.splice(index, 1, { ...this.goals[index], goal: response });
      });
    } catch (error) {
      runInAction(() => {
        goal.status = initial_status;

        this.goals.splice(index, 1, { ...this.goals[index], goal });
      });

      this.stores.notificationStore.triggerErrorNotification("something went wrong");

      console.warn(error);
    } finally {
      this.stores.loadingStore.end(UPDATE_GOAL_STATUS);
    }
  }

  async changeProgress(progress: number, goalId: number) {
    this.stores.loadingStore.start(UPDATE_GOAL_PROGRESS);
    try {
      const updatedGoal = await updateGoalProgress({
        goal_id: goalId,
        progress,
      });
      const currentGoalIndex = this.goals.findIndex(
        (item) => item.goal.id === goalId,
      )!;

      runInAction(() => {
        this.goals[currentGoalIndex].goal = updatedGoal;
      });
    } catch (error) {
      console.warn(error);
    } finally {
      this.stores.loadingStore.end(UPDATE_GOAL_PROGRESS);
    }
  }

  async deleteGoal(goalId: number): Promise<void> {
    try {
      await deleteGoal(goalId);
      this.stores.notificationStore.triggerSuccessNotification(
        'Goal successfully deleted!',
      );

      runInAction(() => {
        const index = this.getGoalIndex(goalId);
        this.goals.splice(index, 1);
      });
    } catch (error) {
      console.warn(error);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
    }
  }

  incrementCommentCount(goalId: number): void {
    const goal = this.getGoalById(goalId);
    if (goal) {
      goal.comments_count++;
    }
  }

  decrementCommentCount(goalId: number): void {
    const goal = this.getGoalById(goalId);
    if (goal) {
      goal.comments_count--;
    }
  }

  setGoals(goals: GoalsList) {
    const statusOrder = [
      GoalStatus.NOT_APPROVED,
      GoalStatus.IN_PROGRESS,
      GoalStatus.PENDING,
      GoalStatus.COMPLETED,
      GoalStatus.NOT_COMPLETED,
      GoalStatus.OBSOLETE,
    ];

    const sorted = statusOrder.flatMap((status) =>
      goals.filter((g) => g.initial_status === status),
    );

    this.goals = sorted;
  }

  resetCheckInReviewForGoal(goalId: number) {
    const item = this.getGoalById(goalId);
    item && (item.check_in_review = null);
  }

  getGoalById(goalId: number) {
    return this.goals.find((item) => item.goal.id === goalId);
  }

  async getGoal(goalId: number) {
    this.stores.loadingStore.start(FETCH_GOAL);
    try {
      return await getGoalDetails(goalId);
    } catch (e) {
      console.warn(e);
    } finally {
      this.stores.loadingStore.end(FETCH_GOAL);
    }
  }

  async addGoal(employeeId: number, goal: GoalPayload): Promise<void> {
    this.stores.loadingStore.start(ADD_GOAL);

    try {
      await addGoal(employeeId, goal);

      this.stores.notificationStore.triggerSuccessNotification(
        'Goal successfully added!',
      );

      await this.fetchGoals(employeeId);

      await this.stores.taskStore.fetchTasks();

    } catch (e) {
      console.warn(e);

      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );

    } finally {
      this.stores.loadingStore.end(ADD_GOAL);
    }
  }

  async editGoal(goal: GoalPayload): Promise<void> {
    this.stores.loadingStore.start(EDIT_GOAL);
    try {
      await editGoal(goal);
      this.stores.notificationStore.triggerSuccessNotification(
        'Goal successfully updated!',
      );
    } catch (e) {
      console.warn(e);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
    } finally {
      this.stores.loadingStore.end(EDIT_GOAL);
    }
  }

  getGoalIndex(goalId: number) {
    return this.goals.findIndex((item) => item.goal.id === goalId);
  }

  reset() {
    this.goals = EMPTY_GOALS;
    this.showAllGoals = false;
  }
}

function transformGoalsResponse(goals: GoalResponse[]): GoalsList {
  return goals.map((goal) => {
    const { comments_count, check_in_review } = goal;

    return {
      comments_count,
      check_in_review,
      goal,
      initial_status: goal.status,
    };
  });
}

decorate(GoalStore, {
  goals: observable,
  showAllGoals: observable,

  filteredGoals: computed,
  activeGoals: computed,
  inactiveGoals: computed,

  setShowAllGoals: action,
  setGoals: action,
  incrementCommentCount: action,
  decrementCommentCount: action,
  changeStatus: action,
  changeProgress: action,
  resetCheckInReviewForGoal: action,
  reset: action,
});

async function getEmployeeGoals(employeeID: number, check_in_cycle: number) {
  const { data } = await api.get(
    `employees/${employeeID}/goals`,
    { params: { check_in_cycle } }
  );
  return data.data;
}

async function changeGoalStatus(payload: ChangeStatusPayload) {
  const { goal_id, status } = payload;
  const { data } = await api.post<Goal>(
    `goals/${goal_id}/change-status`,
    { status }
  );
  return data;
}

async function updateGoalProgress(payload: GoalProgressPayload) {
  const { goal_id, progress } = payload;
  const response = await api.post<Goal>(`goals/${goal_id}/update-progress`, {
    progress,
  });

  return response.data;
}

async function deleteGoal(goalId: number): Promise<void> {
  await api.delete(`goals/${goalId}`);
}

async function getGoalDetails(goalId: number) {
  const { data } = await api.get(`goals/${goalId}`);
  return data.data;
}

async function addGoal(employeeId: number, goalPayload: GoalPayload) {
  const { data } = await api.post(
    `employees/${employeeId}/goals`,
    goalPayload,
  );
  return data.data;
}

async function editGoal(goal: GoalPayload): Promise<Goal> {
  return await api.patch(`goals/${goal.id}`, goal);
}

export type GoalsList = Array<{
  goal: Goal;
  comments_count: number;
  check_in_review: CheckInCycle | null;
  /** used for UI only: filtering goals based on status */
  initial_status: GoalStatus;
}>;

type GoalResponse = Goal & {
  comments_count: number;
  check_in_review: CheckInCycle | null;
};

type ChangeStatusPayload = {
  status: GoalStatus;
  goal_id: number;
};

type GoalProgressPayload = {
  goal_id: number;
  progress: number;
};

export type GoalPayload = {
  id?: number;
  title: string;
  description: string;
  progress_type: GoalProgress | null;
  progress_target: number;
  deadline?: string;
  status?: string;
  comment?: string;
  client_evaluation_period_id: number;
};
