import React, { useMemo, useState } from 'react';
import styles from './CreateUserScreen.module.css';
import { inject, observer } from 'mobx-react';
import { IClientStore, IEmployeeStore } from '../../stores';

import {
  Button,
  Card,
  Checkbox,
  Color,
  Content,
  Heading,
  Icon,
  IconEnum,
  Input,
  MultiSelect,
  Option,
  Select,
  Spacer,
  Text,
  Wrapper,
} from '../../components';
import { User } from '../../types';
import { UserCreate } from '../../stores/clientStore';
import { useHistory } from 'react-router';

interface Props {
  clientStore: IClientStore;
  employeeStore: IEmployeeStore;
}

function CreateUserScreen(props: Props) {
  const clientStore = props.clientStore!;
  const employeeStore = props.employeeStore!;
  const history = useHistory();

  const managers: Array<User> = useMemo(() => clientStore.users
    .filter(user => clientStore.users
      .some(u => u.manager_id === user.id)
      || user.manager_id === null
    ),
    [clientStore.users]
  );

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [manager, setManager] = useState<number|null>(null);
  const [isManager, setIsManager] = useState<boolean>(false);
  const [employees, setEmployees] = useState<Array<Option<number>>>(
    clientStore.users.map(user => ({
      label: user.name,
      value: user.id,
      selected: false,
    }))
  );

  const isDisabled = useMemo(
    () => {
      return name.length === 0 || email.length === 0 || password.length === 0
    },
    [name, email, password]
  );

  return (
    <Wrapper>
      <Content
        title="Create User"
        header={
          <>
            <a href="/users">
              <Icon
                icon={IconEnum.CHEVRON_LEFT}
                size={8}
                color={Color.GREY}
              />
              <Spacer horizontal={4} />
              Back
            </a>
            <Spacer vertical={8} />
            <Heading level={1}>Create User</Heading>
          </>
        }
        body={renderBody()}
      />
    </Wrapper>
  );

  function renderBody() {
    return (
      <Card className={styles.users}>
        <div className={styles.form}>
          { renderName() }

          { renderEmail() }

          { renderPassword() }

          { renderManager() }

          { renderIsManager() }

          {isManager && renderEmployees()}
        </div>

        <div className={styles.actions}>
          <Button
            onClick={createUser}
            disabled={isDisabled}
          >
            Create
          </Button>
        </div>
      </Card>
    );
  }

  function renderName()
  {
    return (
      <label>
        <Input
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Name"
        ></Input>
      </label>
    );
  }

  function renderEmail()
  {
    return (
      <label>
        <Input
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Email"
        ></Input>
      </label>
    );
  }

  function renderPassword()
  {
    return (
      <label>
        <Input
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="Password"
        ></Input>
      </label>
    );
  }

  function renderManager()
  {
    return (
      <label className={styles.managerInput}>
        <Select
          defaultValue={manager === null ? '' : manager.toString()}
          values={managers.map(manager => ({
            value: manager.id.toString(),
            label: manager.name,
          }))}
          onChange={e => setManager(Number(e.target.value))}
          placeholder="Select a Manager"
        />
      </label>
    );
  }

  function renderIsManager()
  {
    return (
      <label>
        <Text inline>Is a manager</Text>
        <Checkbox
          checked={isManager}
          onChange={e=>setIsManager(e.target.checked)}
        />
      </label>
    );
  }

  function renderEmployees()
  {
    return (
      <div className={styles.employees}>
        <MultiSelect
          label="Employees"
          onChange={values => setEmployees(values)}
          values={employees}
        />

        { renderManaging() }
      </div>
    );
  }

  function renderManaging()
  {
    function removeAll()
    {
      const selected = false;
      setEmployees(employees.map(user => ({ ...user, selected })))
    }

    return (
      <div className={styles.managing}>
        <div className={styles.managingHeader}>
          <Text>Managing</Text>

          {employees.some(user => user.selected) && <button
            type="button"
            title="Remove all employees"
            onClick={removeAll}
          >
            Remove All
            <Icon
              icon={IconEnum.DELETE}
              size={12}
              color={Color.PRIMARY}
            />
          </button>}
        </div>

        <div className={styles.managingBody}>
          {employees.map(employee => employee.selected && (
            renderEmployee(employee)
          ))}
        </div>
      </div>
    );
  }

  function renderEmployee(employee: Option<number>)
  {
    function removeEmployee()
    {
      const selected = false;
      setEmployees(employees.map(user =>
        user.value === employee.value
          ? { ...user, selected }
          : user
      ));
    }

    return (
      <div
        className={styles.managingEmployee}
        key={employee.value}
      >
        <button
          type="button"
          title="Remove Employee"
          onClick={removeEmployee}
        >
          <Icon
            icon={IconEnum.CLOSE}
            size={10}
            color={Color.PRIMARY}
          />
        </button>
        { employee.label }
      </div>
    );
  }

  async function createUser()
  {
    const userCreate: UserCreate = {
      client_id: 0,
      email,
      manager_id: manager,
      name,
      password,
    };

    const user = await clientStore.createUser(userCreate);

    if (user.id && isManager)
    {
      for (let i = 0; i < employees.length; i++) {
        const employee = employees[i];
        if (employee.selected)
        {
          await employeeStore.setManager(employee.value, user.id)
        }
      }
    }

    if (user.id)
    {
      await clientStore.fetchUsers();
      history.push('/users');
    }
  }
}

export default inject('clientStore', 'employeeStore')(observer(CreateUserScreen));
