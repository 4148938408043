import React from 'react';
import classNames from 'classnames/bind';
import styles from './ProfileImage.module.css';
import { Color } from '../../styles';

const cx = classNames.bind(styles);

type Props = {
  /** User full name, used for initials rendering and background color computing */
  name: string;
  size?: 'regular' | 'large' | 'small';
};

export function _ProfileImageUnoptimized(props: Props) {
  const { name = '', size = 'regular' } = props;

  const initials = getInitials(name);

  const style = cx({
    profile_image: true,
    [`profile_image_${size}`]: size !== 'regular',
  });

  const background = name ? stringToHslColor(name, 40, 70) : Color.LIGHT_GREY;

  return (
    <div style={{ background }} className={style}>
      <span>{initials.join(' ')}</span>
    </div>
  );
}

_ProfileImageUnoptimized.displayName = 'ProfileImage';
_ProfileImageUnoptimized.defaultProps = {
  size: 'regular',
};

function stringToHslColor(
  str: string,
  saturation: number,
  lightness: number,
): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
}

function getInitials(name: string): string[] {
  const initials = name
    .split(' ')
    .filter(ignorePrefixSuffix)
    .map((s) => s[0]);

  if (initials.length > 2) {
    return [initials[0], initials[initials.length - 1]];
  }

  return initials;
}

function ignorePrefixSuffix(str: string) {
  return str[str.length - 1] !== '.';
}

export const ProfileImage = React.memo(_ProfileImageUnoptimized);
