import { RootStore } from "./index";
import { decorate, observable, action, runInAction, configure } from 'mobx';
import { api } from '../services/http';
import { getClient } from '../services/ClientRepository';
import {
  EMPTY_CHECK_IN_CYCLE,
  EMPTY_CLIENT,
  EMPTY_EVALUATION_PERIOD
} from './clientStore';
import {
  EvaluationPeriod,
  CheckInCycle,
  EvaluationPeriodTypes,
  CheckInCycleTypes
} from "../types";


configure({ enforceActions: 'observed' });

export const FETCH_TIME_FRAMES = 'FETCH_TIME_FRAMES';
export const CREATE_EVALUATION_PERIOD = 'CREATE_EVALUATION_PERIOD';
export const DELETE_EVALUATION_PERIOD = 'DELETE_EVALUATION_PERIOD';
export const UPDATE_CHECK_IN_CYCLES = 'UPDATE_CHECK_IN_CYCLES';

export interface IEvaluationPeriodStore
{
  evaluationPeriods: Array<EvaluationPeriod>;
  checkInCycles: Array<CheckInCycle>;
  isVisible: boolean;

  getPeriod(id: number): EvaluationPeriod;
  getCycle(id: number): CheckInCycle;
  showModal(): void;
  hideModal(): void;
  updateCheckInCycle(
    checkInCycle: CheckInCycle,
  ): void;
  fetchTimeFrames(): Promise<void>;
  createEvaluationPeriod(
    label: string,
    type: EvaluationPeriodTypes,
    cycleType: CheckInCycleTypes,
    startYear: number,
    startMonth: number,
  ): Promise<void>;
  deleteEvaluationPeriod(
    id: number
  ): Promise<void>;
  updateCheckInCycles(cycles: Array<CheckInCycle>): Promise<void>;
}

type TimeFramesResponse =
{
  evaluation_periods: Array<EvaluationPeriod>;
  check_in_cycles: Array<CheckInCycle>;
}

export default class EvaluationPeriodStore implements IEvaluationPeriodStore
{
  stores: RootStore;

  evaluationPeriods = Array<EvaluationPeriod>();
  checkInCycles = Array<CheckInCycle>();
  isVisible = false;

  constructor(stores: RootStore)
  {
    this.stores = stores;
  }

  showModal(): void
  {
    this.isVisible = true;
  }

  hideModal(): void
  {
    this.isVisible = false;
  }

  updateCheckInCycle(checkInCycle: CheckInCycle): void
  {
    runInAction(() => {
      this.checkInCycles = this.checkInCycles
        .map(cycle => cycle.id === checkInCycle.id
          ? checkInCycle
          : cycle
        )
    })
  }

  getPeriod(id: number)
  {
    return this.evaluationPeriods.find(period => period.id === id) ||
      EMPTY_EVALUATION_PERIOD
  }

  getCycle(id: number)
  {
    return this.checkInCycles.find(period => period.id === id) ||
      EMPTY_CHECK_IN_CYCLE
  }

  async fetchTimeFrames()
  {
    this.stores.loadingStore.start(FETCH_TIME_FRAMES);

    try
    {
      const client = getClient() || EMPTY_CLIENT;
      const {
        evaluation_periods,
        check_in_cycles,
      } = await getTimeFrames(client.id)

      runInAction(() => {
        this.evaluationPeriods = evaluation_periods;
        this.checkInCycles = check_in_cycles;
      })
    }

    catch (error)
    {
      console.warn(error)
    }

    finally
    {
      this.stores.loadingStore.end(FETCH_TIME_FRAMES);
    }
  }

  async createEvaluationPeriod(
    label: string,
    type: EvaluationPeriodTypes,
    cycleType: CheckInCycleTypes,
    startYear: number,
    startMonth: number,
  ): Promise<void> {
    this.stores.loadingStore.start(CREATE_EVALUATION_PERIOD);

    try
    {
      await create(
        label,
        type,
        cycleType,
        startYear,
        startMonth,
        this.stores.clientStore.client.id,
      );
      this.stores.notificationStore.triggerSuccessNotification(
        'Evaluation period successfully created'
      )
    }

    catch (error)
    {
      console.warn(error)
      this.stores.notificationStore.triggerErrorNotification(
        'An error occurred: no evaluation period was created'
      )
    }

    finally
    {
      this.stores.loadingStore.end(CREATE_EVALUATION_PERIOD)
    }
  }

  async deleteEvaluationPeriod(id: number)
  {
    this.stores.loadingStore.start(DELETE_EVALUATION_PERIOD)

    try
    {
      await deleteEvaluationPeriod(id)
      this.stores.notificationStore.triggerSuccessNotification(
        'Evaluation period successfully deleted'
      )
    }

    catch (error) {
      console.warn(error)
      this.stores.notificationStore.triggerErrorNotification(
        'An error occurred: could not delete evaluation period'
      )
    }

    finally
    {
      this.stores.loadingStore.end(DELETE_EVALUATION_PERIOD)
    }
  }

  async updateCheckInCycles(
    cycles: Array<CheckInCycle>
  ): Promise<void>
  {
    this.stores.loadingStore.start(UPDATE_CHECK_IN_CYCLES)

    try
    {
      await updateCheckInCycles(cycles)
      this.stores.notificationStore.triggerSuccessNotification(
        'Check in cycles successfully saved'
      )
    }

    catch (error)
    {
      console.warn(error)
      this.stores.notificationStore.triggerErrorNotification(
        'An error occurred: could not update the check in cycles'
      )
    }

    finally
    {
      this.stores.loadingStore.end(UPDATE_CHECK_IN_CYCLES)
    }
  }
}

decorate(EvaluationPeriodStore, {
  evaluationPeriods: observable,
  checkInCycles: observable,
  isVisible: observable,
  showModal: action,
  hideModal: action,
  fetchTimeFrames: action,
})

async function getTimeFrames(
  clientId: number,
): Promise<TimeFramesResponse> {
  const { data } = await api.get(`clients/${clientId}/evaluation-periods`, {});
  return data;
}

async function create(
  label: string,
  type: EvaluationPeriodTypes,
  cycleType: CheckInCycleTypes,
  startYear: number,
  startMonth: number,
  clientId: number,
): Promise<EvaluationPeriod> {
  const { data } = await api.post(`client-evaluation-periods`, {
    label,
    type,
    cycleType,
    startYear,
    startMonth,
    clientId,
  })
  return data
}

async function deleteEvaluationPeriod(id: number): Promise<void>
{
  await api.delete(`client-evaluation-periods/${id}`)
}

async function updateCheckInCycles(
  cycles: Array<CheckInCycle>
): Promise<void>
{
  await api.patch('client-checkin-cycles', cycles)
}
