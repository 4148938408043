import React from 'react';
import classNames from 'classnames/bind';

import { Icon, IconEnum, Text } from '../../base';
import { Spacer } from '../../layout';

import styles from './High5Button.module.css';
const cx = classNames.bind(styles);

type Props = {
  text?: string;
  onClick(): void;
  disabled?: boolean;
  isRound?: boolean;
};

export function High5Button(props: Props) {
  const {
    onClick,
    disabled = false,
    text = 'Give Applause',
    isRound = false,
  } = props;

  const css = cx({
    button: true,
    round: isRound,
  });

  return (
    <button onClick={onClick} disabled={disabled} className={css}>
      <Icon size={isRound ? 28 : 36} icon={IconEnum.HIGH5} />
      {isRound === false && (
        <>
          <Spacer vertical={8} />
          <Text bold>{text}</Text>
        </>
      )}
    </button>
  );
}

High5Button.defaultProps = {
  text: 'Give Applause',
  disabled: false,
  isRound: false,
};
