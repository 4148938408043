import React from 'react';
import iconSet from '../../../assets/selection.json';
import IcoMoon from 'icomoon-react';
import { Color } from '../../styles';

import styles from './Icon.module.css';

const iconTypes = iconSet.icons.map((icon) => icon.properties.name);

interface Props {
  color?: Color | 'inherit';
  size?: number;
  icon: IconEnum;
}

export function Icon(props: Props) {
  const { color, size, icon } = props;

  return (
    <IcoMoon
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
      style={{ minWidth: size }}
      className={color === 'inherit' ? styles.inherit : ''}
    />
  );
}

Icon.defaultProps = {
  size: 16,
};

export enum IconEnum {
  ARROW = 'arrow',
  CALENDAR = 'calendar',
  CHECK = 'check',
  CHECK_CIRCLE = 'check-circle',
  CHEVRON_DOWN = 'chevron_down',
  CHEVRON_LEFT = 'chevron_left',
  CHEVRON_RIGHT = 'chevron_right',
  CHEVRON_UP = 'chevron_up',
  CLOSE = 'close',
  COMMENT = 'comment',
  DELETE = 'delete',
  DOCUMENT = 'document',
  DOCUMENT_DOC = 'document-doc',
  DOCUMENT_DOCX = 'document-docx',
  DOCUMENT_PDF = 'document-pdf',
  DOCUMENT_PPT = 'document-ppt',
  DOCUMENT_TXT = 'document-txt',
  DOCUMENT_XLS = 'document-xls',
  DOCUMENT_XLSX = 'document-xlsx',
  DOTS = 'more',
  EDIT = 'edit',
  ERROR = 'error',
  FINAL_REVIEW = 'final-review',
  GOAL = 'goal',
  HAND = 'hand',
  HIGH5 = 'high5',
  INFO = 'info',
  PLUS = 'plus',
  PROGRESS = 'progress',
  SEARCH = 'search',
  SUBMIT = 'submit',
  TEAM = 'team',
}

function validateIcons(icons: string[]) {
  const iconsEnum = Object.values(IconEnum) as string[];
  const missingIcons = icons.filter((icon) => !iconsEnum.includes(icon));
  const unusedIcons = iconsEnum.filter((icon) => !icons.includes(icon));
  if (missingIcons.length > 0) {
    console.info(`❌ Missing icon types: "${missingIcons.join(', ')}"`);
  } else if (unusedIcons.length > 0) {
    console.info(`❌ Missing icons from font: "${unusedIcons.join(', ')}"`);
  } else {
    console.info('✅ All icons are typed');
  }
}

process.env.NODE_ENV === 'development' && validateIcons(iconTypes);
