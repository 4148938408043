import React, { useEffect, useState } from 'react';
import { Tab } from '../../../types';
import { TabButton } from '../TabButton/TabButton';
import { Heading } from '../../base';
import styles from './Tabs.module.css';
import classNames from 'classnames/bind';

interface Props {
  active?: number;
  tabs: Tab[];
}

const cx = classNames.bind(styles);

export default function Tabs(props: Props) {
  const { active: activeTab, tabs } = props;
  const [active, setActive] = useState(activeTab ?? 0);

  const tab_list: JSX.Element[] = tabs.map((tab, i) => (
    <TabButton
      key={`tab_${tab.title}`}
      onClick={() => setActive(i)}
      text={tab.title}
      active={i === active}
      count={tab.count}
    />
  ));

  const style = cx({
    tabs: true,
    hasTabs: tab_list.length > 1,
  });

  useEffect(() => setActive(activeTab ?? 0), [activeTab, setActive]);

  return (
    <div className={style}>
      <div className={styles.tabs__header}>
        { header() }
      </div>

      <div className="tabs__body">
        { body() }
      </div>
    </div>
  );

  function header() {
    if (tabs.length === 1) {
      return (
        <Heading>{ tabs[0].title }</Heading>
      );
    }

    return tab_list;
  }

  function body() {
    return tabs[active].body;
  }
}
