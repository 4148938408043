import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import { IUserStore, IEmployeeStore, IClientStore } from '../../stores';
import {
  Wrapper,
  EmployeeHeader,
  TaskList,
  EmployeePage,
  StandardsReviews,
} from '../../components';
import { SidebarLayout, TabItem } from '../../components/layout/SidebarLayout/SidebarLayout';
import { EvaluationDetailsContainer } from '../../components/custom/EmployeePage/EvaluationDetailsContainer';
import { OverallReviewCard } from '../../components/custom/EmployeePage/OverallReviewCard';
import { EMPTY_EMPLOYEE } from '../../stores/employeeStore';

type RouteParams = {
  employee_id: string;
};
type RouteProps = RouteComponentProps<RouteParams>;

type Props = RouteProps & {
  clientStore: IClientStore;
  employeeStore: IEmployeeStore;
  userStore: IUserStore;
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function EmployeeScreen(props: Props) {
  const clientStore = props.clientStore!;
  const employeeStore = props.employeeStore!;
  const userStore = props.userStore!;
  const employeeId = parseInt(props.match.params.employee_id);

  const { role } = userStore;
  const query = useQuery();
  const param = query.get("tab");

  const [employee, setEmployee] = useState(EMPTY_EMPLOYEE);

  useEffect(() => {
    async function getEmployee() {
      await employeeStore.fetchEmployees(userStore.user);
      employeeStore.setEmployeeId(employeeId);
      setEmployee(employeeStore.employee!);
    }
    getEmployee();
  }, [employeeId, employeeStore, userStore, employeeStore.employees.length, clientStore.checkInCycle.id]);

  enum Tabs {
    GOALS = 'Goal Management',
    PERFORMANCE_STANDARDS = 'Performance Standard Reviews',
    REVIEWS = 'Reviews',
    TASKS = 'Tasks',
  }

  const tabs: TabItem[] = [{
    title: Tabs.GOALS,
    body: renderBody(),
    location: "main",
  }, {
    title: Tabs.PERFORMANCE_STANDARDS,
    body: <StandardsReviews employee={employee!} />,
    location: "main"
  }, {
    title: Tabs.REVIEWS,
    body: <OverallReviewCard><EvaluationDetailsContainer employee={employee!} role={role!} /></OverallReviewCard>,
    location: "main"
  }, {
    title: Tabs.TASKS,
    body: <TaskList />,
    location: "sidebar"
  }];

  const [active, setActive] = useState<number|undefined>();

  useEffect(() => {
    function getIndex(query: string): number|undefined {
      const idx = tabs.findIndex((t) => t.title === query);

      if (idx === -1) return;

      return idx;
    }

    if (!param) return;
    setActive(getIndex(param));
  }, [param, tabs, setActive]);

  return !employee
    ? <div>No employee found</div>
    : (
    <Wrapper>
      <SidebarLayout
        active={active}
        header={<EmployeeHeader employee={employee} />}
        tabs={tabs}
      />
    </Wrapper>
  );

  function renderBody() {
    if (!employee) {
      return <div></div>;
    }

    return (
      <EmployeePage
        employee={employee}
        role={role === 'manager' ? 'manager' : 'employee'}
      />
    );
  }
}

export default inject(
  'clientStore',
  'employeeStore',
  'userStore',
)(observer(EmployeeScreen));
