import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { IEmployeeStore, IGoalStore, IUserStore } from '../../stores';
import {
  Content,
  Wrapper,
  Heading,
  Spacer,
  Icon,
  IconEnum,
  Color,
  TaskList,
} from '../../components';
import { useAdaptiveLayout } from '../../utils/hooks';
import { EmployeeCard } from './EmployeeCard';

import styles from './ManagerDashboard.module.css';
import { Employee } from '../../types';
import { useHistory } from 'react-router-dom';

type Props = {
  employeeStore?: IEmployeeStore;
  goalStore?: IGoalStore;
  userStore?: IUserStore;
};

function ManagerDashboard(props: Props) {
  const employeeStore = props.employeeStore!;
  const goalStore = props.goalStore!;
  const userStore = props.userStore!;

  const history = useHistory();
  const [searchString, setSearchString] = useState('');
  const { isDesktop } = useAdaptiveLayout();

  useEffect(() => {
    employeeStore.fetchEmployees(userStore.user);
    employeeStore.resetEmployee();
    goalStore.reset();
  }, [employeeStore, goalStore, userStore]);

  const filteredEmployees = getfilteredEmployees(employeeStore.employees);

  return (
    <Wrapper>
      <Content
        title="Team"
        header={renderMainHeader()}
        body={renderMainBody()}
        tabs={[
          {
            title: 'Tasks',
            body: <TaskList />,
          },
        ]}
      />
    </Wrapper>
  );

  function renderMainHeader() {
    return isDesktop === false ? <Heading>Dashboard</Heading> : undefined;
  }

  function renderMainBody() {
    return (
      <>
        <div className={styles.team_header}>
          {isDesktop === true && <Heading>Team</Heading>}
          {renderSearch()}
        </div>

        <Spacer vertical={32} />

        {renderEmployeeList()}
      </>
    );
  }

  function renderSearch() {
    return (
      <div className={styles.search_wrapper}>
        <input
          value={searchString}
          type="text"
          placeholder="Search employee"
          className={styles.search}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <span className={styles.search_icon}>
          <Icon icon={IconEnum.SEARCH} color={Color.PRIMARY} />
        </span>
      </div>
    );
  }

  function renderEmployeeList() {
    if (!filteredEmployees.length) {
      return (<div>
        You aren't managing any employees. {userStore.hasAdminRole ? (<button className={styles.add_user_button} onClick={goToUserSettings}>Add an employee</button>) : ''}
      </div>);
    }

    return (
      <ul className={styles.employee_list}>
        {filteredEmployees.map((employee) => {
          return (
            <li key={employee.id}>
              {/* <Link
                to={`/employee/${employee.id}`}
                className={styles.employee_link}> */}
                <EmployeeCard employee={employee} />
              {/* </Link> */}
            </li>
          );
        })}
      </ul>
    );
  }

  function getfilteredEmployees(employees: Employee[]) {
    return employees.filter((employee) =>
      employee.manager_id === userStore.user.id &&
      employee.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }

  function goToUserSettings() {
    history.push('/users');
  }
}

export default inject(
  'employeeStore',
  'goalStore',
  'userStore'
)(observer(ManagerDashboard));
