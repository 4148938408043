// https://github.com/mobxjs/mobx-react-lite/#observer-batching
import 'mobx-react-lite/batchingForReactDom';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { Router } from 'react-router-dom';

import stores from './stores';
import * as serviceWorker from './serviceWorker';
import MainRoutes from './Routes';
import { history } from './services/history';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider {...stores}>
      <Router history={history}>
        <MainRoutes />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
