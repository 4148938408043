import { Client } from '../types';

enum ClientData {
  CLIENT = 'client',
}

export function setClient(client: Client) {
  localStorage.setItem(ClientData.CLIENT, JSON.stringify(client));
}

export function getClient(): Client | null {
  const client = localStorage.getItem(ClientData.CLIENT);
  return client ? (JSON.parse(client) as Client) : null;
}

export function clearClientData() {
  localStorage.removeItem(ClientData.CLIENT);
}
