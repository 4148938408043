import React from 'react';
import styles from './Input.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  type?: 'text' | 'email' | 'date' | 'password' | 'number';
  alignText?: 'center';
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  size?: 'normal' | 'large';
  unit?: string;
  error?: string;
  min?: number | string;
  max?: number | string;
}

export function Input(props: Props) {
  const {
    onChange,
    onFocus,
    value,
    placeholder,
    size,
    unit,
    error,
    type = 'text',
    alignText,
    disabled = false,
    required = false,
    min = '',
    max = '',
  } = props;

  const inputStyles = cx({
    input: true,
    large: size === 'large',
    centeredText: alignText === 'center',
  });

  return (
    <div className={styles.container}>
      <input
        className={inputStyles}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        min={min}
        max={max}
      />
      {unit && <span className={styles.unit}>{unit}</span>}
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
}

Input.defaultProps = {
  size: 'normal',
  type: 'text',
  disabled: false,
  required: false,
};
