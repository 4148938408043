import React, { useEffect, useState } from 'react';
import styles from './ManageStandards.module.css';
import { IUserStore, IStandardStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Card,
  Content,
  CreateStandardModal,
  Divider,
  Heading,
  Icon,
  IconEnum,
  Spacer,
  Text,
  Wrapper,
} from '../../components';
import { ClientStandard } from '../../types';
import { runInAction } from 'mobx';
import classNames from 'classnames/bind';
import { EditStandardModal } from '../../components/custom/EditStandardModal/EditStandardModal';

const cx = classNames.bind(styles);

interface Props {
  userStore?: IUserStore;
  standardStore?: IStandardStore;
}

function ManageStandards(props: Props) {
  const userStore = props.userStore!;
  const standardStore = props.standardStore!;
  const { user } = userStore;
  const { standards } = standardStore;

  const [isCreateModalVisible, setCreateModalVisible] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [standard, setStandard] = useState<ClientStandard>();

  useEffect(() => {
    standardStore.fetchStandards(user.client_id);
  }, [user.client_id, standardStore]);

  return (
    <>
      <Wrapper>
        <Content
          title="Manage Standards"
          header={<Heading level={1}>Manage Standards</Heading>}
          body={renderBody()}
        />
      </Wrapper>

      {isCreateModalVisible && (
        <CreateStandardModal
          isVisible={isCreateModalVisible}
          onClose={() => {
            setCreateModalVisible(false);
          }}
        />
      )}

      {!!standard && (
        <EditStandardModal
          standard={standard}
          isVisible={true}
          onClose={() => {setStandard(undefined)}}
        />
      )}
    </>
  );

  function renderBody() {
    return (
      <Card className={styles.manage_standards_card}>
        <div className={styles.card_header}>
          <Text>Create or modify your organization's standards</Text>
          <div className={styles.card_header__actions}>
            <Button role="secondary" onClick={() => { setIsEditable(!isEditable) }}>
              {isEditable ? 'Cancel' : 'Edit'}
            </Button>
            <Button onClick={() => { setCreateModalVisible(true) }}>Add a Standard</Button>
          </div>
        </div>

        <div className={styles.card_body}>
          {renderStandards()}
        </div>
      </Card>
    );
  }

  function renderStandards() {
    return (
      <>
        <Divider gap={24} />
        <Heading level={2}>Standards</Heading>
        <Spacer vertical={16} />
        <div className={styles.standards__list}>
          { standards.length
            ? standards.map((standard) => renderStandard(standard))
            : <Text>No standards were found.</Text>
          }
        </div>
      </>
    );
  }

  function renderStandard(standard: ClientStandard) {
    const standardStyles = cx({
      standard: true,
      is_editable: isEditable,
    });

    return (
      <div className={standardStyles} key={standard.id}>
        {isEditable &&
          <button
            className={styles.standard__edit}
            onClick={() => setStandard(standard)}
          >
            <Icon icon={IconEnum.EDIT} />
          </button>
        }

        <div className={styles.standard__header}>
          <h3>{standard.name}</h3>
        </div>
        <div className={styles.standard__body}>
          <p>{standard.description}</p>
        </div>
        <div className={styles.standard__actions}>
          {standard.deactivated
            ? <Button onClick={() => activate(standard.id)}>Activate</Button>
            : <Button role={'secondary'} onClick={() => deactivate(standard.id)}>Deactivate</Button>}
        </div>
      </div>
    );
  }

  async function activate(standardId: number) {
    const standard = await standardStore.activateStandard(standardId);

    if (!standard) {
      return;
    }

    for (let i = 0; i < standardStore.standards.length; i++) {
      const element = standardStore.standards[i];
      if (element.id !== standardId) {
        continue;
      }

      runInAction(() => {
        element.deactivated = standard.deactivated;
      });
    }
  }

  async function deactivate(standardId: number) {
    const standard = await standardStore.deactivateStandard(standardId);

    if (!standard) {
      return;
    }

    for (let i = 0; i < standardStore.standards.length; i++) {
      const element = standardStore.standards[i];

      if (element.id !== standardId) {
        continue;
      }

      runInAction(() => {
        element.deactivated = standard.deactivated;
      });
    }
  }
}

export default inject(
  'userStore',
  'standardStore'
)(observer(ManageStandards));
