import React, { useRef, useState } from 'react';
import styles from './Dropdown.module.css';
import { Icon, IconEnum } from '../../../components';
import classNames from 'classnames/bind';
import { useOnClickOutside } from '../../../utils/hooks';

const cx = classNames.bind(styles);

type OptionValue = string | number;

type Option<T extends OptionValue> = {
  value: T;
  label: string;
};

interface Props<T extends OptionValue> {
  /** if value is not provided, the placeholder will be displayed */
  value?: T;
  /** { label: string, value: any } */
  options: Option<T>[];
  placeholder?: string;
  onChange: (value: T) => void;
}

export function Dropdown<T extends OptionValue>(props: Props<T>) {
  const { placeholder = 'Choose...', value, options, onChange } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const selectedLabel =
    typeof value !== 'undefined'
      ? options.find((o) => o.value === value)?.label
      : placeholder;

  const containerStyles = cx({
    container: true,
    container_open: isDropdownOpen,
    container_placeholder: selectedLabel === placeholder,
  });

  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, () => setIsDropdownOpen(false));

  return (
    <div className={containerStyles} ref={containerRef}>
      <button
        className={styles.trigger}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <span className={styles.selected_label}>{selectedLabel}</span>
        <Icon
          icon={isDropdownOpen ? IconEnum.CHEVRON_UP : IconEnum.CHEVRON_DOWN}
          size={12}
        />
      </button>

      {isDropdownOpen && renderDropdown()}
    </div>
  );

  function renderDropdown() {
    return (
      <div className={styles.options}>
        {options.map((o) => (
          <button
            key={o.value}
            className={styles.option}
            onClick={() => {
              onChange(o.value);
              setIsDropdownOpen(false);
            }}>
            {o.label}
          </button>
        ))}
      </div>
    );
  }
}
