import React from 'react';
import { ClientStandardsScore } from '../../../types';
import { observer } from 'mobx-react';
import { Stack } from '../../index';
import styles from './StandardsScores.module.css';
import { IStandardStore } from '../../../stores';
import { Score } from './Score';

type Props = {
  scores: ClientStandardsScore[],
  standardStore?: IStandardStore
}

function _StandardsScores(props: Props) {
  const { scores } = props;

  return (
    <div className={styles.review__scores}>
      <Stack gap={32}>
        {scores.map((score) => (
          <Score
            key={score.id}
            score={score}
          />
        ))}
      </Stack>
    </div>
  );
}

export const StandardsScores = observer(_StandardsScores);
