import React from 'react';
import { inject, observer } from 'mobx-react';

import { IUserStore } from '../../stores';

import AdminDashboard from './AdminDashboard';
import EmployeeDashboard from './EmployeeDashboard';
import ManagerDashboard from './ManagerDashboard';
import { Wrapper } from '../../components/custom';

type Props = {
  userStore: IUserStore;
};

function DashboardScreen(props: Props) {
  const { userStore } = props;

  if (userStore.isAdmin && userStore.hasAdminRole) {
    return <AdminDashboard />;
  }

  if (userStore.isManager && userStore.hasManagerRole) {
    return <ManagerDashboard />;
  }

  if (userStore.isEmployee && userStore.hasEmployeeRole) {
    return <EmployeeDashboard />;
  }

  return (
    <Wrapper>
      <p>
        Role "{userStore.role}" is not set; current user roles:{' '}
        {userStore.user.roles?.toString()}
      </p>
    </Wrapper>
  );
}

export default inject('userStore')(observer(DashboardScreen));
