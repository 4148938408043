import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { IUserStore } from '../../stores';
import {
  Button,
  Heading,
  Icon,
  IconEnum,
  Input,
  Spacer,
  Stack,
  Text,
} from '../../components';
import styles from './PasswordRecoveryScreen.module.css';
import { inject, observer } from 'mobx-react';
import { useAdaptiveLayout } from '../../utils/hooks';

type RouteParams = {};

type RouteProps = RouteComponentProps<RouteParams>;

type Props = RouteProps & {
  userStore: IUserStore;
};

function PasswordRecoveryScreen(props: Props) {
  const { userStore } = props;
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { isDesktop } = useAdaptiveLayout();

  return (
    <div data-mobile={!isDesktop} className={styles.container}>
      <div className={styles.loginContainer}>
        <Stack gap={8} align={'center'}>
          <Icon icon={IconEnum.HIGH5} size={36} />
          <Heading>Recover Password</Heading>

          { success
            ? passwordResetSuccess()
            : passwordResetForm()
          }
        </Stack>
      </div>
    </div>
  );

  function passwordResetForm() {
    return (
      <form
        onSubmit={(e) => e.preventDefault()}
        className={styles.formContainer}
      >
        <Text>Enter your email to recieve a password recovery link</Text>
        <Spacer vertical={32} />

        <Stack gap={16} align={'stretch'}>
          <Input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            placeholder={'john@mail.com'}
            alignText={'center'}
          />
        </Stack>

        <Spacer vertical={32} />

        <div className={styles.centered}>
          <Button disabled={
            !email || submitting
          } onClick={() => sendPasswordRecoveryLink()}>
            Email me a recovery link
          </Button>
        </div>

        {errorMessage.length !== 0 && (
          <div className={styles.centered}>
            <h4>{errorMessage}</h4>
          </div>
        )}
      </form>
    );
  }

  function passwordResetSuccess() {
    return (
      <Text>If the account exists, an email has been sent with further instructions for resetting your password.</Text>
    );
  }

  async function sendPasswordRecoveryLink() {
    const payload = { email };
    try {
      setSubmitting(true);
      await userStore.sendPasswordRecoveryLink(payload);
      setSuccess(true);
    } catch (error) {
      setSubmitting(false);
      setErrorMessage(error.message);
    }
  }
}

export default inject('userStore')(observer(PasswordRecoveryScreen));
