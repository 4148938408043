import React, { useEffect } from 'react';
import styles from './High5ListModalContainer.module.css';

import { inject, observer } from 'mobx-react';
import { IHigh5Store } from '../../../stores';
import { Modal, High5ListModal } from '..';
import { Employee } from '../../../types';

interface Props {
  high5Store?: IHigh5Store;
  employee: Employee;
  highlightedHigh5Id?: string | null;
  isOpen: boolean;
  onClose: () => void;
}
//TODO - Move this component in employee screen
function High5ListModalContainerWithoutStore(props: Props) {
  const { isOpen, onClose, employee, highlightedHigh5Id } = props;
  const high5Store = props.high5Store!;

  useEffect(() => {
    high5Store.fetchCompanyValues();
  }, [high5Store]);

  useEffect(() => {
    high5Store.fetchHighFives(employee.id);
  }, [isOpen, high5Store, employee.id]);

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <div className={styles.high_fives_container}>
        <High5ListModal
          highFives={high5Store.highFives}
          companyValues={high5Store.companyValues}
          employee={employee}
          highlightedHigh5Id={highlightedHigh5Id}
        />
      </div>
    </Modal>
  );
}

export const High5ListModalContainer = inject('high5Store')(
  observer(High5ListModalContainerWithoutStore),
);
