import React, { useState } from 'react';
import styles from './ReassignUsersModal.module.css';
import {
  Button,
  Color,
  Heading,
  Modal,
  Select,
  Spacer,
  Table,
  Text,
} from '../..';
import { User } from '../../../types';
import { Column } from 'react-table';
import { inject, observer } from 'mobx-react';
import { IEmployeeStore } from '../../../stores';

interface Props {
  employeeStore?: IEmployeeStore;
  isVisible: boolean;
  managers: User[];
  user: User;
  users: User[];
  onClose: () => void;
  onSend: () => void;
}

function ReassignUsersModalFunction(props: Props) {
  const {
    isVisible,
    managers,
    user,
    users,
    onClose,
    onSend
  } = props;

  const employeeStore: IEmployeeStore = props.employeeStore!;

  const [manager, setManager] = useState<number|null>();

  const columns: Column<User>[] = [{
    Header: 'Name',
    accessor: 'name',
  }, {
    Header: 'Email',
    accessor: 'email',
  }];

  return (
    <Modal isOpen={isVisible} onClose={onClose}>
      <div className={styles.container}>
        <Heading level={3}>Reassign Users</Heading>

        <Spacer vertical={8} />

        <Text size={14} color={Color.PRIMARY}>
          These users must be reassigned before deleting {user.name}
        </Text>

        <Table
          columns={columns}
          data={users}
        />

        <Spacer vertical={32} />

        <Text>Reassign users to which manager?</Text>

        <Select
          defaultValue={manager ? manager.toString() : ''}
          values={managers.map(manager => ({
            value: manager.id.toString(),
            label: manager.name,
          }))}
          onChange={e => setManager(Number(e.target.value))}
          placeholder="Select a Manager"
        ></Select>

        <Spacer vertical={32} />

        <div className={styles.actions}>
          <Button
            role="primary"
            onClick={() => reassignUsers()}
            disabled={!manager}
          >
            Reassign
          </Button>
        </div>
      </div>
    </Modal>
  );

  async function reassignUsers()
  {
    for (let i = 0; i < users.length; i++) {
      const employee = users[i];

      await employeeStore.setManager(employee.id, Number(manager))
    }

    onSend()
  }
}

export const ReassignUsersModal = inject('employeeStore')(observer(ReassignUsersModalFunction))
