import React, { useState, useEffect, useRef } from 'react';
import { CheckInCycleTypes, EvaluationPeriodTypes } from '../../../types';
import styles from './EvaluationPeriodModal.module.css';
import { mapEnum, YEARS, MONTHS,  } from './EvaluationPeriodModal.utils';

import {
  Button,
  Heading,
  Input,
  Modal,
  Select,
  Spacer,
  Text,
} from '../../';
import { useAdaptiveLayout } from '../../../utils/hooks';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSend: (selected: {
    label: string;
    type: EvaluationPeriodTypes;
    cycleType: CheckInCycleTypes;
    startYear: number;
    startMonth: number;
  }) => void;
}

export function EvaluationPeriodModal(props: Props) {
  const {
    onClose,
    onSend,
    isVisible,
  } = props;

  const { isDesktop } = useAdaptiveLayout();

  const currentYear = new Date().getFullYear()

  const [label, setLabel] = useState<string>('');
  const [type, setType] = useState<EvaluationPeriodTypes>(EvaluationPeriodTypes.ANNUAL);
  const [cycleType, setCycleType] = useState<CheckInCycleTypes>(CheckInCycleTypes.QUARTERLY);
  const [startYear, setStartYear] = useState<number>(currentYear + 1);
  const [startMonth, setStartMonth] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [isVisible]);

  return (
    <Modal
      isOpen={isVisible}
      onClose={closeModal}
    >
      <div
        className={styles.container}
        data-desktop={isDesktop}
      >
        <Heading level={2}>
          Create a New Evaluation Period
        </Heading>

        <Spacer vertical={24} />

        <div className={styles.form}>
          {renderLabel()}

          {renderTypes()}

          {renderCycleTypes()}

          {renderYears()}

          {renderMonths()}
        </div>

        <Spacer vertical={32} />

        <Button
          disabled={isSendDisabled()}
          onClick={createEvaluationPeriod}
        >
          Create
        </Button>
      </div>
    </Modal>
  );


  function renderLabel()
  {
    return (
      <label>
        <Text>Evaluation Period Label</Text>
        <Input
          onChange={(e) => setLabel(e.target.value)}
          value={label}
        />
      </label>
    )
  }

  function renderTypes()
  {
    const values = mapEnum(EvaluationPeriodTypes, (v:string) => {
      return {
        value: v,
        label: v
      }
    })

    return (
      <label>
        <Text>Evaluation Period Type</Text>
        <Select
          onChange={(e) => {
            setType(e.target.value as EvaluationPeriodTypes)
          }
          }
          values={values}
          defaultValue={type}
        />
      </label>
    )
  }

  function renderCycleTypes()
  {
    const values = mapEnum(CheckInCycleTypes, (v:string) => {
      return {
        value: v,
        label: v
      }
    })

    return (
      <label>
        <Text>Check-in Cycle Type</Text>
        <Select
          onChange={(e) => {
            setCycleType(e.target.value as CheckInCycleTypes)
          }}
          values={values}
          defaultValue={cycleType}
        />
      </label>
    )
  }

  function renderYears()
  {
    return (
      <label>
        <Text>Start Year</Text>
        <Select
          onChange={(e) =>
            setStartYear(Number(e.target.value))
          }
          values={YEARS}
          defaultValue={startYear.toString()}
        />
      </label>
    )
  }

  function renderMonths()
  {
    return (
      <label>
        <Text>Start Month</Text>
        <Select
          onChange={(e) =>
            setStartMonth(Number(e.target.value))
          }
          values={MONTHS}
          defaultValue={startMonth.toString()}
        />
      </label>
    )
  }

  function createEvaluationPeriod()
  {
    onSend({
      label,
      type,
      cycleType,
      startMonth,
      startYear,
    })
    closeModal()
  }

  function isSendDisabled() {
    if (!label) return true;
    if (!type) return true;
    if (!cycleType) return true;
    if (!startYear) return true;
    return false;
  }

  function closeModal() {
    onClose();
  }
}
