import React from 'react';
import styles from './Divider.module.css';
import { Space } from '../..';

interface Props {
  gap?: Space;
}

export function Divider(props: Props) {
  const { gap = 0 } = props;
  return <div style={{ margin: `${gap}px 0px` }} className={styles.line} />;
}

Divider.defaultProps = {
  gap: 0,
};
