import React from 'react';
import styles from './ActionButton.module.css';
import { Icon, IconEnum, Spacer, Text, Color } from '../../';

interface Props {
  icon: IconEnum;
  text: string;
  iconSize?: number;
  iconColor?: Color;
  onClick: () => void;
}

export function ActionButton(props: Props) {
  const { text, icon, iconSize, iconColor, onClick } = props;
  return (
    <button onClick={onClick} className={styles.button}>
      <Icon icon={icon} size={iconSize} color={iconColor} />
      <Spacer horizontal={8} />
      <Text inline bold>
        {text}
      </Text>
    </button>
  );
}
